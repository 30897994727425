// @flow
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { defaultReducers } from './defaultReducers';
import { DefaultRequests } from './defaultRequests';

export interface Program {
  id: number;
  name: string;
  deleted: any;
}

export interface ProgramState {
  loading: boolean;
  error: string;
  list: Program[];
  byId: {};
}

const initialState: ProgramState = {
  loading: false,
  error: '',
  list: [],
  byId: {},
};

const program = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    getStart: defaultReducers.getStart,
    getSuccess: defaultReducers.getSuccess,
    getFailure: defaultReducers.getFailure,
    normalizeList: defaultReducers.normalizeList,
    add: defaultReducers.add,
    update: defaultReducers.update,
    deleteProgramReducer: defaultReducers.delete,
  },
});

export const {
  getStart,
  getSuccess,
  getFailure,
  normalizeList,
  add,
  update,
  deleteProgramReducer,
} = program.actions;

export const programReducer = program.reducer;

export const programActions = new DefaultRequests(
  program.actions,
  `aerostar/program`
);

export const fetchPrograms = () => async (dispatch: any) => {
  try {
    dispatch(getStart());
    const program = await axios.get(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/program/`
    );
    dispatch(getSuccess({ list: program.data }));
  } catch (err) {
    dispatch(getFailure(err));
  }
};

export const fetchScheduleProgram = () => async (dispatch: any) => {
  try {
    dispatch(getStart());
    const items = await axios.get(
      `${
        process.env.REACT_APP_SERVER_URL || ''
      }/aerostar/program/?state=schedule`
    );
    dispatch(normalizeList(items.data));
    return true;
  } catch (err) {
    dispatch(getFailure(err));
    return false;
  }
};

export const postProgram = (item: any) => async (dispatch: any) => {
  try {
    dispatch(getStart());
    item = await axios.post(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/program/`,
      item
    );
    dispatch(add(item.data));
    return item.data;
  } catch (err) {
    dispatch(getFailure(err));
    return false;
  }
};

export const patchProgram = (item: any) => async (dispatch: any) => {
  try {
    dispatch(getStart());
    item = await axios.patch(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/program/${item.id}/`,
      item
    );
    dispatch(update(item.data));
    dispatch(getStart());
    const items = await axios.get(
      `${
        process.env.REACT_APP_SERVER_URL || ''
      }/aerostar/program/?state=dashboard`
    );
    dispatch(normalizeList(items.data));
    return item.data;
  } catch (err) {
    dispatch(getFailure(err));
    return null;
  }
};

export const deleteProgram = (item: any) => async (dispatch: any) => {
  try {
    dispatch(getStart());
    await axios.delete(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/program/${item.id}/`
    );
    dispatch(deleteProgramReducer(item));
    dispatch(getStart());
    const items = await axios.get(
      `${
        process.env.REACT_APP_SERVER_URL || ''
      }/aerostar/program/?state=dashboard`
    );
    dispatch(normalizeList(items.data));
    return true;
  } catch (err) {
    dispatch(getFailure(err));
    return false;
  }
};

export const upsertProgram = (item: any) => async (dispatch: any) => {
  if (item.id) {
    return dispatch(patchProgram(item));
  } else {
    return dispatch(postProgram(item));
  }
};
