// @flow
import React from 'react';
import './AutoComplete.scss';
import { Button, Dropdown, Input } from 'antd';
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import { useDispatch } from 'react-redux';
import { patchCoach } from '../../slices/coachesSlice';
import type { ScheduleCoach } from '../../slices/coachesSlice';
import removeIcon from '../../assets/card-remove-icon.svg';

export interface listItem {
  id: number;
  name: string;
}

export default function AutoComplete({
  list,
  current,
  scheduleCoach,
  updateKey,
  isMaster,
}: {
  list: listItem[],
  current: listItem,
  scheduleCoach: ScheduleCoach,
  updateKey: string,
  isMaster?: boolean,
}) {
  const [result, setResult] = React.useState<listItem[]>(list);
  const [visible, setVisible] = React.useState(false);
  const [selected, setSelected] = React.useState<listItem | null>(current);
  const dispatch = useDispatch();

  const handleSearch = (event) => {
    const value = event.target.value;
    let res: listItem[];
    if (!value) {
      res = list;
    } else {
      res = list.filter((place) =>
        place.name.toLowerCase().includes(value.toLowerCase())
      );
    }
    setResult(res);
  };

  const handleSelect = (value: ?listItem) => {
    setVisible(false);
    let update = { id: scheduleCoach.id };
    update[updateKey] = value ? value.id : null;
    dispatch(patchCoach(update, isMaster));
    setSelected(value || null);
  };

  const menu = (
    <div className="auto-complete">
      <Input autoFocus onChange={handleSearch} />
      <div className="results">
        {result.map((place: listItem) => (
          <Button
            type="link"
            key={place.id}
            onClick={(e) => handleSelect(place)}
            className="option"
          >
            {place.name}
          </Button>
        ))}
        {result.length === 0 && <div className="no-results">No Results</div>}
      </div>
    </div>
  );

  return (
    <div className="lifestyle">
      {selected ? (
        <div className="selected">
          <div className="display-text">{selected.name}</div>
          <div>
            <Button type="link" onClick={() => handleSelect()}>
              <img src={removeIcon} alt="cross" width="15" height="15" />
            </Button>
          </div>
        </div>
      ) : (
        <Dropdown
          overlay={menu}
          trigger={['click']}
          visible={visible}
          onVisibleChange={setVisible}
          className={`ant-dropdown-link hover ${visible ? 'visible' : ''}`}
        >
          <PlusCircleOutlined />
        </Dropdown>
      )}
    </div>
  );
}
