// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import type { State } from '../general/types';
import styles from './Transport.module.scss';
import { Select } from 'antd';
import UserPresenter from '../presenters/UserPresenter';
import { patchCoach } from '../slices/coachesSlice';
import { useDispatch } from 'react-redux';
import chevronDown from '../assets/chevron-down.svg';

const { Option } = Select;

export default function TransportDropdown({
  coachId,
  defaultTransportationType,
  isMaster,
}: {
  coachId: number,
  defaultTransportationType: number,
  isMaster?: boolean,
}) {
  const dispatch = useDispatch();

  const coach = useSelector((state: State) => {
    return state.coaches.byId[coachId];
  });

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const updateEncounterType = async (value) => {
    let transportationUpdateData = Object.assign({}, coach);
    transportationUpdateData.transportationType = value;
    dispatch(patchCoach(transportationUpdateData, isMaster));
  };
  return (
    <div className={styles.transport}>
      <Select
        id={coachId}
        className={styles.dropdown}
        onChange={updateEncounterType}
        style={{ width: 217 }}
        defaultValue={defaultTransportationType}
        placeholder={'- - -'}
        suffixIcon={
          <img src={chevronDown} alt="chevron" width="30" height="30" />
        }
      >
        {currentUserPresenter.user &&
          currentUserPresenter.user.agency.transportationTypes.map(
            (transportationType) => {
              return (
                <Option
                  label={transportationType.name}
                  key={transportationType.id}
                  value={transportationType.id}
                >
                  {transportationType.name}
                </Option>
              );
            }
          )}
      </Select>
    </div>
  );
}
