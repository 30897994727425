// @flow
import React from 'react';
import './Login.scss';
// import logo from '../assets/Centripoint-AA-standard-full(light).svg';
import logo from '../assets/fulllogo_nobuffer.jpg';
import { logout } from '../slices/authSlice';
import store from '../general/store';

export default function LoginApp({ children }: { children: any }) {
  React.useEffect(() => {
    store.dispatch(logout());
  }, []);

  return (
    <div className="login">
      <div className="top">
        <div>
          <div className="logo">
            <img src={logo} alt="centripoint logo" />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
