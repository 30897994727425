// @flow
import React from 'react';
import '../../../directory/Modal.scss';
import styles from '../../../directory/Directory.module.scss';
import '../../program/add-program/addPrograms.scss';
import { Button, Form, Input, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import type { State } from '../../../general/types';
import UserPresenter from '../../../presenters/UserPresenter';
import { Group, upsertGroup, deleteGroup } from '../../../slices/groupSlice';
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
const { confirm } = Modal;

export default function AddGroup({
  group,
  groupStatus,
  groupResetStatus,
}: {
  group?: Group,
  groupStatus?: boolean,
  groupResetStatus?: any,
}) {
  const [submitting, setSubmitting] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  let initialValues = {};
  if (group) {
    initialValues = Object.assign({}, group);
  }

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleOk = (e) => {
    hideModal();
  };

  const handleCancel = (e) => {
    hideModal();
  };

  let formSubmit = async (values) => {
    setSubmitting(true);
    if (initialValues.id) {
      openUpdateModal(values);
    } else {
      const result = await dispatch(
        upsertGroup({
          group: values.name,
          agency: currentUserPresenter.user.agency.id,
        })
      );
      if (result) {
        form.resetFields();
        hideModal();
      }
      setSubmitting(false);
    }
  };

  const openUpdateModal = async (values: any) => {
    confirm({
      title: group?.deleted
        ? `Reactivate ${group?.group ?? 'this group'}?`
        : `Update ${group?.group ?? 'this group'} with these changes?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      async onOk() {
        const groupNewName = values.name ?? initialValues.name;
        const result = await dispatch(
          upsertGroup({
            id: initialValues.id,
            group: groupNewName,
            agency: currentUserPresenter.user.agency.id,
          })
        );
        if (result) {
          form.resetFields();
          hideModal();
        }
        setSubmitting(false);
      },
      onCancel() {
        form.resetFields();
      },
    });
  };
  const openDeactivateModal = async (values: any) => {
    confirm({
      title: `Are you sure you want to delete this group?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okButtonProps: { type: 'danger' },
      async onOk() {
        dispatch(deleteGroup(initialValues));
        groupResetStatus();
      },
      onCancel() {
        form.resetFields();
      },
    });
  };

  return (
    <div>
      {group ? (
        <>
          <Form
            onFinish={formSubmit}
            className="forgot-password"
            form={form}
            colon={false}
          >
            <div className="split">
              <>
                <Form.Item name="id" hidden={true}>
                  <Input type="text" />
                </Form.Item>
                <Form.Item
                  name="name"
                  label={<p className="title">Group Name</p>}
                >
                  <Input type="text" placeholder={initialValues.group} />
                </Form.Item>
              </>
            </div>

            <div className="buttons">
              <button
                className={styles.programButton}
                type="primary"
                htmltype="submit"
                disabled={submitting}
              >
                {group.deleted ? ' activate' : 'Update'}
              </button>
            </div>
          </Form>
          <div>
            {groupStatus ? (
              ''
            ) : (
              <button
                type="danger"
                className={styles.groupDelete}
                onClick={openDeactivateModal}
              >
                {groupStatus ? ' activate' : 'Delete'}
              </button>
            )}
          </div>
        </>
      ) : (
        <button className={styles.teammate} onClick={showModal}>
          <div>
            <PlusCircleOutlined className="plus-icon-dashboard" />
            <p className="add-text-align">ADD GROUP</p>
          </div>
        </button>
      )}
      <Modal
        width="900px"
        title={group ? 'Update group' : 'Add Group'}
        visible={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          onFinish={formSubmit}
          className="forgot-password"
          form={form}
          initialValues={initialValues}
        >
          <div className="split">
            <div>
              <Form.Item name="id" hidden={true}>
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="name"
                label="Group Name"
                rules={[
                  {
                    required: true,
                    message: 'Please Input a Group Name!',
                  },
                ]}
              >
                <Input type="text" placeholder="Group Name" />
              </Form.Item>
            </div>
          </div>

          <div className="buttons">
            <Button type="primary" htmlType="submit" disabled={submitting}>
              {group ? 'Update group' : 'Create Group'}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
