// @flow
import React from 'react';
import styles from '../../directory/list-page/ListPage.module.scss';
import { useSelector } from 'react-redux';
import type { State } from '../../general/types';
import UserPresenter from '../../presenters/UserPresenter';
import { Table, Spin } from 'antd';
import { filterList } from '../../slices/searchSlice';
import SearchComponent from '../../directory/search-component/SearchComponent';
import { getSortProps } from '../../utilities/getSortProps';
import AddEncounterType from './add-ecountertype/addEncounterType';
import { encounterTypeActions } from '../../slices/encounterTypeSlice';
import store from '../../general/store';

export default function EncounterType() {
  React.useEffect(() => {
    store.dispatch(encounterTypeActions.fetch());
  }, []);

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });

  const currentUserPresenter = new UserPresenter(currentUser);
  const search = useSelector((state: State) => {
    return state.search;
  });

  const encounterList = useSelector((state: State) => {
    return state.encounter_type;
  });

  const encounters = encounterList.list.map((id) => {
    return encounterList.byId[id];
  });

  let filteredList = filterList(
    search.byId['encounterList'],
    encounters,
    false,
    true
  );
  filteredList = filteredList.map((encounter) => new UserPresenter(encounter));

  const columns = [
    {
      title: 'ENCOUNTER TYPE',
      dataIndex: 'encounter',
      key: 'encounter',
      ...getSortProps('encounter'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (id) => {
        return (
          <div className={styles.options}>
            <AddEncounterType encounter={encounterList.byId[id]} />
          </div>
        );
      },
    },
  ];

  if (!currentUserPresenter.isAdminUserType()) {
    // Don't show User the edit/delete options
    columns.pop();
  }

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <SearchComponent
          id="encounterList"
          partial={filteredList.length}
          total={encounters.length}
        ></SearchComponent>
        {currentUser && filteredList.length > 0 ? (
          <Table dataSource={filteredList} columns={columns} />
        ) : (
          <div className={styles.spinner}>
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
}
