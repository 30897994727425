// @flow
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { defaultReducers } from './defaultReducers';
import { DefaultRequests } from './defaultRequests';

export interface Group {
  id: number;
  name: string;
  deleted: any;
}

export interface GroupState {
  loading: boolean;
  error: string;
  list: Group[];
  byId: {};
}

const initialState: GroupState = {
  loading: false,
  error: '',
  list: [],
  byId: {},
};

const group = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    getStart: defaultReducers.getStart,
    getSuccess: defaultReducers.getSuccess,
    getFailure: defaultReducers.getFailure,
    normalizeList: defaultReducers.normalizeList,
    add: defaultReducers.add,
    update: defaultReducers.update,
    deleteGroupReducer: defaultReducers.delete,
  },
});

export const {
  getStart,
  getSuccess,
  getFailure,
  normalizeList,
  add,
  update,
  deleteGroupReducer,
} = group.actions;

export const groupReducer = group.reducer;

export const groupActions = new DefaultRequests(
  group.actions,
  `aerostar/group`
);

export const fetchGroups = () => async (dispatch: any) => {
  try {
    dispatch(getStart());
    const group = await axios.get(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/group/`
    );
    dispatch(getSuccess({ list: group.data }));
  } catch (err) {
    dispatch(getFailure(err));
  }
};

export const postGroup = (item: any) => async (dispatch: any) => {
  try {
    dispatch(getStart());
    item = await axios.post(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/group/`,
      item
    );
    dispatch(add(item.data));
    return item.data;
  } catch (err) {
    dispatch(getFailure(err));
    return false;
  }
};

export const patchGroup = (item: any) => async (dispatch: any) => {
  try {
    dispatch(getStart());
    item = await axios.patch(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/group/${item.id}/`,
      item
    );
    dispatch(update(item.data));
    dispatch(getStart());
    const items = await axios.get(
      `${
        process.env.REACT_APP_SERVER_URL || ''
      }/aerostar/group/?state=dashboard`
    );
    dispatch(normalizeList(items.data));
    return item.data;
  } catch (err) {
    dispatch(getFailure(err));
    return null;
  }
};
export const deleteGroup = (item: any) => async (dispatch: any) => {
  try {
    dispatch(getStart());
    await axios.delete(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/group/${item.id}/`
    );
    dispatch(deleteGroupReducer(item));
    dispatch(getStart());
    const items = await axios.get(
      `${
        process.env.REACT_APP_SERVER_URL || ''
      }/aerostar/group/?state=dashboard`
    );
    dispatch(normalizeList(items.data));
    return true;
  } catch (err) {
    dispatch(getFailure(err));
    return false;
  }
};
export const upsertGroup = (item: any) => async (dispatch: any) => {
  if (item.id) {
    return dispatch(patchGroup(item));
  } else {
    return dispatch(postGroup(item));
  }
};
