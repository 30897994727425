// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Input } from 'antd';
import { Link } from 'react-router-dom';
import './ForgotPassword.scss';
import { forgotPassword } from '../../slices/authSlice';

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [done, setDone] = React.useState(false);

  let formSubmit = async (values) => {
    await dispatch(forgotPassword(values));
    setDone(true);
  };

  return (
    <div>
      {done ? (
        <div className="done">
          An email has been sent. Please follow the instructions within to reset
          your password.
        </div>
      ) : (
        <Form onFinish={formSubmit} className="forgot-password" form={form}>
          <label>Email</label>
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input
              id="email"
              type="text"
              autoComplete="email"
              placeholder="Email"
            />
          </Form.Item>
          <div className="buttons">
            <Link to="/login">Login</Link>
            <Button type="primary" htmlType="submit">
              Send Password Reset Email
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
}
