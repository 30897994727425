import React from 'react';
import { Popover } from 'antd';
import { calendarClassMap } from '../../utilities/getScheduleTypeStyles';
import './CoachCalendar.scss';

const EventPopover = ({ eventInfo, view }) => {
  const [visible, setVisible] = React.useState(false);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const setEventColor = (type_id) => {
    return calendarClassMap[type_id % 5];
  };

  const popoverContent = (eventInfo) => {
    return (
      <div key={eventInfo.event.extendedProps.id}>
        <div className="pop-header">
          <h3>{eventInfo.event.extendedProps.program} -</h3>
          <h3>&nbsp;{eventInfo.event.title}</h3>
        </div>
        <h4>{eventInfo.event.extendedProps.timeText}</h4>
        <div className="flex">
          <h4>Pickup Location:</h4>
          <p>&nbsp;{eventInfo.event.extendedProps?.start_location} </p>
        </div>
        <div className="flex">
          <h4>Destination:</h4>
          <p>&nbsp;{eventInfo.event.extendedProps?.end_location} </p>
        </div>
        <div className="flex">
          <h4>Transport Type:</h4>
          <p>&nbsp;{eventInfo.event.extendedProps?.transport_type} </p>
        </div>
        <div className="non-flex">
          <h4>Participants:</h4>
          {eventInfo.event.extendedProps.participants.map((p, index) => (
            <p key={index}>{p}</p>
          ))}
        </div>
        <div className="non-flex">
          <h4>Participant status:</h4>
          {eventInfo.event.extendedProps?.participant_status.map((p, index) => (
            <p key={index}>{p}</p>
          ))}
        </div>
        {eventInfo.event.extendedProps.skills.length > 0 ? (
          <div className="non-flex">
            <h4>Skills:</h4>
            {eventInfo.event.extendedProps?.skills.map((skill, index) => (
              <div key={index}>
                <p>{skill}</p>
              </div>
            ))}
          </div>
        ) : (
          <h4>No skills provided</h4>
        )}
        {eventInfo.event.extendedProps.addresses ? (
          <div className="non-flex">
            <h4>Participant Addresses:</h4>
            {eventInfo.event.extendedProps?.addresses.map((p, index) => (
              <p key={index}>{p}</p>
            ))}
          </div>
        ) : (
          <p>No participant address provided</p>
        )}
        {eventInfo.event.extendedProps.note && (
          <div className="non-flex">
            <h4>Note:</h4>
            <p>{eventInfo.event.extendedProps.note}</p>
          </div>
        )}
        {eventInfo.event.extendedProps.participant_notes.length > 0 && (
          <div className="non-flex">
            <h4>Participant Notes:</h4>
            {eventInfo.event.extendedProps.participant_notes.map(
              (note, index) => (
                <p key={index}>{note}</p>
              )
            )}
          </div>
        )}
        <button
          className="close-btn"
          style={setEventColor(eventInfo.event.extendedProps?.type_id)}
          onClick={() => setVisible(false)}
        >
          Close
        </button>
      </div>
    );
  };

  return (
    <Popover
      content={popoverContent(eventInfo)}
      placement="right"
      overlayClassName="popover-info"
      title={eventInfo.event?.title}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <div
        onClick={() => setVisible(true)}
        style={setEventColor(eventInfo.event.extendedProps?.type_id)}
        className={
          view === 'dayGridWeek' ? 'event-wrapper' : 'day-event-wrapper'
        }
      >
        <div className="event-header">
          <header>{eventInfo.event.extendedProps?.program}</header>
          <h3>{eventInfo.event?.title}</h3>
          <p className="time-text">{eventInfo.event.extendedProps.timeText}</p>
        </div>
        <div className="participants-header">
          <p>PARTICIPANTS</p>
          {eventInfo.event.extendedProps?.participants && (
            <div>
              {eventInfo.event.extendedProps.participants.map((p, index) => (
                <h5
                  className={
                    eventInfo.event.extendedProps.participant_status[index] ===
                    `${p}: scheduled`
                      ? 'participants'
                      : 'participant-out'
                  }
                  key={index}
                >
                  {p}
                </h5>
              ))}
            </div>
          )}
        </div>
        <div className="location">
          <p>PICKUP LOCATION</p>
          <h5>
            {eventInfo.event.extendedProps?.start_location
              ? eventInfo.event.extendedProps?.start_location
              : 'Unknown'}
          </h5>
        </div>
        <div className="location">
          <p>DESTINATION</p>
          <h5>
            {eventInfo.event.extendedProps?.end_location
              ? eventInfo.event.extendedProps?.end_location
              : 'Unknown'}
          </h5>
        </div>
      </div>
    </Popover>
  );
};

export default EventPopover;
