// @flow
import { createSlice } from '@reduxjs/toolkit';
import { defaultReducers } from './defaultReducers';
import { DefaultRequests } from './defaultRequests';

export interface Document {
  id: number;
  sThreeLocation: string;
}

export interface Vehicle {
  id: number;
  make: string;
  model: string;
  year: string;
  color: string;
  licenseNumber: string;
  docExpiration: string;
  licensePhoto: Document[];
  vehiclePhoto: Document[];
  ownedBy: number;
}

export interface VehicleState {
  loading: boolean;
  error: string;
  list: Vehicle[];
  byId: {};
}

const initialState: VehicleState = {
  loading: false,
  error: '',
  list: [],
  byId: {},
};

const vehicles = createSlice({
  name: 'vehicles',
  initialState,
  reducers: defaultReducers,
});

export const vehicleReducer = vehicles.reducer;
export const vehicleActions = new DefaultRequests(
  vehicles.actions,
  `transportation/vehicles`
);
