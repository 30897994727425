export function sortByLastNameFirstName(a, b) {
  if (!a || !b) {
    return 0;
  }
  return (a.lastName + ' ' + a.firstName).localeCompare(
    b.lastName + ' ' + b.firstName
  );
}

export function sortByLastNameFirstNameHelper(participantsById) {
  return function (a, b) {
    a = participantsById[a];
    b = participantsById[b];
    return sortByLastNameFirstName(a, b);
  };
}
