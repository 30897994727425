import React from 'react';
import Menu from '../menu/Menu';
import MenuButton from '../menu-button/MenuButton';

export default function MenuNav({ userInitials }: { userInitials: string }) {
  const [visible, setVisible] = React.useState(false);

  const handleMouseDown = (e) => {
    toggleMenu();
    e.stopPropagation();
  };

  const toggleMenu = () => {
    setVisible(!visible);
  };

  return (
    <div className="menu">
      <MenuButton
        handleMouseDown={handleMouseDown}
        userInitials={userInitials}
      />
      <Menu setVisible={setVisible} menuVisibility={visible} />
    </div>
  );
}
