// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import type { State } from '../general/types';
import CoachNav from './coach-nav/CoachNav';
// import Nav from '../nav/Nav';
import store from '../general/store';
import { fetchSchedule } from '../slices/scheduleSlice';
import './CoachDashboard.scss';
import CoachSchedule from './coach-schedule/CoachSchedule';
import CoachCalendar from './coach-calendar/CoachCalendar';
import { Route, Switch } from 'react-router';
import PrivateRoute from '../general/PrivateRoute';
import { participantActions } from '../slices/participantUserSlice';
import AutoLogout from '../utilities/userAutoLogout';
import SurveyList from './survey/SurveyList';

export default function CoachDashboardApp() {
  React.useEffect(() => {
    store.dispatch(fetchSchedule());
    store.dispatch(participantActions.fetch());
  }, []);

  const auth = useSelector((state: State) => {
    return state.auth;
  });

  return (
    <div>
      {auth.currentUser ? (
        <div>
          <div className="coach-nav">
            <CoachNav />
          </div>
          <Switch>
            <Route path="/coach-dashboard/schedule">
              <CoachSchedule />
            </Route>
            <Route path="/coach-dashboard/calendar">
              <CoachCalendar />
            </Route>
            {/* <PrivateRoute path="/coach-dashboard/covid-survey/:partId">
              <CovidSurvey />
            </PrivateRoute>
            <PrivateRoute path="/coach-dashboard/covid-survey">
              <CovidSurvey />
            </PrivateRoute> */}
            <PrivateRoute path="/coach-dashboard/survey">
              <SurveyList />
            </PrivateRoute>
          </Switch>
          <AutoLogout />
        </div>
      ) : (
        <div>Loading</div>
      )}
    </div>
  );
}
