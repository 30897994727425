// @flow
import { createSlice } from '@reduxjs/toolkit';
import { defaultReducers } from './defaultReducers';
import { DefaultRequests } from './defaultRequests';

export interface EncounterType {
  id: number;
  name: string;
  agency: string;
  reimbursement: number;
}

export interface EncounterTypeState {
  loading: boolean;
  error: string;
  list: EncounterType[];
  byId: {};
}

const initialState: EncounterTypeState = {
  loading: false,
  error: '',
  list: [],
  byId: {},
};

const encounterType = createSlice({
  name: 'encounterType',
  initialState,
  reducers: defaultReducers,
});

export const encounterTypeReducer = encounterType.reducer;
export const encounterTypeActions = new DefaultRequests(
  encounterType.actions,
  `transportation/transportation_types`
);
