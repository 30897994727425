// @flow
import React from 'react';
import styles from '../directory/Directory.module.scss';
import { Route, Switch, useHistory } from 'react-router';
import Users from './users/Users';
import Programs from './program/ProgramDashboard';
import AddProgram from './program/add-program/addPrograms';
import { useSelector } from 'react-redux';
import AddUsers from './users/add-users/AddUsers';
import type { State } from '../general/types';
import UserPresenter from '../presenters/UserPresenter';
import Skills from './skills/Skills';
import AddSkills from './skills/add-skills/AddSkills';
import store from '../general/store';
import { skillActions } from '../slices/skillSlice';
import EncounterTypes from './encountertypes/EncounterTypes';
import AddEncounterType from './encountertypes/add-ecountertype/addEncounterType';
import EventTypes from './eventTypes/EventTypes';
import AddEventTypes from './eventTypes/add-eventTypes/AddEventTypes';
import Groups from './groups/Groups';
import AddGroup from './groups/add-group/addGroup';
import Surveys from './surveys/Surveys';
import AddSurvey from './surveys/add-surveys/AddSurvey';
import { Checkbox, Switch as AntSwitch } from 'antd';

export default function Directory() {
  React.useEffect(() => {
    store.dispatch(skillActions.fetch());
  }, []);
  const history = useHistory();
  const tab = useSelector((state) => {
    return state.general.url;
  });

  const navigate = (target) => {
    return () => {
      history.push(target);
    };
  };

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  return (
    <div>
      <div className={styles.main}>
        <div className={styles.center}>
          <div className={tab.includes('/users') ? styles.selected : ''}>
            <button
              className={styles.button}
              onClick={navigate('/schedule/admin/users')}
            >
              USERS
            </button>
          </div>
          <div className={tab.includes('/skills') ? styles.selected : ''}>
            <button
              className={styles.button}
              onClick={navigate('/schedule/admin/skills')}
            >
              SKILLS
            </button>
          </div>
          <div className={tab.includes('/programs') ? styles.selected : ''}>
            <button
              className={styles.button}
              onClick={navigate('/schedule/admin/programs')}
            >
              PROGRAMS
            </button>
          </div>
          <div
            className={tab.includes('/encounter_types') ? styles.selected : ''}
          >
            <button
              className={styles.button}
              onClick={navigate('/schedule/admin/encounter_types')}
            >
              ENCOUNTER TYPES
            </button>
          </div>
          <div className={tab.includes('/event_types') ? styles.selected : ''}>
            <button
              className={styles.button}
              onClick={navigate('/schedule/admin/event_types')}
            >
              EVENT TYPES
            </button>
          </div>
          <div className={tab.includes('/groups') ? styles.selected : ''}>
            <button
              className={styles.button}
              onClick={navigate('/schedule/admin/groups')}
            >
              GROUPS
            </button>
          </div>
          <div className={tab.includes('/surveys') ? styles.selected : ''}>
            <button
              className={styles.button}
              onClick={navigate('/schedule/admin/surveys')}
            >
              SURVEYS
            </button>
          </div>
        </div>
        <div className={styles.left}>
          {currentUserPresenter.isAdminUserType() && (
            <React.Fragment>
              {tab.includes('/users') && <AddUsers />}
              {tab.includes('/skills') && <AddSkills />}
              {tab.includes('/programs') && <AddProgram />}
              {tab.includes('/encounter_types') && <AddEncounterType />}
              {tab.includes('/event_types') && <AddEventTypes />}
              {tab.includes('/groups') && <AddGroup />}
              {tab.includes('/surveys') && <AddSurvey />}
            </React.Fragment>
          )}
        </div>
        <div className={styles.right1}>
          <div className={styles.activeToggle}>
            <p className={styles.activeText}>ACTIVE</p>
            <AntSwitch defaultChecked={true} />
          </div>
        </div>
        <div className={styles.right2}>
          <div className={styles.showAll}>
            <p className={styles.activeText}>SHOW ALL</p>
            <Checkbox />
          </div>
        </div>
      </div>
      <Switch>
        <Route path="/schedule/admin/users">
          <Users />
        </Route>
        <Route path="/schedule/admin/skills">
          <Skills />
        </Route>
        <Route path="/schedule/admin/programs">
          <Programs />
        </Route>
        <Route path="/schedule/admin/encounter_types">
          <EncounterTypes />
        </Route>
        <Route path="/schedule/admin/event_types">
          <EventTypes />
        </Route>
        <Route path="/schedule/admin/groups">
          <Groups />
        </Route>
        <Route path="/schedule/admin/surveys">
          <Surveys />
        </Route>
      </Switch>
    </div>
  );
}
