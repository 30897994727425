// @flow
import React from 'react';
import styles from '../../nav/Nav.module.scss';
import { useSelector } from 'react-redux';
import UserPresenter from '../../presenters/UserPresenter';
import type { State } from '../../general/types';
import MenuNav from '../../nav/menu-nav/MenuNav';
import logo from '../../assets/Centripoint-AA-standard-icon(light).svg';
import { useHistory } from 'react-router';

export default function CoachNav() {
  const history = useHistory();

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const tab = useSelector((state) => {
    return state.general.url;
  });

  const navigate = (target) => {
    return () => {
      history.push(target);
    };
  };

  return (
    <div className={styles.nav}>
      <div className={styles.left}>
        <div>
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className={styles.center}>
        <div
          className={`${
            tab.includes('/schedule') ||
            tab.endsWith('/coach-dashboard/covid-survey')
              ? styles.selected
              : ''
          } ${styles.tab} `}
        >
          <button
            name="scheduler button"
            className={styles.button}
            onClick={navigate('/coach-dashboard/schedule')}
          >
            SCHEDULER
          </button>
        </div>
        <div
          className={`${
            tab.includes('/calendar') ||
            tab.endsWith('/coach-dashboard/covid-survey')
              ? styles.selected
              : ''
          } ${styles.tab} `}
        >
          <button
            className={styles.button}
            name="calendar button"
            onClick={navigate('/coach-dashboard/calendar')}
          >
            CALENDAR
          </button>
        </div>
        <div
          className={`${
            tab.includes('/covid-survey-list') ? styles.selected : ''
          } ${styles.tab} `}
        >
          <button
            className={styles.button}
            name="survey button"
            onClick={navigate('/coach-dashboard/survey')}
          >
            SURVEY
          </button>
        </div>
      </div>
      <div className={styles.right}>
        {currentUserPresenter.user ? (
          <MenuNav userInitials={currentUserPresenter.initials()}></MenuNav>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
