import moment from 'moment';

export const getSortProps = (key) => ({
  sorter: (a, b) => {
    if (key === 'startTime' || key === 'endTime') {
      if (a.user.deleted) {
        return 1;
      } else {
        return (moment(a[key], 'H:mm a').format('HH:mm') || '').localeCompare(
          moment(b[key], 'H:mm a').format('HH:mm') || ''
        );
      }
    } else if (key === 'type') {
      if (a.user.deleted) {
        return 1;
      } else {
        return (a[key] || '').localeCompare(b[key] || '');
      }
    } else {
      return (a[key] || '').localeCompare(b[key] || '');
    }
  },
  sortDirections: ['ascend', 'descend', 'ascend'],
});
