// @flow
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../Modal.scss';
import styles from '../../Directory.module.scss';
import {
  Button,
  Form,
  Input,
  Modal,
  TimePicker,
  Checkbox,
  Radio,
  Upload,
  Alert,
} from 'antd';
import { UserTypeValues } from '../../../utilities/userTypeValues';
import AddressForm from '../../adddress-form/AddressForm';
import AddVehicle from '../add-vehicle/AddVehicle';
import editIcon from '../../../assets/edit-icon.svg';
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import moment from 'moment';
import type { CoachUser } from '../../../slices/coachUserSlice';
import type { State } from '../../../general/types';
import { getProps } from '../../../utilities/getProps';
import UpsertCoachVehicleStrategy from './UpsertCoachVehicleStrategy';
import Program from '../../../program/Program';
import { updateProgramTransfer } from '../../../slices/transferProgramSlice';
const { TextArea } = Input;

export default function AddCoach({ user }: { user?: CoachUser }) {
  const [submitting, setSubmitting] = React.useState(false);
  const [vehicles, setVehicles] = React.useState(user ? user.vehicles : []);
  const [backgroundCheckFileList, setBackgroundCheckFileList] = React.useState(
    []
  );
  const [
    immunizationRecordsFileList,
    setImmunizationRecordsFileList,
  ] = React.useState([]);
  const [vehicleFileList, setVehicleFileList] = React.useState([]);
  const [licenseFileList, setLicenseFileList] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [checked, setChecked] = React.useState(true);
  const dispatch = useDispatch();
  const programTransfer = useSelector((state: State) => {
    return state.programTransfer.transfer;
  });
  const [form] = Form.useForm();
  let initialValues = {};
  if (user) {
    initialValues = Object.assign(
      {},
      user,
      {
        workHoursStart: user.workHoursStart
          ? moment(user.workHoursStart, 'HH:mm')
          : null,
        workHoursEnd: user.workHoursEnd
          ? moment(user.workHoursEnd, 'HH:mm')
          : null,
      },
      { sendNotificationEmail: checked }
    );
  }

  const userSendNotificationEmail = user?.sendNotificationEmail;

  useEffect(() => {
    setChecked(userSendNotificationEmail);
  }, [userSendNotificationEmail]);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleOk = (e) => {
    hideModal();
  };

  const handleCancel = (e) => {
    hideModal();
  };

  let onSubmit = (values) => {
    setVehicles([values]);
  };

  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  let formSubmit = async (values) => {
    setSubmitting(true);
    const vehicleFormData = (() => {
      const vehicleFormData = new FormData();

      let vehicle: any = null;
      if (vehicles && vehicles.length) {
        vehicle = vehicles[0];
      } else {
        return null;
      }

      Object.keys(vehicle).forEach((key) => {
        if (typeof vehicle[key] === 'undefined') {
          return;
        }
        vehicleFormData.append(key, vehicle[key]);
      });

      vehicleFileList.forEach((file) => {
        vehicleFormData.append('vehicle_photo_upload', file);
      });
      licenseFileList.forEach((file) => {
        vehicleFormData.append('license_photo_upload', file);
      });

      if (vehicleFormData.get('id')) {
        //$FlowFixMe
        vehicleFormData.id = vehicleFormData.get('id');
      }

      return vehicleFormData;
    })();
    const formData = new FormData();
    backgroundCheckFileList.forEach((file) => {
      formData.append('background_check_upload', file);
    });
    immunizationRecordsFileList.forEach((file) => {
      formData.append('immunization_records_upload', file);
    });
    if (user) {
      // Pass no Transfer State
    } else {
      values.program = programTransfer.targetKeys;
    }
    const newValues = Object.assign(
      {},
      values,
      {
        workHoursStart: values.workHoursStart.format('HH:mm'),
        workHoursEnd: values.workHoursEnd.format('HH:mm'),
      },
      { sendNotificationEmail: checked }
    );

    Object.keys(newValues).forEach((key) => {
      if (typeof newValues[key] === 'undefined') {
        return;
      }
      if (typeof newValues[key] === 'object') {
        let newValue = JSON.stringify(newValues[key]);
        formData.append(key, newValue);
      } else {
        formData.append(key, newValues[key]);
      }
    });
    //$FlowFixMe
    formData.id = formData.get('id');

    let upsertStrategy = new UpsertCoachVehicleStrategy(
      formData,
      vehicleFormData
    );
    const result = await upsertStrategy.execute();
    if (result) {
      hideModal();
      form.resetFields();
      setBackgroundCheckFileList([]);
      setImmunizationRecordsFileList([]);
      setLicenseFileList([]);
      setVehicleFileList([]);
    }
    dispatch(
      updateProgramTransfer({
        targetKeys: [],
        selectedKeys: [],
      })
    );
    setSubmitting(false);
  };

  const options = [
    { label: 'M', value: 'M' },
    { label: 'Tu', value: 'Tu' },
    { label: 'W', value: 'W' },
    { label: 'Th', value: 'Th' },
    { label: 'F', value: 'F' },
  ];

  const backgroundCheckProps = getProps(
    backgroundCheckFileList,
    setBackgroundCheckFileList
  );
  const immunizationRecordProps = getProps(
    immunizationRecordsFileList,
    setImmunizationRecordsFileList
  );
  const vehicleProps = getProps(vehicleFileList, setVehicleFileList);
  const licenseProps = getProps(licenseFileList, setLicenseFileList);

  const getInitialNormalized = (values: any) => {
    if (Object.keys(values).length === 0) {
      return values;
    } else {
      const newValues = Object.assign({}, values, {
        workHoursStart: values.workHoursStart?.format('HH:mm'),
        workHoursEnd: values.workHoursEnd?.format('HH:mm'),
      });
      return newValues;
    }
  };

  return (
    <div>
      {user ? (
        <Button type="link" onClick={showModal}>
          <img src={editIcon} alt="pencil" width="25" height="25" />
        </Button>
      ) : (
        <button className={styles.teammate} onClick={showModal}>
          <div>
            <PlusCircleOutlined className="plus-icon-dashboard" />
            <p className="add-text-align">ADD COACH</p>
          </div>
        </button>
      )}
      <Modal
        width="900px"
        title={user ? 'Update Coach' : 'Add Coach'}
        visible={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          onFinish={formSubmit}
          className="forgot-password"
          form={form}
          initialValues={initialValues}
        >
          {initialValues.isActive === false ? (
            <div>
              <Alert
                message="Coach is Deactivated"
                type="warning"
                showIcon
                closable
              />
            </div>
          ) : (
            ''
          )}
          <div className="split">
            <div>
              <Form.Item name="id" hidden={true}>
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  { required: true, message: 'Please Input a First Name!' },
                ]}
              >
                <Input type="text" placeholder="First Name" />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  { required: true, message: 'Please Input a Last Name!' },
                ]}
              >
                <Input type="text" placeholder="Last Name" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'Please Input an Email Address!',
                  },
                ]}
              >
                <Input placeholder="Email Address" />
              </Form.Item>

              <AddressForm required={false} />

              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                rules={[
                  { required: true, message: 'Please Input a Phone Number!' },
                ]}
              >
                <Input
                  type="tel"
                  placeholder="Phone Number"
                  pattern="^[0-9-+\s()]*$"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item name="note" label="Notes">
                <TextArea rows={4} />
              </Form.Item>
              <Form.Item name="workDays" label="Work Days">
                <Checkbox.Group options={options} />
              </Form.Item>
              <label className="ant-form-label">Work Hours</label>
              <div className="work-hours">
                <Form.Item
                  name="workHoursStart"
                  label="Start"
                  rules={[
                    { required: true, message: 'Please Enter Starting Hours!' },
                  ]}
                >
                  <TimePicker use12Hours format="h:mm a" />
                </Form.Item>
                <Form.Item
                  name="workHoursEnd"
                  label="End"
                  rules={[
                    { required: true, message: 'Please Enter Ending Hours!' },
                  ]}
                >
                  <TimePicker use12Hours format="h:mm a" />
                </Form.Item>
              </div>

              <div className={styles.section}>
                <label className="ant-form-label">Vehicle Details</label>
                {user && user.vehicles.length ? (
                  user.vehicles.map((vehicle, i) => {
                    return (
                      <AddVehicle
                        onSubmit={onSubmit}
                        vehicle={vehicle}
                        key={i}
                        vehicleProps={vehicleProps}
                        licenseProps={licenseProps}
                      />
                    );
                  })
                ) : (
                  <AddVehicle
                    onSubmit={onSubmit}
                    vehicleProps={vehicleProps}
                    licenseProps={licenseProps}
                  />
                )}
              </div>

              <div className={styles.section}>
                <label className="ant-form-label">Documentation Upload</label>
                <div>
                  <Upload {...backgroundCheckProps}>
                    <Button className={styles.plusButton}>
                      <PlusCircleOutlined /> <span>Background Check</span>
                    </Button>
                  </Upload>
                  {user &&
                    user.backgroundCheck.map((document, index) => {
                      return (
                        <div key={index}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={document.sThreeLocation}
                          >
                            Background Check {index + 1}
                          </a>
                        </div>
                      );
                    })}
                </div>
                <div>
                  <Upload {...immunizationRecordProps}>
                    <Button className={styles.plusButton}>
                      <PlusCircleOutlined /> <span>Immunization Records</span>
                    </Button>
                  </Upload>
                  {user &&
                    user.immunizationRecords.map((document, index) => {
                      return (
                        <div key={index}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={document.sThreeLocation}
                          >
                            Immunization Record {index + 1}
                          </a>
                        </div>
                      );
                    })}
                </div>
              </div>

              <Form.Item
                name="userType"
                label="USER TYPE"
                rules={[
                  { required: true, message: 'Please Select a User Type!' },
                ]}
              >
                <Radio.Group>
                  <Radio value={UserTypeValues.COACH}>COACH</Radio>
                  <Radio value={UserTypeValues.TRANSPORTER}>TRANSPORTER</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <Checkbox checked={checked} onChange={onChange}>
                  Send Notification Emails
                </Checkbox>
              </Form.Item>
              <Form.Item
                label="Program"
                rules={[
                  { required: true, message: 'Please Select a Program!' },
                ]}
              >
                <Program initial={getInitialNormalized(initialValues)} />
              </Form.Item>
            </div>
          </div>

          <div className="buttons">
            <Button type="primary" htmlType="submit" disabled={submitting}>
              {user ? 'Update Coach' : 'Create New Coach'}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
