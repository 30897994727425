// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Input } from 'antd';
import { Link } from 'react-router-dom';
import './ResetPassword.scss';
import { resetPassword } from '../../slices/authSlice';
import { useParams } from 'react-router';
import LoginForm from '../login-form/LoginForm';

export default function ResetPassword() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [done, setDone] = React.useState(false);

  let { uid, token } = useParams();

  let handleReset = async (values: any) => {
    let result = await dispatch(
      resetPassword({ newPassword: values.newPassword, uid: uid, token: token })
    );
    if (result) {
      setDone(true);
    }
  };

  return (
    <div>
      {done ? (
        <div>
          <div className="done">Your password has successfully been reset</div>
          <LoginForm />
        </div>
      ) : (
        <Form onFinish={handleReset} className="reset-password" form={form}>
          <label>New Password</label>
          <Form.Item
            name="newPassword"
            rules={[
              { required: true, message: 'Please input your new password!' },
            ]}
          >
            <Input
              id="newPassword"
              type="password"
              autoComplete="new-password"
              placeholder="Password"
            />
          </Form.Item>
          <div className="buttons">
            <Link to="/login">Login</Link>
            <Button type="primary" htmlType="submit">
              Reset Password
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
}
