import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { defaultReducers } from './defaultReducers';

export interface Agency {
  id: number;
  name: string;
  programs: {
    id: number,
    name: string,
  }[];
  transportationTypes: {
    id: number,
    name: string,
  }[];
  zones: {
    id: number,
    zone: string,
  }[];
}

export interface CurrentUser {
  id: number;
  firstName: string;
  lastName: string;
  userType: number;
  uciNumber: string;
  zone: string;
  agency: Agency;
  addresses: {
    address: string,
    addressTwo: string,
    city: string,
    state: string,
    zip: string,
  }[];
}

export interface AuthState {
  isFetching: boolean;
  error: Error;
  currentUser: CurrentUser;
}

const initialState: AuthState = {
  isFetching: false,
  error: null,
  currentUser: null,
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userLogOut(state: AuthState, action: PayloadAction<any>) {
      // resets the state in the main root reducer
    },
    getStart: defaultReducers.getStart,
    getSuccess: defaultReducers.getSuccess,
    getFailure: defaultReducers.getFailure,
  },
});

export const {
  getStart,
  getSuccess,
  getFailure,
  currentUser,
  userLogOut,
} = auth.actions;

export const authReducer = auth.reducer;

export const login = (details: { email: string, password: string }) => async (
  dispatch: any
) => {
  try {
    localStorage.removeItem('authToken');
    dispatch(getStart());
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/login/`,
      details
    );
    localStorage.setItem('authToken', true);
    dispatch(getSuccess());
    return response;
  } catch (err) {
    dispatch(getFailure(err));
  }
};

export const forgotPassword = (details: { email: string }) => async (
  dispatch: any
) => {
  try {
    dispatch(getStart());
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL || ''}/auth/users/reset_password/`,
      details
    );
    dispatch(getSuccess());
    return response;
  } catch (err) {
    dispatch(getFailure(err));
  }
};

export const resetPassword = (details: {
  uid: string,
  token: string,
  password: string,
}) => async (dispatch: any) => {
  try {
    dispatch(getStart());
    const response = await axios.post(
      `${
        process.env.REACT_APP_SERVER_URL || ''
      }/auth/users/reset_password_confirm/`,
      details
    );
    dispatch(getSuccess());
    return response;
  } catch (err) {
    dispatch(getFailure(err));
  }
};

export const fetchUser = () => async (dispatch: any) => {
  //Dont fetch the current user unless login has already occurred
  if (localStorage.getItem('authToken') === null) return;

  try {
    dispatch(getStart());
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/current_user/`
    );
    const currentUser = response.data;
    localStorage.setItem('userType', currentUser.userType);
    dispatch(getSuccess({ currentUser: currentUser }));
  } catch (err) {
    dispatch(getFailure(err));
  }
};

export const logout = () => async (dispatch: any) => {
  try {
    dispatch(getStart());
    dispatch(userLogOut());
    if (localStorage.getItem('authToken')) {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/logout/`,
        {}
      );
    }
    dispatch(getSuccess());
  } catch (err) {
    dispatch(getFailure(err));
  }
  localStorage.removeItem('authToken');
  localStorage.removeItem('userType');
  localStorage.removeItem('master_date');
  localStorage.removeItem('scheduleType');
  localStorage.removeItem('schedule_date');
  localStorage.removeItem('program');
  localStorage.removeItem('time_stamp');
};
