// @flow
import { createSlice } from '@reduxjs/toolkit';
import { defaultReducers } from './defaultReducers';
import { DefaultRequests } from './defaultRequests';

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  program: number[];
}

export interface UserState {
  loading: boolean;
  error: string;
  list: User[];
  byId: {};
}

const initialState: UserState = {
  loading: false,
  error: '',
  list: [],
  byId: {},
};

const users = createSlice({
  name: 'users',
  initialState,
  reducers: defaultReducers,
});

export const userReducer = users.reducer;
export const userActions = new DefaultRequests(users.actions, `aerostar/user`);
