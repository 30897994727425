import type { ScheduleState, ScheduleType } from '../slices/scheduleSlice';
import { CarOutlined, FileDoneOutlined, SendOutlined } from '@ant-design/icons';
import React from 'react';

export const getScheduleTypeIcon = (
  schedule: ScheduleState,
  type: ScheduleType
) => {
  if (schedule.types[0].id === type.id) {
    return <CarOutlined />;
  }
  if (schedule.types[schedule.types.length - 1].id === type.id) {
    return <FileDoneOutlined />;
  }
  return <SendOutlined />;
};

export const scheduleClassMap = [
  { backgroundColor: '#f4f5f7' },
  { backgroundColor: '#E3FFE2' },
  { backgroundColor: '#D9FFFB' },
  { backgroundColor: '#FEEDE2' },
  { backgroundColor: '#E7F6FD' },
];

export const getScheduleTypeClass = (schedule: ScheduleState) => {
  const index = schedule.types.findIndex((type) => {
    return type.id === schedule.type.id;
  });
  if (index > -1) {
    return scheduleClassMap[index % 5];
  }
  return scheduleClassMap[0];
};

export const calendarClassMap = [
  {
    backgroundColor: '#f4f5f7',
    color: '#636363',
    borderLeft: '5px solid #989898',
  },
  {
    backgroundColor: '#E3FFE2',
    color: '#456D44',
    borderLeft: '5px solid #AEECAC',
  },
  {
    backgroundColor: '#D9FFFB',
    color: '#237B6F',
    borderLeft: '5px solid #42D0B8',
  },
  {
    backgroundColor: '#FEEDE2',
    color: '#AF521D',
    borderLeft: '5px solid #F99F68',
  },
  {
    backgroundColor: '#E7F6FD',
    color: '#1C69A1',
    borderLeft: '5px solid #5AABFF',
  },
];

// for CoachCalendar event color
export const getCalendarEventColor = (schedule, type_id) => {
  const index = schedule.types.findIndex((type) => {
    return type.id === type_id;
  });
  if (index > -1) {
    return calendarClassMap[index % 5];
  }
  return calendarClassMap[0];
};
