// @flow
import React from 'react';
import { Button, Form, Input, Modal, DatePicker, Upload } from 'antd';
import '../../Modal.scss';
import styles from '../../Directory.module.scss';
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import type { Vehicle } from '../../../slices/coachUserSlice';
import moment from 'moment';

export default function AddVehicle({
  onSubmit,
  vehicle,
  vehicleProps,
  licenseProps,
}: {
  onSubmit: any,
  vehicle?: Vehicle,
  licenseProps: any,
  vehicleProps: any,
}) {
  const [visible, setVisible] = React.useState(false);
  const [form] = Form.useForm();

  let initialValues = {};
  if (vehicle) {
    initialValues = Object.assign({}, vehicle, {
      docExpiration: vehicle.docExpiration
        ? moment(vehicle.docExpiration)
        : null,
    });
  }
  const [savedValues, setValues] = React.useState(initialValues);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleOk = (e) => {
    hideModal();
  };

  const handleCancel = (e) => {
    hideModal();
  };

  let formSubmit = async (values) => {
    onSubmit(
      Object.assign({}, values, {
        docExpiration: values.docExpiration.toISOString().slice(0, 10),
      })
    );
    setValues(values);
    hideModal();
  };

  return (
    <div>
      {savedValues.make ? (
        <div>
          Vehicle: {savedValues.color} {savedValues.year} {savedValues.make}{' '}
          {savedValues.model}
          <div>
            <button
              onClick={showModal}
              type="button"
              className={styles.plusButton}
            >
              <PlusCircleOutlined /> <span>Edit Vehicle Information</span>
            </button>
          </div>
        </div>
      ) : (
        <button onClick={showModal} type="button" className={styles.plusButton}>
          <PlusCircleOutlined /> <span>Add Vehicle Information</span>
        </button>
      )}
      <Modal
        width="900px"
        title="Vehicle Details"
        visible={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          onFinish={formSubmit}
          form={form}
          initialValues={initialValues}
        >
          <div className="split">
            <div>
              <div className="section">Description</div>
              <Form.Item name="id" hidden={true}>
                <Input type="text" />
              </Form.Item>
              <Form.Item
                label="Make"
                name={'make'}
                rules={[{ required: true, message: 'Please Input the Make!' }]}
              >
                <Input placeholder="Make" />
              </Form.Item>
              <Form.Item
                label="Model"
                name={'model'}
                rules={[{ required: true, message: 'Please Input the Model!' }]}
              >
                <Input placeholder="Model" />
              </Form.Item>
              <Form.Item
                label="Year"
                name={'year'}
                rules={[{ required: true, message: 'Please Input the Year!' }]}
              >
                <Input placeholder="Year" />
              </Form.Item>
              <Form.Item
                label="Color"
                name={'color'}
                rules={[{ required: true, message: 'Please Input a Color!' }]}
              >
                <Input placeholder="Color" />
              </Form.Item>
              <div className="section">Drivers License</div>
              <Form.Item
                label="License Number"
                name={'licenseNumber'}
                rules={[
                  {
                    required: true,
                    message: 'Please Input the License Number!',
                  },
                ]}
              >
                <Input placeholder="License Number" />
              </Form.Item>
              <Form.Item
                label="Doc Expiration"
                name={'docExpiration'}
                rules={[
                  {
                    required: true,
                    message: 'Please Input the Doc Expiration!',
                  },
                ]}
              >
                <DatePicker placeholder="Doc Expiration" />
              </Form.Item>
              <Upload {...licenseProps}>
                <Button className={styles.plusButton}>
                  <PlusCircleOutlined /> <span>Upload License Photo</span>
                </Button>
              </Upload>
              {vehicle &&
                vehicle.licensePhoto.map((document, index) => {
                  return (
                    <div key={index}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={document.sThreeLocation}
                      >
                        License Photo {index + 1}
                      </a>
                    </div>
                  );
                })}
            </div>
            <div>
              <div className="section">Vehicle Details</div>
              <Upload {...vehicleProps}>
                <Button className={styles.plusButton}>
                  <PlusCircleOutlined /> <span>Upload Photo of Vehicle</span>
                </Button>
              </Upload>
              {vehicle &&
                vehicle.vehiclePhoto.map((document, index) => {
                  return (
                    <div key={index}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={document.sThreeLocation}
                      >
                        Vehicle Photo {index + 1}
                      </a>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="buttons">
            <Button type="primary" htmlType="submit">
              {vehicle ? 'Edit Vehicle' : 'Add New Vehicle'}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
