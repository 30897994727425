// @flow
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultReducers } from './defaultReducers';
import axios from 'axios';

export interface ParticipantHistory {
  id: number;
  createdBy: string;
  date: string;
  changed: string[];
}

export interface ParticipantHistoryState {
  loading: boolean;
  error: string;
  history: ParticipantHistory[];
}

const initialState: ParticipantHistoryState = {
  loading: false,
  error: '',
  history: [],
};

const participantHistory = createSlice({
  name: 'participantHistory',
  initialState,
  reducers: {
    getStart: defaultReducers.getStart,
    getSuccess: defaultReducers.getSuccess,
    getFailure: defaultReducers.getFailure,
    appendHistory(state: ParticipantHistoryState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = '';
      Object.assign(state, action.payload);
    },
  },
});

export const {
  getStart,
  getSuccess,
  getFailure,
  appendHistory,
} = participantHistory.actions;

export const participantHistoryReducer = participantHistory.reducer;

export const fetchParticipantHistory = (participantId: number) => async (
  dispatch: any
) => {
  const participantHistory = await axios.get(
    `${
      process.env.REACT_APP_SERVER_URL || ''
    }/aerostar/participant/${participantId}/history`
  );
  try {
    dispatch(getStart());
    dispatch(getSuccess({ history: participantHistory.data }));
  } catch (err) {
    dispatch(getFailure(err));
  }
};

export const refreshParticipantHistory = () => (dispatch: any) => {
  try {
    dispatch(getStart());
    dispatch(appendHistory({ history: [] }));
  } catch (err) {
    dispatch(getFailure(err));
  }
};
