// @flow
import axios from 'axios';
import { history } from './myHistory';

axios.defaults.xsrfCookieName = process.env.REACT_APP_COOKIE_NAME;
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.withCredentials = true;

export default function initializeAxios() {
  axios.interceptors.request.use(
    (config: any) => {
      config.headers['Content-Type'] = 'application/json';
      return config;
    },
    (error) => {
      let message = 'Error Making Request';
      Promise.reject({ message: message });
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      let message = '';
      if (error.response) {
        if (error.response.status === 403) {
          setTimeout(() => {
            history.push('/login');
          });
        }
        Object.keys(error.response.data).forEach((key) => {
          const value = error.response.data[key];
          if (value.constructor === Array) {
            message += value.join('\n');
          } else {
            message += value;
          }
        });
      } else if (error.message) {
        error.message = message;
      }

      message = message || 'Error Completing Request';

      return Promise.reject({ message: message });
    }
  );
}
