// @flow
import React from 'react';
import styles from './SearchComponent.module.scss';
import UserSearch from '../../user-search/UserSearch';
import UserSearchInput from '../../user-search-input/UserSearchInput';

export default function SearchComponent({
  id,
  partial,
  total,
}: {
  id: string,
  partial: number,
  total: number,
}) {
  return (
    <div className={styles.search}>
      <UserSearch id={id} partial={partial} total={total} className="dark" />
      <UserSearchInput id={id} />
    </div>
  );
}
