// @flow
import { createSlice } from '@reduxjs/toolkit';
import { defaultReducers } from './defaultReducers';
import { DefaultRequests } from './defaultRequests';
import axios from 'axios';

export interface ParticipantUser {
  id: number;
  firstName: string;
  lastName: string;
  note: string;
  workDays: string[];
}

export interface ParticipantUserState {
  loading: boolean;
  error: string;
  list: ParticipantUser[];
  byId: {};
}

const initialState: ParticipantUserState = {
  loading: false,
  error: '',
  list: [],
  byId: {},
};

const participantUsers = createSlice({
  name: 'participantUsers',
  initialState,
  reducers: defaultReducers,
});

export const participantUserReducer = participantUsers.reducer;
export const participantActions = new DefaultRequests(
  participantUsers.actions,
  `aerostar/participant`
);

export const recordParticipantAddressHistory = async (address: any) => {
  if (address.id) {
    await axios.patch(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/addresses/${
        address.id
      }/`,
      address
    );
  } else {
    await axios.post(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/addresses/`,
      address
    );
  }
};
