import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import store from '../../general/store';
import { coachActions } from '../../slices/coachUserSlice';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import { DatePicker, Switch } from 'antd';
import { getCoachCalendar } from '../../slices/coachesSlice';
import './CoachCalendar.scss';
import EventPopover from './EventPopover';
import moment from 'moment';

const CoachCalendar = () => {
  const [view, setView] = React.useState('dayGridWeek');
  const [weekends, setWeekends] = React.useState(false);
  const { id } = useParams();

  const calendarRef = React.createRef();

  React.useEffect(() => {
    store.dispatch(coachActions.fetch());
  }, []);

  const coachUsers = useSelector((state: State) => {
    return state.coachUsers;
  });

  const coach = coachUsers.byId[id];

  const getCalendarData = async (info, successCallback) => {
    try {
      if (info) {
        const start = moment(info.start).format('YYYY-MM-DD');
        const end = moment(info.end).format('YYYY-MM-DD');

        const response = await getCoachCalendar(id, start, end);
        successCallback(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = async (value) => {
    if (value) {
      let selectedStartDate = value._d;

      let calendar = calendarRef.current.getApi();
      calendar.gotoDate(selectedStartDate);
    }
  };

  function renderEventContent(eventInfo) {
    return <EventPopover eventInfo={eventInfo} view={view} />;
  }

  const datesSet = (dateInfo) => {
    var view = dateInfo.view;
    var viewType = view.type;
    setView(viewType);
  };

  return (
    <div className="calendar">
      <div className="calendar-aside">
        <div className="aside-content">
          <div className="coach-cal-header">
            <h3 className="coach-name">
              {coach?.firstName} {coach?.lastName}
            </h3>
            <h3 className="coach-name">Calendar</h3>
          </div>
          <div id="week-picker" className="picker">
            <h4>Select Week</h4>
            <DatePicker
              className={'custom-picker'}
              picker="week"
              onChange={onChange}
              aria-labelledby="week-picker"
            />
          </div>
          <div className="picker">
            <h4>Show Weekends</h4>
            <Switch title="weekends" onChange={() => setWeekends(!weekends)} />
          </div>
        </div>
      </div>
      <div className="calendar-container">
        <FullCalendar
          ref={calendarRef}
          plugins={[timeGridPlugin, dayGridPlugin]}
          events={(fetchInfo, successCallback, failureCallback) =>
            getCalendarData(fetchInfo, successCallback, failureCallback)
          }
          eventContent={renderEventContent}
          initialView={'dayGridWeek'}
          slotEventOverlap={false}
          nowIndicator={true}
          height={'100vh'}
          slotMinTime={'04:00:00'}
          weekends={weekends}
          allDaySlot={false}
          datesSet={datesSet}
          headerToolbar={{
            left: 'title',
            center: 'timeGridDay dayGridWeek',
            right: 'prev,next',
          }}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            meridiem: true,
          }}
        />
      </div>
    </div>
  );
};

export default CoachCalendar;
