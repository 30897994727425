// @flow
import React from 'react';
import './CoachCardBack.scss';
import { Radio, Input, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { CoachStatusTypesEnum } from '../../utilities/coachStatusTypesEnum';
import { patchCoach } from '../../slices/coachesSlice';
import type { ScheduleCoach } from '../../slices/coachesSlice';
const { TextArea } = Input;

export default function CoachCardBack({
  coachSchedule,
  toggleSide,
  partnerData,
}: {
  coachSchedule: ScheduleCoach,
  toggleSide: any,
  coachId: any,
  partnerData: any,
}) {
  const dispatch = useDispatch();
  const [note, setNote] = React.useState(coachSchedule.note);
  const [status, setStatus] = React.useState(coachSchedule.status);

  const onStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const onNoteChange = (e) => {
    setNote(e.target.value);
  };

  const close = (e) => {
    toggleSide();
  };

  const save = (e) => {
    dispatch(patchCoach({ id: coachSchedule.id, note, status }));
    toggleSide();
  };

  let generateList = () => {
    return partnerData.map((item) => {
      return (
        <li key={item[0]}>
          <div className="name">{item[0]}</div>
          <div className="text">{item[1]}</div>
          <div className="text">{item[2]}</div>
          <br></br>
        </li>
      );
    });
  };

  return (
    <div className="coach-card-back">
      <h4>Attendance</h4>
      <div className="content">
        <Radio.Group onChange={onStatusChange} value={status}>
          <Radio value={CoachStatusTypesEnum.SCHEDULED}>Scheduled</Radio>
          <Radio value={CoachStatusTypesEnum.SCHEDULED_OFF}>
            Scheduled Off
          </Radio>
          <Radio value={CoachStatusTypesEnum.UNSCHEDULED}>Unscheduled</Radio>
          <Radio value={CoachStatusTypesEnum.CALLED_OUT}>Called Out</Radio>
        </Radio.Group>

        <div className="notes">
          <h4>Notes</h4>
          <TextArea rows={2} onChange={onNoteChange} value={note} />
        </div>

        <div className="footer">
          <Button className="cancel" onClick={close}>
            Cancel
          </Button>
          <Button className="ok" type="primary" onClick={save}>
            OK
          </Button>
        </div>
      </div>
      <h4>Partners</h4>
      <div className="partners-content">
        <ul className="partner-list">{generateList()}</ul>
      </div>
    </div>
  );
}
