import { CoachUser, coachActions } from '../../../slices/coachUserSlice';
import { vehicleActions } from '../../../slices/vehicleSlice';
import store from '../../../general/store';
import { Form } from 'antd';

export default class UpsertCoachVehicleStrategy {
  formData: Form;
  coachUser: CoachUser;
  formDataVehicle: any;

  constructor(formData, formDataVehicle) {
    this.formData = formData;
    this.formDataVehicle = formDataVehicle;
  }

  //First Update the Coach, then assign the new id to the vehicle and create/update that as well.
  async execute() {
    const coachResponse = await store.dispatch(
      coachActions.upsert(this.formData)
    );
    if (coachResponse && coachResponse['id']) {
      this.coachUser = coachResponse;
      if (!this.formDataVehicle) return true;

      this.formDataVehicle.append('ownedBy', coachResponse['id']);

      const vehicleResponse = await store.dispatch(
        vehicleActions.upsert(this.formDataVehicle)
      );

      if (vehicleResponse && vehicleResponse['id']) {
        this.coachUser = Object.assign({}, this.coachUser, {
          vehicles: [vehicleResponse],
        });
        store.dispatch(coachActions.actions.update(this.coachUser));
        return true;
      }
    }

    return false;
  }
}
