// @flow
import React from 'react';
import '../../../directory/Modal.scss';
import './AddScheduleType.scss';
import styles from '../../../directory/Directory.module.scss';
import {
  Button,
  Form,
  Input,
  Modal,
  TimePicker,
  Checkbox,
  Select,
  Table,
  Alert,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import type { State } from '../../../general/types';
import UserPresenter from '../../../presenters/UserPresenter';
import editIcon from '../../../assets/edit-icon.svg';
import type { ScheduleType } from '../../../slices/scheduleTypeSlice';
import { scheduleTypeActions } from '../../../slices/scheduleTypeSlice';
import { eventTypeActions } from '../../../slices/eventTypeSlice';
import store from '../../../general/store';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { MenuOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
const { Option } = Select;
const { confirm } = Modal;

export default function AddScheduleType({
  scheduleType,
  initialEvents,
  programID,
  programStatus,
}: {
  scheduleType?: ScheduleType,
  initialEvents?: any,
  programID?: number,
  programStatus?: boolean,
}) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    store.dispatch(eventTypeActions.fetch());
  }, []);
  const [submitting, setSubmitting] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [showWarning, setShowWarning] = React.useState(false);
  const getInitialSelectedEvents = () => {
    let selectedEvents = [];
    if (initialEvents !== undefined) {
      if (initialEvents.length > 0) {
        for (let i = 0; i < initialEvents.length; i++) {
          selectedEvents.push(initialEvents[i].eventType);
        }
      }
      return selectedEvents;
    } else {
      return [];
    }
  };

  const [selectEventValue, setSelectEventValue] = React.useState(
    getInitialSelectedEvents()
  );
  const [dataSource, setDataSource] = React.useState(
    initialEvents === undefined ? [] : initialEvents
  );
  const [
    eventModificationWarning,
    setEventModificationWarning,
  ] = React.useState(false);
  const [form] = Form.useForm();
  let initialValues = {};
  if (scheduleType) {
    initialValues = Object.assign({}, scheduleType, {
      startTime: scheduleType.startTime
        ? moment(scheduleType.startTime, 'HH:mm')
        : null,
      endTime: scheduleType.endTime
        ? moment(scheduleType.endTime, 'HH:mm')
        : null,
      initialEventTypesAssigned: getInitialSelectedEvents(),
    });
  }

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });

  const eventList = useSelector((state: State) => {
    return state.event_types;
  });

  const events = eventList.list.map((id) => {
    return eventList.byId[id];
  });

  const currentUserPresenter = new UserPresenter(currentUser);

  const sortEventList = (results) => {
    let sortListData = [];
    for (let i = 0; i < results.length; i++) {
      const patchData = Object.assign({}, results[i], {
        eventTypeTranslation: results[i].event
          ? results[i].event
          : results[i].eventTypeTranslation,
        key: results[i].eventType
          ? results[i].eventType.toString()
          : results[i].id.toString(),
        index: i,
        sortOrder: i.toString(),
        scheduleType: scheduleType?.id,
        eventType: results[i].eventType ? results[i].eventType : results[i].id,
      });
      sortListData.push(patchData);
    }
    setDataSource(sortListData);
  };

  const handleChange = (value) => {
    if (scheduleType !== undefined) setEventModificationWarning(true);
    setShowWarning(true);

    setSelectEventValue(value);
    let oldEventSortList = [...dataSource];
    let newValueOnly = [...value];
    let existingSortListValues = [];
    let result = [];
    for (let i = 0; i < value.length; i++) {
      let item = oldEventSortList.filter((event) => {
        if (event.eventType === value[i]) {
          existingSortListValues.push(value[i]);
          let index = newValueOnly.indexOf(event.eventType);
          if (index > -1) {
            newValueOnly.splice(index, 1);
          }
          return true;
        } else {
          return false;
        }
      });
      if (item.length > 0) {
        result.push(item[0]);
      }
    }
    if (newValueOnly.length === 0) {
      sortEventList(result);
    } else {
      let results = [...result];
      for (let i = 0; i < newValueOnly.length; i++) {
        let selectedEventType = events.filter((e) => {
          return e.id === newValueOnly[i];
        });
        results.push(selectedEventType[0]);
      }
      sortEventList(results);
    }
  };

  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    form.resetFields();
    const resetEventActions = getInitialSelectedEvents();
    handleChange(resetEventActions);
    setVisible(false);
  };

  const handleOk = (e) => {
    hideModal();
  };

  const handleCancel = (e) => {
    hideModal();
  };

  const updateAPI = async (values) => {
    const newValues = Object.assign({}, values, {
      startTime: values.startTime.format('HH:mm'),
      endTime: values.endTime.format('HH:mm'),
      event_types: dataSource,
    });
    const result = await dispatch(
      scheduleTypeActions.upsert(
        Object.assign({}, newValues, {
          agency: currentUserPresenter.user.agency.id,
          program: programID,
        })
      )
    );
    if (result) {
      form.resetFields();
      hideModal();
    }
  };

  const openModal = async (values: any) => {
    confirm({
      title: `Changing the Event Actions mid-day will raise issues with reports. Are you sure you want to do this?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      async onOk() {
        updateAPI(values);
      },
      okButtonProps: { type: 'danger' },
      onCancel() {
        form.resetFields();
        const resetEventActions = getInitialSelectedEvents();
        handleChange(resetEventActions);
      },
    });
  };

  let formSubmit = async (values) => {
    setSubmitting(true);
    if (eventModificationWarning) {
      await openModal(values);
    } else {
      updateAPI(values);
    }
    setSubmitting(false);
  };

  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
  ));

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Order',
      width: 30,
      className: 'drag-visible',
      render: (record) => (
        <p style={{ margin: '0' }}>{parseInt(record.sortOrder) + 1}</p>
      ),
    },
    {
      title: 'Event',
      dataIndex: 'eventTypeTranslation',
      className: 'drag-visible',
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      for (let i = 0; i < newData.length; i++) {
        newData[i].index = i;
        newData[i].sortOrder = i.toString();
      }
      setEventModificationWarning(true);
      setDataSource(newData);
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <div style={{ display: 'flex' }}>
      {scheduleType ? (
        <Button
          type="link"
          onClick={showModal}
          disabled={programStatus ? true : false}
        >
          <img src={editIcon} alt="pencil" width="25" height="25" />
        </Button>
      ) : (
        <button
          className={
            programStatus
              ? styles.scheduleTypeButtonDisable
              : styles.scheduleTypeButton
          }
          onClick={showModal}
          disabled={programStatus ? true : false}
        >
          + Add Schedule Type
        </button>
      )}
      <Modal
        width="900px"
        title={scheduleType ? 'Update Schedule Type' : 'Add Schedule Type'}
        visible={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          onFinish={formSubmit}
          className="forgot-password"
          form={form}
          initialValues={initialValues}
        >
          <div className="split">
            <div>
              <Form.Item name="id" hidden={true}>
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="type"
                label="Type Name"
                rules={[
                  { required: true, message: 'Please Input a Type Name!' },
                ]}
              >
                <Input type="text" placeholder="Type Name" />
              </Form.Item>
              <Form.Item name="requireSurvey" valuePropName="checked">
                <Checkbox>Enable Survey</Checkbox>
              </Form.Item>
              <label className="ant-form-label">Schedule Type Duration</label>
              <div className="work-hours">
                <Form.Item
                  name="startTime"
                  label="Start"
                  rules={[
                    { required: true, message: 'Please Enter Starting Hours!' },
                  ]}
                >
                  <TimePicker use12Hours format="h:mm a" />
                </Form.Item>
                <Form.Item
                  name="endTime"
                  label="End"
                  rules={[
                    { required: true, message: 'Please Enter Ending Hours!' },
                  ]}
                >
                  <TimePicker use12Hours format="h:mm a" />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="event-type-section">
            <Form.Item
              name="initialEventTypesAssigned"
              label="Select Available Coach Actions"
              rules={[
                { required: true, message: 'Please Assign Event Actions' },
              ]}
            >
              <Select
                mode="multiple"
                style={{ width: '100%', padding: '10px 0px' }}
                placeholder="assign coach actions"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={selectEventValue}
                optionFilterProp="children"
                filterOption
              >
                {events.map((event) => {
                  return (
                    <Option
                      key={event?.id}
                      value={event?.id}
                      label={event?.event}
                    >
                      {event?.event}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <div hidden={!showWarning} style={{ marginBottom: '10px' }}>
              <Alert
                message={
                  'Warning: Changing the order or adding/removing values mid-day can affect reporting for today (' +
                  new Date().toLocaleString('en', {
                    year: 'numeric',
                    month: '2-digit',
                    day: 'numeric',
                  }) +
                  "). It's recommended to do this after hours."
                }
                type="warning"
                showIcon
              />
              <Alert
                message="Note: No data will be lost, but some reporting might get out of sync for the day."
                type="info"
                showIcon
              />
            </div>
            <div>
              <label className="ant-form-label">
                Drag Sort Icons to Rearrange Action Order
              </label>
              <Table
                pagination={false}
                dataSource={dataSource ? dataSource : null}
                columns={columns}
                rowKey="index"
                style={{ marginTop: '22px' }}
                components={{
                  body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                  },
                }}
              />
            </div>
          </div>
          <div className="buttons">
            <Button type="primary" htmlType="submit" disabled={submitting}>
              {scheduleType
                ? 'Update Schedule Type'
                : 'Create New Schedule Type'}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
