// @flow
import React from 'react';
import './ParticipantList.scss';
import { useSelector } from 'react-redux';
import ParticipantCard from '../participant-card/ParticipantCard';
import type { State } from '../../general/types';
import UserSearch from '../../user-search/UserSearch';
import UserSearchInput from '../../user-search-input/UserSearchInput';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import type { Participant } from '../../slices/participantSlice';
import { filterList } from '../../slices/searchSlice';
import { ParticipantStatusTypesEnum } from '../../utilities/participantStatusTypesEnum';
import { sortByLastNameFirstNameHelper } from '../../utilities/sortByLastNameFirstName';
import { Spin } from 'antd';

export default function ParticipantList({ isMaster }: { isMaster?: boolean }) {
  const scheduleState = useSelector((state: State) => {
    return state.schedule;
  });

  const participantState = useSelector((state: State) => {
    return state.participants;
  });

  const search = useSelector((state: State) => {
    return state.search;
  });

  let filteredParticipants: Participant[] = participantState.list
    .slice()
    .sort(sortByLastNameFirstNameHelper(participantState.byId))
    .map((participantId) => participantState.byId[participantId]);
  if (participantState.byId) {
    filteredParticipants = filteredParticipants.filter((participant) => {
      return !Boolean(participant.scheduledCoach);
    });
  }
  filteredParticipants = filterList(
    search.byId['participants'],
    filteredParticipants
  );

  const offParticipants = filteredParticipants.filter(
    (participant) => participant.status === ParticipantStatusTypesEnum.OFF
  );

  const noShowParticipants = filteredParticipants.filter(
    (participant) => participant.status === ParticipantStatusTypesEnum.NO_SHOW
  );

  const calledOutParticipants = filteredParticipants.filter(
    (participant) =>
      participant.status === ParticipantStatusTypesEnum.CALLED_OUT
  );

  filteredParticipants = filteredParticipants.filter(
    (participant) =>
      participant.status !== ParticipantStatusTypesEnum.OFF &&
      participant.status !== ParticipantStatusTypesEnum.CALLED_OUT &&
      participant.status !== ParticipantStatusTypesEnum.NO_SHOW &&
      participant.status !== ParticipantStatusTypesEnum.DECLINED_SERVICES
  );

  const participants = participantState.list.map(
    (participantId) => participantState.byId[participantId]
  );

  const getDraggable = (participant, index) => {
    return (
      <Draggable
        key={participant.id}
        draggableId={participant.id.toString()}
        index={index}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="card-holder"
          >
            <ParticipantCard
              key={`participant-${participant.id}`}
              participantId={participant.id}
              isMaster={isMaster}
            />
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <div className="participant-list">
      <div className="participant-box-section">
        <div className="header">
          <div>PARTICIPANT CENTER</div>
          <div>
            <UserSearch
              partial={filteredParticipants.length}
              total={participants.length}
              id="participants"
            />
          </div>
        </div>
        <div className="user-search-margin">
          <UserSearchInput id="participants" />
        </div>
        {scheduleState.loading ? (
          <Spin />
        ) : (
          <div className="people-list">
            <Droppable droppableId="participantDroppable_0">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {filteredParticipants.map((participant, index) => {
                    return getDraggable(participant, index);
                  })}
                  <div className="placeholder">{provided.placeholder}</div>
                </div>
              )}
            </Droppable>
          </div>
        )}
      </div>
      <div className="participant-box-section">
        <div className="participants-off">PARTICIPANTS OFF</div>
        <div className="people-list">
          <Droppable droppableId="participantDroppableOff_0">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="inner-list"
              >
                {offParticipants.map((participant, index) => {
                  return getDraggable(participant, index);
                })}
                <div className="placeholder">{provided.placeholder}</div>
              </div>
            )}
          </Droppable>
        </div>
      </div>
      <div className="participant-box-section">
        <div className="participants-off">CALLED OUT</div>
        <div className="people-list">
          <Droppable droppableId="participantDroppableOff_1">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="inner-list"
              >
                {calledOutParticipants.map((participant, index) => {
                  return getDraggable(participant, index);
                })}
                <div className="placeholder">{provided.placeholder}</div>
              </div>
            )}
          </Droppable>
        </div>
      </div>
      <div className="participant-box-section">
        <div className="participants-off">NO SHOW</div>
        <div className="people-list">
          <Droppable droppableId="participantDroppableOff_2">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="inner-list"
              >
                {noShowParticipants.map((participant, index) => {
                  return getDraggable(participant, index);
                })}
                <div className="placeholder">{provided.placeholder}</div>
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </div>
  );
}
