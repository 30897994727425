// @flow
import React from 'react';
import styles from './Nav.module.scss';
import { useSelector } from 'react-redux';
import UserPresenter from '../presenters/UserPresenter';
import type { State } from '../general/types';
import MenuNav from './menu-nav/MenuNav';
import logo from '../assets/servdrop-icon-only.png';
import { useHistory } from 'react-router';

export default function Nav() {
  const history = useHistory();

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const tab = useSelector((state) => {
    return state.general.url;
  });

  const navigate = (target) => {
    return () => {
      history.push(target);
    };
  };

  return (
    <div className={styles.nav}>
      <div className={styles.left}>
        <div>
          <img src={logo} alt="servdrop logo" />
        </div>
      </div>
      <div className={styles.center}>
        <div
          className={`${tab.includes('/scheduler') ? styles.selected : ''} ${
            styles.tab
          } `}
        >
          <button
            className={styles.button}
            onClick={navigate('/schedule/scheduler')}
          >
            SCHEDULER
          </button>
        </div>
        <div
          className={`${tab.includes('/master') ? styles.selected : ''} ${
            styles.tab
          } `}
        >
          <button
            className={styles.button}
            onClick={navigate('/schedule/master')}
          >
            MASTER SCHEDULE
          </button>
        </div>
        <div
          className={`${tab.includes('/directory') ? styles.selected : ''} ${
            styles.tab
          }`}
        >
          <button
            className={styles.button}
            onClick={navigate('/schedule/directory/people_center')}
          >
            DIRECTORY
          </button>
        </div>
        <div
          className={`${tab.includes('/admin') ? styles.selected : ''} ${
            styles.tab
          }`}
        >
          <button
            className={styles.button}
            onClick={navigate('/schedule/admin/users')}
          >
            ADMIN
          </button>
        </div>
        <div
          className={`${tab.includes('/search') ? styles.selected : ''} ${
            styles.tab
          }`}
        >
          <button
            className={styles.button}
            onClick={navigate('/schedule/search')}
          >
            SEARCH
          </button>
        </div>
      </div>
      <div className={styles.right}>
        {currentUserPresenter.user ? (
          <MenuNav userInitials={currentUserPresenter.initials()}></MenuNav>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
