// @flow
import React from 'react';
import styles from '../list-page/ListPage.module.scss';
import '../people-center/tables/Report.scss';
import 'moment-duration-format';
import { Table } from 'antd';
import availableIcon from '../../assets/available-icon.svg';
import checkIcon from '../../assets/scheduled-check-icon.svg';
import calloutIcon from '../../assets/callout-icon.svg';
import cancelIcon from '../../assets/cancel-icon.svg';

export default function MonthlyTable({
  reports = {
    rows: [],
  },
}: {
  reports?: any,
}) {
  const renderReportLogic = (reports) => {
    let rows = reports.rows;
    if (!rows || rows?.length === 0) {
      return '';
    }

    rows.sort(function (a, b) {
      if (a.Coach >= b.Coach) {
        return 1;
      }

      return -1;
    });

    let table_data = {};
    let table_columns = [
      {
        title: 'Coach',
        dataIndex: 'Coach',
        key: 'Coach',
        fixed: 'left',
        width: 180,
      },
    ];

    for (let i = 0; i < rows.length; i++) {
      let row = rows[i];
      let name = row['Coach'];
      let date = row['ServiceDate'];
      let status = row['Status'];

      let statusIcon = (
        <img src={availableIcon} alt="dashes" width="18" height="18" />
      );

      switch (status) {
        case 'called_out':
          statusIcon = (
            <img src={calloutIcon} alt="dashes" width="18" height="18" />
          );
          break;
        case 'scheduled':
          statusIcon = (
            <img src={checkIcon} alt="check" width="18" height="18" />
          );
          break;
        case 'unscheduled':
          statusIcon = (
            <img src={availableIcon} alt="available" width="18" height="18" />
          );
          break;
        case 'scheduled_off':
          statusIcon = (
            <img src={cancelIcon} alt="off" width="18" height="18" />
          );
          break;
        default:
          statusIcon = (
            <img src={availableIcon} alt="dashes" width="18" height="18" />
          );
      }

      if (table_data[name]) {
        table_data[name][date] = statusIcon;
      } else {
        table_data[name] = {
          key: name,
          Coach: name,
        };

        table_data[name][date] = statusIcon;
      }

      if (!table_columns[date]) {
        table_columns.push({
          title: date,
          dataIndex: date,
          width: 60,
          key: date,
        });
      }
    }

    return (
      <Table
        dataSource={Object.values(table_data)}
        columns={Object.values(table_columns)}
        scroll={{ x: '100vw' }}
      />
    );
  };

  return (
    <div className={styles.main}>
      <div className={styles.reportHeaderLegend}>
        <div className={styles.centerLegend}>
          <div>
            <img src={availableIcon} alt="dashes" width="18" height="18" />
            <p>Available</p>
          </div>
          <div>
            <img src={checkIcon} alt="checkmark" width="18" height="18" />
            <p>Scheduled</p>
          </div>
          <div>
            <img src={calloutIcon} alt="exclaimation" width="17" height="17" />
            <p>Called Out</p>
          </div>
          <div>
            <img src={cancelIcon} alt="cancel" width="18" height="18" />
            <p>Scheduled Off</p>
          </div>
        </div>
      </div>
      <div className="report-container">
        {reports.length === 0 ? '' : renderReportLogic(reports)}
      </div>
    </div>
  );
}
