// @flow
import { createSlice } from '@reduxjs/toolkit';
import { defaultReducers } from './defaultReducers';
import { DefaultRequests } from './defaultRequests';

export interface Location {
  id: number;
  name: string;
  phoneNumber: string;
  address: string;
  addressTwo: string;
  city: string;
  state: string;
  zip: string;
  googleMapUrl: string;
}

export interface LocationState {
  loading: boolean;
  error: string;
  list: Location[];
  byId: {};
}

const initialState: LocationState = {
  loading: false,
  error: '',
  list: [],
  byId: {},
};

const location = createSlice({
  name: 'locations',
  initialState,
  reducers: defaultReducers,
});

export const {
  getStart,
  getSuccess,
  getFailure,
  add,
  normalizeList,
} = location.actions;

export const locationReducer = location.reducer;
export const locationRequests = new DefaultRequests(
  location.actions,
  'aerostar/locations'
);
