// @flow
import React from 'react';
import styles from '../../directory/list-page/ListPage.module.scss';
import store from '../../general/store';
import { useSelector, useDispatch } from 'react-redux';
import type { State } from '../../general/types';
import UserPresenter from '../../presenters/UserPresenter';
import { Table, Modal, Button, Spin } from 'antd';
import { filterList } from '../../slices/searchSlice';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import deleteIcon from '../../assets/remove.svg';
import SyncOutlined from '@ant-design/icons/lib/icons/SyncOutlined';
import type { ScheduleType } from '../../slices/scheduleTypeSlice';
import { scheduleTypeActions } from '../../slices/scheduleTypeSlice';
import { getSortProps } from '../../utilities/getSortProps';
import AddScheduleType from './add-scheduleTypes/AddScheduleTypes';
const { confirm } = Modal;

export default function ScheduleTypes({
  programID,
  programStatus,
}: {
  programID?: number,
  programStatus?: boolean,
}) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    store.dispatch(scheduleTypeActions.fetch());
  }, []);

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const search = useSelector((state: State) => {
    return state.search;
  });

  const scheduleTypes = useSelector((state: State) => {
    return state.schedule_types;
  });

  let types = scheduleTypes.list.map((id) => {
    return scheduleTypes.byId[id];
  });

  types = types.filter((type) => type.program === programID);

  let filteredList = filterList(
    search.byId['scheduleTypes'],
    types,
    false,
    false,
    true
  );

  filteredList = filteredList.map((type) => new UserPresenter(type));

  const openModalDeactivate = function (type: ScheduleType) {
    confirm({
      title: `Are you sure you want to delete ${type.type}?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okType: 'danger',
      onOk() {
        dispatch(scheduleTypeActions.delete(type));
      },
      onCancel() {},
    });
  };

  const openModalActivate = function (type: any) {
    confirm({
      title: `Do you want to recover ${type.type}?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        const patchingEventTypes = [];
        for (let i = 0; i < type.eventTypesAssigned.length; i++) {
          const patchingEventAction = Object.assign(
            {},
            type.eventTypesAssigned[i],
            { key: type.eventTypesAssigned[i].eventType.toString() }
          );
          patchingEventTypes.push(patchingEventAction);
        }
        dispatch(
          scheduleTypeActions.upsert(
            Object.assign({}, type, {
              event_types: patchingEventTypes,
              deleted: null,
            })
          )
        );
      },
      onCancel() {},
    });
  };

  const getInitialEvents = (scheduleType) => {
    let results = [];
    let initialSortedEvents = [...scheduleType.eventTypesAssigned];
    initialSortedEvents.sort((a, b) => {
      return parseInt(a.sortOrder) - parseInt(b.sortOrder);
    });
    for (let i = 0; i < initialSortedEvents.length; i++) {
      const patchData = Object.assign({}, initialSortedEvents[i], {
        // key --> eventType id, not assigned eventType id
        key: initialSortedEvents[i].eventType.toString(),
        index: parseInt(initialSortedEvents[i].sortOrder),
        // eventAPIupdateID --> actual assigned eventType id for patching (if key does not exist, then request is sending a post request)
        eventAPIupdateID: initialSortedEvents[i].id,
      });
      results.push(patchData);
    }
    return results;
  };

  const columns = [
    {
      title: 'TYPE NAME',
      dataIndex: 'type',
      key: 'type',
      ...getSortProps('type'),
    },
    {
      title: 'START TIME',
      dataIndex: 'startTime',
      key: 'startTime',
      defaultSortOrder: 'ascend',
      ...getSortProps('startTime'),
    },
    {
      title: 'END TIME',
      dataIndex: 'endTime',
      key: 'endTime',
      ...getSortProps('endTime'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (id) => {
        return (
          <div className={styles.scheduleTypeOptions}>
            <AddScheduleType
              scheduleType={scheduleTypes.byId[id]}
              programID={programID}
              initialEvents={getInitialEvents(scheduleTypes.byId[id])}
              programStatus={programStatus}
            />
            <Button
              type="link"
              onClick={() => {
                if (scheduleTypes.byId[id].deleted) {
                  openModalActivate(scheduleTypes.byId[id]);
                } else {
                  openModalDeactivate(scheduleTypes.byId[id]);
                }
              }}
              disabled={programStatus ? true : false}
            >
              {scheduleTypes.byId[id].deleted ? (
                <SyncOutlined />
              ) : (
                <img src={deleteIcon} alt="pencil" width="25" height="25" />
              )}
            </Button>
          </div>
        );
      },
    },
  ];

  if (!currentUserPresenter.isAdminUserType()) {
    // Don't show User the edit/delete options
    columns.pop();
  }

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        {!scheduleTypes.loading ? (
          <Table
            className="schedule-type-css"
            dataSource={filteredList}
            columns={columns}
            pagination={false}
          />
        ) : (
          <div className={styles.spinner}>
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
}
