import React from 'react';
import './MenuButton.scss';

export default function MenuButton({
  userInitials,
  handleMouseDown,
}: {
  userInitials: string,
  handleMouseDown: () => void,
}) {
  return (
    <div className="menu" onMouseDown={handleMouseDown}>
      {userInitials}
    </div>
  );
}
