// @flow
import { createSlice } from '@reduxjs/toolkit';
import { defaultReducers } from './defaultReducers';
import axios from 'axios';

export interface ProgramTransfer {
  targetKeys: number[];
  selectedKeys: number[];
  enableProgram: boolean;
}

export interface ProgramTransferState {
  loading: boolean;
  error: string;
  transfer: ProgramTransfer;
}

const initialState: ProgramTransferState = {
  loading: false,
  error: '',
  transfer: { targetKeys: [], selectedKeys: [], enableProgram: true },
};

const programTransfer = createSlice({
  name: 'programTransfer',
  initialState,
  reducers: {
    getStart: defaultReducers.getStart,
    getSuccess: defaultReducers.getSuccess,
    getFailure: defaultReducers.getFailure,
  },
});

export const { getStart, getSuccess, getFailure } = programTransfer.actions;

export const programTransferReducer = programTransfer.reducer;

export const updateProgramTransfer = (transferData: any) => async (
  dispatch: any
) => {
  try {
    dispatch(getStart());
    dispatch(getSuccess({ transfer: transferData }));
  } catch (err) {
    dispatch(getFailure(err));
  }
};

export const assignProgramToPeople = (
  peopleList: any,
  programID: any
) => async (dispatch: any) => {
  try {
    await axios.post(
      `${
        process.env.REACT_APP_SERVER_URL || ''
      }/aerostar/program/${programID}/assign_users/`,
      peopleList
    );
  } catch (err) {
    dispatch(getFailure(err));
  }
};
