// @flow
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { defaultReducers } from './defaultReducers';
import { DefaultRequests } from './defaultRequests';

export interface Skill {
  id: number;
  name: string;
  icon: string;
  skill: string;
}

export interface SkillState {
  loading: boolean;
  error: string;
  list: Skill[];
  byId: {};
}

const initialState: SkillState = {
  loading: false,
  error: '',
  list: [],
  byId: {},
};

const skill = createSlice({
  name: 'skills',
  initialState,
  reducers: {
    getStart: defaultReducers.getStart,
    getSuccess: defaultReducers.getSuccess,
    getFailure: defaultReducers.getFailure,
    normalizeList: defaultReducers.normalizeList,
    add: defaultReducers.add,
    update: defaultReducers.update,
  },
});

export const { getStart, getSuccess, getFailure } = skill.actions;

export const skillReducer = skill.reducer;

export const fetchSkills = () => async (dispatch: any) => {
  try {
    dispatch(getStart());
    const skills = await axios.get(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/skills/`
    );
    dispatch(getSuccess({ list: skills.data }));
  } catch (err) {
    dispatch(getFailure(err));
  }
};

export const skillActions = new DefaultRequests(
  skill.actions,
  `aerostar/skills`
);
