// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.scss';
import { Provider } from 'react-redux';
import store from './general/store';
import { fetchUser } from './slices/authSlice';

import { Router, Switch, Route } from 'react-router-dom';
import LoginApp from './login/LoginApp';
import Schedule from './schedule/Schedule';
import CoachDashboardApp from './coach-dashboard/CoachDashboardApp';
import { history } from './general/myHistory';
import * as serviceWorker from './serviceWorker';
import PrivateRoute from './general/PrivateRoute';
import initializeAxios from './general/initizlizeAxios';
import ForgotPassword from './login/forgot_password/ForgotPassword';
import LoginForm from './login/login-form/LoginForm';
import ResetPassword from './login/reset-password/ResetPassword';
import { setUrl } from './slices/generalSlice';
import { UserTypesEnum } from './utilities/userTypesEnum';
import { Redirect } from 'react-router';
// import * as Sentry from '@sentry/react';

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
// });

const root = document.getElementById('root');
initializeAxios();
store.dispatch(fetchUser());

store.dispatch(setUrl(window.location.href));
history.listen((location) => {
  store.dispatch(setUrl(location.pathname));
});
const userType = localStorage.getItem('userType');

if (root !== null) {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route path="/login">
            <LoginApp>
              <LoginForm />
            </LoginApp>
          </Route>
          <Route path="/forgot_password">
            <LoginApp>
              <ForgotPassword />
            </LoginApp>
          </Route>
          <Route path="/reset_password/:uid/:token">
            <LoginApp>
              <ResetPassword />
            </LoginApp>
          </Route>
          <PrivateRoute path="/schedule">
            <Schedule />
          </PrivateRoute>
          <PrivateRoute path="/coach-dashboard">
            <CoachDashboardApp />
          </PrivateRoute>
          <Route>
            {userType === UserTypesEnum.USER ||
            userType === UserTypesEnum.ADMIN ? (
              <Redirect to="/schedule/scheduler" />
            ) : userType === UserTypesEnum.TRANSPORTER ||
              userType === UserTypesEnum.COACH ? (
              <Redirect to="/coach-dashboard/schedule" />
            ) : (
              <LoginApp>
                <LoginForm />
              </LoginApp>
            )}
          </Route>
        </Switch>
      </Router>
    </Provider>,
    root
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
