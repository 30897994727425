// @flow
import React from 'react';
import './CoachList.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { State } from '../../general/types';
import CoachCard from '../coach-card/CoachCard';
import UserSearch from '../../user-search/UserSearch';
import UserSearchInput from '../../user-search-input/UserSearchInput';
import { Button, Select, Spin } from 'antd';
import { CoachStatusTypesEnum } from '../../utilities/coachStatusTypesEnum';
import type { ScheduleState } from '../../slices/scheduleSlice';
import { setCoachStatus } from '../../slices/coachesSlice';
import type { CoachState, ScheduleCoach } from '../../slices/coachesSlice';
import { getScheduleTypeClass } from '../../utilities/getScheduleTypeStyles';
import { fetchSchedule } from '../../slices/scheduleSlice';
import chevronDown from '../../assets/chevron-down.svg';
const { Option } = Select;

export default function CoachList({ isMaster }: { isMaster?: boolean }) {
  const dispatch = useDispatch();

  const schedule: ScheduleState = useSelector((state: State) => {
    return state.schedule;
  });

  const coaches: CoachState = useSelector((state: State) => {
    return state.coaches;
  });

  const filteredList = useSelector((state: State) => {
    let filteredList: ScheduleCoach[] = state.coaches.list.map(
      (coachId) => state.coaches.byId[coachId]
    );

    if (
      state.search.byId['coaches'] &&
      state.search.byId['coaches'].searchTerm
    ) {
      filteredList = filteredList.filter((coach) => {
        return (coach.user.firstName + ' ' + coach.user.lastName)
          .toLowerCase()
          .includes(state.search.byId['coaches'].searchTerm.toLowerCase());
      });
    }
    if (state.coaches.searchStatus) {
      filteredList = filteredList.filter((coach) => {
        return coach.status === state.coaches.searchStatus;
      });
    }
    return filteredList;
  }, shallowEqual);

  const handleChange = (value) => {
    dispatch(setCoachStatus(value));
  };

  return (
    <div style={getScheduleTypeClass(schedule)} className={'coach-list'}>
      <div className="coach-title">
        <label htmlFor="coach_selection">COACHES</label>
        <div>
          <Select
            id="coach_selection"
            defaultValue=""
            className="coach-dropdown"
            bordered={false}
            value={coaches.searchStatus}
            onChange={handleChange}
            suffixIcon={
              <img src={chevronDown} alt="chevron" width="30" height="30" />
            }
          >
            <Option label="all coaches" value="">
              All Coaches
            </Option>
            <Option
              label={CoachStatusTypesEnum.SCHEDULED}
              value={CoachStatusTypesEnum.SCHEDULED}
            >
              Scheduled
            </Option>
            <Option
              label={CoachStatusTypesEnum.SCHEDULED_OFF}
              value={CoachStatusTypesEnum.SCHEDULED_OFF}
            >
              Scheduled Off
            </Option>
            <Option
              label={CoachStatusTypesEnum.UNSCHEDULED}
              value={CoachStatusTypesEnum.UNSCHEDULED}
            >
              Unscheduled
            </Option>
            <Option
              label={CoachStatusTypesEnum.CALLED_OUT}
              value={CoachStatusTypesEnum.CALLED_OUT}
            >
              Called Out
            </Option>
          </Select>
        </div>
        <div className="top">
          <UserSearchInput id="coaches" />
          <UserSearch
            id="coaches"
            className="dark"
            partial={filteredList.length}
            total={coaches.list.length}
          />
        </div>
      </div>
      {schedule.isOutdated && (
        <div className="outdated">
          <div>Your schedule is outdated please refresh.</div>
          <Button
            onClick={() => {
              dispatch(fetchSchedule());
            }}
          >
            Refresh
          </Button>
        </div>
      )}
      <div className="list">
        {schedule && !schedule.loading ? (
          filteredList.map((coach) => {
            return (
              <CoachCard
                key={`coach-${coach.id}`}
                coach={coach.user}
                coachSchedule={coach}
                isMaster={isMaster}
              />
            );
          })
        ) : (
          <div className="spin-holder">
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
}
