import React from 'react';
import './SurveyList.scss';
import { Select, Radio, Button } from 'antd';
import { useSelector } from 'react-redux';
import UserPresenter from '../../presenters/UserPresenter';
import Axios from 'axios';

const { Option } = Select;

const SurveyList = () => {
  const currentUserPresenter = useSelector((state: State) => {
    return new UserPresenter(state.auth.currentUser);
  });

  const scheduledParticipants = useSelector((state: State) => {
    return state.participants;
  });

  const [currentValue, setCurrentValue] = React.useState('');
  const [surveyName, setSurveyName] = React.useState(false);
  const [participantName, setParticipantName] = React.useState(false);
  const [bothName, setBothName] = React.useState(false);
  const [surveys, setSurveys] = React.useState([]);

  const getSurvey = async () => {
    const data = await Axios.get(
      `${process.env.REACT_APP_SERVER_URL || ''}/forms`
    );
    setSurveys(data.data);
  };
  React.useEffect(() => {
    getSurvey();
  }, []);

  const surveyListCoachOnly = surveys.map((surveyId) => {
    if (surveyId.surveyType === 'COACHES') {
      return (
        <Option
          size="large"
          key={surveyId.id}
          label={surveyId.title}
          value={surveyId.title}
        >
          {surveyId.title}
        </Option>
      );
    } else {
      return 'loading';
    }
  });

  const surveyListParticipantsOnly = surveys.map((surveyId) => {
    if (surveyId.surveyType === 'PARTICIPANTS') {
      return (
        <Option
          size="large"
          key={surveyId.id}
          label={surveyId.title}
          value={surveyId.title}
        >
          {surveyId.title}
        </Option>
      );
    } else {
      return 'loading';
    }
  });

  const surveyListBoth = surveys.map((surveyId) => {
    if (surveyId.surveyType === 'BOTH') {
      return (
        <Option
          size="large"
          key={surveyId.id}
          label={surveyId.title}
          value={surveyId.title}
        >
          {surveyId.title}
        </Option>
      );
    } else {
      return 'loading';
    }
  });

  const participantOnly = scheduledParticipants.list.map((participantId) => {
    let participant = scheduledParticipants.byId[participantId];
    return (
      <Option
        size="large"
        key={participant.id}
        label={participant.firstName + ' ' + participant.lastName}
        value={participant.firstName + ' ' + participant.lastName}
        onChange={() => {
          setParticipantName(
            participant.firstName + ' ' + participant.lastName
          );
        }}
      >
        {participant.firstName + ' ' + participant.lastName}
      </Option>
    );
  });

  const BothCoachParticipant = (
    <>
      <Option
        size="large"
        key="Myself"
        label="Myself"
        value=" "
        onChange={() => {
          setBothName(' ');
        }}
      >
        Myself
      </Option>
      {scheduledParticipants.list.map((participantId) => {
        let participant = scheduledParticipants.byId[participantId];
        return (
          <Option
            size="large"
            key={participant.user}
            label={participant.firstName + ' ' + participant.lastName}
            value={participant.firstName + ' ' + participant.lastName}
            onChange={() => {
              setBothName(participant.firstName + ' ' + participant.lastName);
            }}
          >
            {participant.firstName + ' ' + participant.lastName}
          </Option>
        );
      })}
    </>
  );

  const handleOptionChange = (value) => {
    setSurveyName(value);
  };
  const handleParticipantChange = (value) => {
    setParticipantName(value);
  };
  const handleBothChange = (value) => {
    setBothName(value);
  };

  function checkRadio(currentValue) {
    if (currentValue === '') {
      return (
        <div className="survey-list-wrap">
          <p className="survey-list-wrap">Please select </p>
        </div>
      );
    } else if (currentValue === 'Coach Only') {
      return (
        <div>
          <Select
            size="large"
            id="survey_selection_coach"
            className="selection-wrap"
            onChange={handleOptionChange}
            value={surveyName ? surveyName : 'Please select a survey'}
          >
            {surveyListCoachOnly}
          </Select>
        </div>
      );
    } else if (currentValue === 'Participant only') {
      return (
        <div>
          <Select
            size="large"
            id="survey_selection_participant"
            className="selection-wrap"
            onChange={handleOptionChange}
            value={surveyName ? surveyName : 'Please select a survey'}
          >
            {surveyListParticipantsOnly}
          </Select>
          <Select
            size="large"
            id="participant_only_selection"
            className="selection-wrap"
            onChange={handleParticipantChange}
            value={participantName ? participantName : 'Please select  name'}
          >
            {participantOnly}
          </Select>
        </div>
      );
    } else if (currentValue === 'Both') {
      return (
        <div>
          <Select
            size="large"
            id="survey_selection_both"
            className="selection-wrap"
            onChange={handleOptionChange}
            value={surveyName ? surveyName : 'Please select a survey'}
          >
            {surveyListBoth}
          </Select>
          <Select
            size="large"
            id="participant_selection_both"
            className="selection-wrap"
            onChange={handleBothChange}
            value={bothName ? bothName : 'Please select name'}
          >
            {BothCoachParticipant}
          </Select>
        </div>
      );
    }
  }

  function checkButton(surveyName) {
    if (surveyName && currentValue === 'Coach Only') {
      return (
        <div className="survey-list-wrap">
          {surveys.map((surveyId) => {
            if (
              surveyId.surveyType === 'COACHES' &&
              surveyId.title === surveyName
            ) {
              return (
                <a
                  href={surveyId.url
                    .replace(/coach/i, currentUserPresenter.firstLastNames())
                    .replace(/participant/i, '')}
                  target="blank"
                  className="open-survey-button"
                  key={surveyId.id}
                >
                  <Button
                    name="open survey button"
                    className="open-survey-button"
                    key={surveyId.id}
                  >
                    Open Survey {surveyId.title}
                  </Button>
                </a>
              );
            } else {
              return '';
            }
          })}
        </div>
      );
    } else if (
      surveyName &&
      currentValue === 'Participant only' &&
      participantName
    ) {
      return (
        <div className="survey-list-wrap">
          {surveys.map((surveyId) => {
            if (
              surveyId.surveyType === 'PARTICIPANTS' &&
              surveyId.title === surveyName
            ) {
              return (
                <a
                  href={surveyId.url
                    .replace(/coach/i, currentUserPresenter.firstLastNames())
                    .replace(/participant/i, participantName)}
                  target="blank"
                  className="open-survey-button"
                  key={surveyId.id}
                >
                  <Button
                    name="open survey button"
                    className="open-survey-button"
                  >
                    Open Survey {surveyId.title}
                  </Button>
                </a>
              );
            } else {
              return '';
            }
          })}
        </div>
      );
    } else if (surveyName && currentValue === 'Both' && bothName) {
      return (
        <div className="survey-list-wrap">
          {surveys.map((surveyId) => {
            if (
              surveyId.surveyType === 'BOTH' &&
              surveyId.title === surveyName
            ) {
              return (
                <a
                  href={surveyId.url
                    .replace(/coach/i, currentUserPresenter.firstLastNames())
                    .replace(/participant/i, bothName)}
                  target="blank"
                  className="open-survey-button"
                  key={surveyId.id}
                >
                  <Button
                    name="open survey button"
                    className="open-survey-button"
                  >
                    Open Survey {surveyId.title}
                  </Button>
                </a>
              );
            } else {
              return '';
            }
          })}
        </div>
      );
    }
  }

  return (
    <div className="survey-start">
      <div>
        <Radio.Group
          style={{ marginTop: '20px' }}
          onChange={(e) => {
            setCurrentValue(e.target.value);
            setSurveyName(false);
            setParticipantName(false);
            setBothName(false);
          }}
          defaultValue={''}
        >
          <Radio value={'Coach Only'} className="radio-size">
            Coach Only
          </Radio>
          <Radio value={'Participant only'} className="radio-size">
            Participant only
          </Radio>
          <Radio value={'Both'} className="radio-size">
            Both
          </Radio>
        </Radio.Group>
        {checkRadio(currentValue)}
        {checkButton(surveyName)}
      </div>
    </div>
  );
};

export default SurveyList;
