import axios from 'axios';

export class DefaultRequests {
  constructor(actions, endpoint) {
    this.actions = actions;
    this.endPoint = endpoint;
  }

  fetch = () => async (dispatch: any) => {
    try {
      dispatch(this.actions.getStart());
      const items = await axios.get(
        `${process.env.REACT_APP_SERVER_URL || ''}/${this.endPoint}/`
      );
      if (this.endPoint === 'aerostar/user') {
        const adminUsers = [];
        for (let i = 0; i < items.data.length; i++) {
          if (items.data[i].userType === 4 || items.data[i].userType === 3) {
            adminUsers.push(items.data[i]);
          }
        }
        dispatch(this.actions.normalizeList(adminUsers));
        return true;
      }
      dispatch(this.actions.normalizeList(items.data));
      return true;
    } catch (err) {
      dispatch(this.actions.getFailure(err));
      return false;
    }
  };

  //TODO: If/when more than two things, create more DRY and SOLID Strategy for restActionWithItem methods per request.
  post = (item) => async (dispatch: any) => {
    try {
      dispatch(this.actions.getStart());
      item = await axios.post(
        `${process.env.REACT_APP_SERVER_URL || ''}/${this.endPoint}/`,
        item
      );
      dispatch(this.actions.add(item.data));
      return item.data;
    } catch (err) {
      dispatch(this.actions.getFailure(err));
      return false;
    }
  };

  patch = (item) => async (dispatch: any) => {
    try {
      dispatch(this.actions.getStart());
      item = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL || ''}/${this.endPoint}/${
          item.id
        }/`,
        item
      );
      dispatch(this.actions.update(item.data));
      return item.data;
    } catch (err) {
      dispatch(this.actions.getFailure(err));
      return null;
    }
  };

  upsert = (item) => async (dispatch: any) => {
    if (item.id) {
      return dispatch(this.patch(item));
    } else {
      return dispatch(this.post(item));
    }
  };

  delete = (item) => async (dispatch: any) => {
    try {
      dispatch(this.actions.getStart());
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URL || ''}/${this.endPoint}/${item.id}/`
      );
      dispatch(this.actions.delete(item));
      const items = await axios.get(
        `${process.env.REACT_APP_SERVER_URL || ''}/${this.endPoint}/`
      );
      // Need to filter for only Admin and User types only on user dashboard from `aerostar/user` endpoint
      if (this.endPoint === 'aerostar/user') {
        const adminUsers = [];
        for (let i = 0; i < items.data.length; i++) {
          if (items.data[i].userType === 4 || items.data[i].userType === 3) {
            adminUsers.push(items.data[i]);
          }
        }
        dispatch(this.actions.normalizeList(adminUsers));
      } else {
        dispatch(this.actions.normalizeList(items.data));
      }
      return true;
    } catch (err) {
      dispatch(this.actions.getFailure(err));
      return false;
    }
  };
}
