// @flow
import React from 'react';
import axios from 'axios';
import './CoachCard.scss';
import { useHistory } from 'react-router';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import ParticipantCard from '../participant-card/ParticipantCard';
import CoachCardBack from '../coach-card-back/CoachCardBack';
import { Button, Select } from 'antd';
import { useDispatch } from 'react-redux';
import ArrowRightOutlined from '@ant-design/icons/lib/icons/ArrowRightOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import chevronDown from '../../assets/chevron-down.svg';
import CalendarOutlined from '@ant-design/icons/lib/icons/CalendarOutlined';
import type { Coach, ScheduleCoach } from '../../slices/coachesSlice';
import { getScheduleByDate } from '../../slices/scheduleSlice';
import { CoachStatusTypesEnum } from '../../utilities/coachStatusTypesEnum';
import AutoComplete from '../autocomplete/AutoComplete';
import { useSelector } from 'react-redux';
import type { State } from '../../general/types';
import TransportDropdown from '../../transport/TransportDropdown';
import { sortByLastNameFirstNameHelper } from '../../utilities/sortByLastNameFirstName';
import {
  postParticipant,
  deleteParticipant,
} from '../../slices/participantSlice';
const { Option } = Select;

export default function CoachCard({
  coach,
  coachSchedule,
  isMaster,
}: {
  coach: Coach,
  coachSchedule: ScheduleCoach,
  isMaster: boolean,
}) {
  const [showBack, setShowBack] = React.useState(false);
  const [partnerData, setPartnerData] = React.useState([]);

  const history = useHistory();

  const getPartners = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/user_groups/${
        coach.id
      }/get_partners/`
    );

    let resData = res['data'];
    let partners = [];

    for (let i = 0; i < resData.length; i++) {
      const item = [
        resData[i]['user_FirstName'] + ' ' + resData[i]['user_LastName'],
        resData[i]['user_PhoneNumber'],
        resData[i]['user_Email'],
        resData[i]['user_Id'],
      ];

      if (item[3] !== coach.id) {
        partners.push(item);
      }
    }

    return partners;
  };

  const dispatch = useDispatch();

  const locationState = useSelector((state: State) => {
    return state.locations;
  });

  const locations = locationState.list.map((id) => {
    return locationState.byId[id];
  });

  const participantsById = useSelector((state: State) => {
    return state.participants.byId;
  });

  const schedule = useSelector((state: State) => {
    return state.schedule;
  });

  let toggleSide = () => {
    if (!showBack) {
      getPartners()
        .then((data) => {
          setPartnerData(data);
        })
        .catch((error) => {
          console.log('Error: Failed to get partners');
        });
    }

    setShowBack(!showBack);
  };

  const copyScheduleType = async (value) => {
    const targetSchedule = await getScheduleByDate(
      schedule.date,
      schedule.program,
      value
    );

    let copyParticipantsId = [];
    for (let i = 0; i < targetSchedule.data.scheduledCoaches.length; i++) {
      if (targetSchedule.data.scheduledCoaches[i].user.id === coach.id) {
        copyParticipantsId =
          targetSchedule.data.scheduledCoaches[i].scheduledParticipants;
        break;
      }
    }

    let copyParticipantsList = [];

    for (let i = 0; i < copyParticipantsId.length; i++) {
      for (
        let j = 0;
        j < targetSchedule.data.scheduledParticipants.length;
        j++
      ) {
        if (
          copyParticipantsId[i] ===
          targetSchedule.data.scheduledParticipants[j].id
        ) {
          copyParticipantsList.push(
            targetSchedule.data.scheduledParticipants[j]
          );
          break;
        }
      }
    }

    let actualCopyParticipants = [];
    const scheduledParticipantsList = (Object.values(participantsById): any);
    if (copyParticipantsId.length > 0) {
      for (let i = 0; i < copyParticipantsList.length; i++) {
        for (let j = 0; j < scheduledParticipantsList.length; j++) {
          if (
            scheduledParticipantsList[j].user === copyParticipantsList[i].user
          ) {
            actualCopyParticipants.push(scheduledParticipantsList[j].id);
            break;
          }
        }
      }
    } else {
      // No participants to mirror from selected scheduleType onto current scheduleType
    }

    // Delete participants that are not on the copying schedule type BUT leave them if they are
    for (let i = 0; i < coachSchedule.scheduledParticipants.length; i++) {
      if (
        actualCopyParticipants.includes(coachSchedule.scheduledParticipants[i])
      ) {
        // Need this to update the new participants that will be copied over after updating the deletes
        actualCopyParticipants.splice(
          actualCopyParticipants.indexOf(
            coachSchedule.scheduledParticipants[i]
          ),
          1
        );
      } else {
        dispatch(
          deleteParticipant(
            coachSchedule.scheduledParticipants[i],
            coachSchedule.id
          )
        );
      }
    }
    // Add the leftover participants that still needs to be copied over because they are not already scheduled
    for (let i = 0; i < actualCopyParticipants.length; i++) {
      dispatch(postParticipant(actualCopyParticipants[i], coachSchedule.id));
    }
  };

  return (
    <div className="coach-card">
      {!showBack &&
        coachSchedule &&
        coachSchedule.status !== CoachStatusTypesEnum.SCHEDULED && (
          <div className="status-view">
            <div className={`status  ${coachSchedule && coachSchedule.status}`}>
              {coachSchedule.status.toUpperCase().replace('_', ' ')}
            </div>
          </div>
        )}
      <div className={!showBack ? 'coach-card-title' : 'coach-card-title-back'}>
        <div>COACH</div>
        <div>
          {!showBack ? (
            <Button
              type="link"
              onClick={toggleSide}
              icon={<ArrowRightOutlined />}
            />
          ) : (
            <Button type="link" onClick={toggleSide} icon={<CloseOutlined />} />
          )}
        </div>
      </div>
      <div className={!showBack ? 'header' : 'header-back'}>
        <label htmlFor={coach.id}>
          {coach.firstName} {coach.lastName}
        </label>
        <CalendarOutlined
          className="calendar-icon"
          width="2em"
          aria-label="calendar-icon"
          onClick={() => history.push(`/schedule/coach-calendar/${coach.id}/`)}
        />
      </div>
      {!showBack ? (
        <div className="body">
          <div className="copy-dropdown">
            <Select
              id={coach.id}
              style={{ width: 217 }}
              onChange={copyScheduleType}
              placeholder={'COPY FROM'}
              suffixIcon={
                <img src={chevronDown} alt="chevron" width="30" height="30" />
              }
            >
              {schedule.types.map((type) => {
                return (
                  <Option label={type.id} key={type.id} value={type.id}>
                    {type.type.toUpperCase()}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="sub-headers">
            <div className="sub-header-participant">PARTICIPANTS</div>
          </div>
          <div>
            <div className="background-participants">
              {[...Array(3).keys()].map((num, index) => (
                <div key={`participant-${num}`} className="card-holder drop">
                  <div className="participant-outline">
                    Drop or Add Participant
                  </div>
                </div>
              ))}
            </div>
            <Droppable droppableId={`coachDroppable_${coachSchedule.id}`}>
              {(provided, snapshot) => (
                <div
                  className="participants"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {coachSchedule &&
                  coachSchedule.scheduledParticipants &&
                  coachSchedule.scheduledParticipants.length
                    ? coachSchedule.scheduledParticipants
                        .slice()
                        .sort(sortByLastNameFirstNameHelper(participantsById))
                        .map((participantId, index) => {
                          return (
                            <Draggable
                              key={participantId}
                              draggableId={
                                'participant-' + participantId.toString()
                              }
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="card-holder drop"
                                >
                                  <ParticipantCard
                                    key={`participant-${participantId}`}
                                    includeMenu={true}
                                    scheduleCoach={coachSchedule}
                                    participantId={participantId}
                                    isMaster={isMaster}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })
                    : ''}

                  <div className="placeholder">{provided.placeholder}</div>
                </div>
              )}
            </Droppable>
          </div>
          <div>
            <div className="sub-headers">
              <div className="sub-header">PICKUP LOCATION</div>
            </div>
            <div className="dropdowns">
              <div>
                <AutoComplete
                  list={locations}
                  current={coachSchedule.locationStart}
                  updateKey="location_start_id"
                  scheduleCoach={coachSchedule}
                  isMaster={isMaster}
                />
              </div>
            </div>
            <div className="sub-headers">
              <div className="sub-header">DESTINATION</div>
            </div>
            <div className="dropdowns">
              <div className="dest-left">
                <AutoComplete
                  list={locations}
                  current={coachSchedule.locationEnd}
                  updateKey="location_end_id"
                  scheduleCoach={coachSchedule}
                  isMaster={isMaster}
                />
              </div>
            </div>
          </div>

          <div>
            <label
              className="sub-header-transportation"
              htmlFor={coachSchedule.id}
            >
              TRANSPORTATION
            </label>
            <div className="transport-text">
              <TransportDropdown
                coachId={coachSchedule.id}
                defaultTransportationType={coachSchedule.transportationType}
                isMaster={isMaster}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="body back">
          <CoachCardBack
            coachId={coachSchedule.id}
            coachSchedule={coachSchedule}
            toggleSide={toggleSide}
            partnerData={partnerData}
          />
        </div>
      )}
    </div>
  );
}
