// @flow
import React from 'react';
import '../user-search/UserSearch.scss';
import styles from '../directory/search-component/SearchComponent.module.scss';
import searchIconBlue from '../assets/streamline-icon.svg';
import { Button, Input } from 'antd';

export default function SearchComponent({
  onSearchTermChange,
}: {
  onSearchTermChange: void,
}) {
  let setSearchValue = (searchTerm) => {
    onSearchTermChange(searchTerm);
  };

  return (
    <div className={styles.search}>
      <div className="user-search">
        <div>
          <Input
            aria-label="search"
            autoFocus
            placeholder="Search"
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className="">
          <Button
            type="link"
            icon={
              <img
                src={searchIconBlue}
                alt="magnifying_glass"
                width="20"
                height="20"
              />
            }
          />
        </div>
      </div>
    </div>
  );
}
