// @flow
import React from 'react';
import './ScheduleHeader.scss';
import { useDispatch, useSelector } from 'react-redux';
import store from '../../general/store';
import type { State } from '../../general/types';
import { fetchSchedule, setScheduleType } from '../../slices/scheduleSlice';
import { DatePicker, Select } from 'antd';
import UserPresenter from '../../presenters/UserPresenter';
import { MASTER_DATES, MONDAY } from '../../utilities/masterDatesEnum';
import { getScheduleTypeIcon } from '../../utilities/getScheduleTypeStyles';
import { fetchScheduleProgram } from '../../slices/programSlice';
import calendarIcon from '../../assets/calendar-icon.svg';
import chevronDown from '../../assets/chevron-down.svg';
const moment = require('moment');
const { Option } = Select;

export default function ScheduleHeader({ isMaster }: { isMaster?: boolean }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [masterDate, setMasterDate] = React.useState(
    localStorage.getItem('master_date') || MONDAY
  );
  const dispatch = useDispatch();
  React.useEffect(() => {
    store.dispatch(fetchScheduleProgram());
  }, []);

  const schedule = useSelector((state: State) => {
    return state.schedule;
  });

  let scheduleDate = useSelector((state: State) => {
    return state.schedule.date;
  });
  scheduleDate = new moment(scheduleDate, 'YYYY-MM-DD');

  const scheduleProgram = useSelector((state: State) => {
    return state.schedule.program;
  });

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const programs = useSelector((state: State) => {
    return state.programs;
  });

  const programsList = programs.list.map((id) => {
    return programs.byId[id];
  });

  const clickEvent = (scheduleType: number) => {
    dispatch(setScheduleType(scheduleType));
  };

  const changeDate = (value) => {
    if (value) {
      value = value.toISOString().slice(0, 10);
      localStorage.setItem('schedule_date', value);
      dispatch(
        fetchSchedule({
          date: value,
          program: scheduleProgram,
        })
      );
    }
  };

  const changeDay = (value) => {
    if (value) {
      localStorage.setItem('master_date', value);
      setMasterDate(value);
      dispatch(
        fetchSchedule({
          date: value,
          program: scheduleProgram,
        })
      );
    }
  };

  const toggleOpen = (open) => {
    setIsOpen(open);
  };

  const changeProgram = (value) => {
    if (value) {
      localStorage.setItem('program', value);
      dispatch(
        fetchSchedule({
          date: scheduleDate.toISOString().slice(0, 10),
          program: value,
        })
      );
    }
  };

  const selectPrograms = () => {
    const userPrograms = currentUserPresenter.user.program;
    const agencyPrograms = programsList;
    let programsToRender = [];

    if (currentUserPresenter.isUserUserType()) {
      // Find all the programs the user has access to
      if (userPrograms.length > 0) {
        programsToRender = agencyPrograms.filter((agencyProgram) =>
          userPrograms.find((userProgram) => userProgram === agencyProgram.id)
        );
      }
    } else {
      programsToRender = agencyPrograms;
    }

    return (
      <Select
        id="programs"
        value={scheduleProgram}
        style={{ width: 175 }}
        onChange={changeProgram}
        suffixIcon={
          <img
            src={chevronDown}
            alt="chevron drop down icon"
            width="30"
            height="30"
          />
        }
      >
        {programsToRender.map((program) => {
          return (
            <Option key={program.id} value={program.id} label={program.name}>
              {program.name}
            </Option>
          );
        })}
      </Select>
    );
  };

  return (
    <div className="schedule-header">
      <div className="left"></div>
      <div className="center">
        {schedule.types.map((type) => {
          return (
            <button
              key={type.id}
              className={schedule.type.id === type.id ? 'selected tab' : 'tab'}
              onClick={() => {
                clickEvent(type.id);
              }}
            >
              <div className="header-icon">
                {getScheduleTypeIcon(schedule, type)}
              </div>
              {type.type.toUpperCase()}
            </button>
          );
        })}
      </div>
      <div className="right">
        <div className="header-dropdown">
          {(currentUserPresenter.isAdminUserType() ||
            currentUserPresenter.isUserUserType()) &&
            currentUserPresenter.user && (
              <div>
                <label className="subtitle" htmlFor="programs">
                  Program
                </label>
                {selectPrograms()}
              </div>
            )}
        </div>

        {isMaster && (
          <div className="header-dropdown">
            <label className="subtitle" htmlFor="day">
              Day
            </label>
            <Select
              id="day"
              style={{ width: 175 }}
              onChange={changeDay}
              value={masterDate}
              suffixIcon={
                <img src={chevronDown} alt="chevron" width="30" height="30" />
              }
            >
              {Object.keys(MASTER_DATES).map((date) => {
                return (
                  <Option label={date} key={date} value={date}>
                    {MASTER_DATES[date]}
                  </Option>
                );
              })}
            </Select>
          </div>
        )}

        {!isMaster &&
          (currentUserPresenter.isUserUserType() ||
            currentUserPresenter.isAdminUserType()) && (
            <div className="calendar">
              <button onClick={() => toggleOpen(true)} className="date-button">
                {scheduleDate.format('MMM Do')}
              </button>
              <DatePicker
                allowClear={false}
                onOpenChange={toggleOpen}
                onChange={changeDate}
                bordered={false}
                value={scheduleDate}
                format={'MMM Do'}
                open={isOpen}
                suffixIcon={
                  <img
                    src={calendarIcon}
                    alt="calendar"
                    width="20"
                    height="20"
                  />
                }
              />
            </div>
          )}
      </div>
    </div>
  );
}
