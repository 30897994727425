// @flow
import React from 'react';
import styles from '../../directory/list-page/ListPage.module.scss';
import store from '../../general/store';
import { useSelector, useDispatch } from 'react-redux';
import type { State } from '../../general/types';
import UserPresenter from '../../presenters/UserPresenter';
import { Table, Modal, Button, Spin } from 'antd';
import { filterList } from '../../slices/searchSlice';
import SearchComponent from '../../directory/search-component/SearchComponent';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import deleteIcon from '../../assets/remove.svg';
import type { User } from '../../slices/userSlice';
import { userActions } from '../../slices/userSlice';
import { getSortProps } from '../../utilities/getSortProps';
import AddUsers from './add-users/AddUsers';
const { confirm } = Modal;

export default function Users() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    store.dispatch(userActions.fetch());
  }, []);

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const adminUsers = useSelector((state: State) => {
    return state.users;
  });

  const users = adminUsers.list.map((id) => {
    return adminUsers.byId[id];
  });

  const search = useSelector((state: State) => {
    return state.search;
  });

  let filteredList = filterList(search.byId['adminUser'], users);
  filteredList = filteredList.map((user) => new UserPresenter(user));

  const openModal = function (user: User) {
    confirm({
      title: `Are you sure you want to delete ${user.firstName} ${user.lastName}?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        dispatch(userActions.delete(user));
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'USER',
      dataIndex: 'name',
      key: 'name',
      ...getSortProps('name'),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      ...getSortProps('email'),
    },
    {
      title: 'TYPE',
      dataIndex: 'userType',
      key: 'userType',
      defaultSortOrder: 'ascend',
      ...getSortProps('userType'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (id) => {
        return (
          <div className={styles.options}>
            <AddUsers user={adminUsers.byId[id]} />
            <Button type="link" onClick={() => openModal(adminUsers.byId[id])}>
              <img src={deleteIcon} alt="pencil" width="25" height="25" />
            </Button>
          </div>
        );
      },
    },
  ];

  if (!currentUserPresenter.isAdminUserType()) {
    // Don't show User the edit/delete options
    columns.pop();
  }

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <SearchComponent
          id="adminUser"
          partial={filteredList.length}
          total={users.length}
        ></SearchComponent>
        {currentUser && filteredList.length > 0 ? (
          <Table dataSource={filteredList} columns={columns} />
        ) : (
          <div className={styles.spinner}>
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
}
