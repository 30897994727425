// @flow
import { createSlice } from '@reduxjs/toolkit';
import { defaultReducers } from './defaultReducers';
import { DefaultRequests } from './defaultRequests';
import axios from 'axios';

export interface ScheduleType {
  id: number;
  type: string;
  startTime: string;
  endTime: string;
}

export interface ScheduleTypeState {
  loading: boolean;
  error: string;
  list: ScheduleType[];
}

const initialState: ScheduleTypeState = {
  loading: false,
  error: '',
  list: [],
};

const scheduleType = createSlice({
  name: 'scheduleType',
  initialState,
  reducers: defaultReducers,
});

export const scheduleTypeReducer = scheduleType.reducer;
export const scheduleTypeActions = new DefaultRequests(
  scheduleType.actions,
  `schedules/schedule_types`
);

export const updateEventActions = async (
  eventActions: any,
  initialEvents: any
) => {
  for (let i = 0; i < eventActions.length; i++) {
    if (!eventActions[i].eventAPIupdateID) {
      await axios.post(
        `${
          process.env.REACT_APP_SERVER_URL || ''
        }/schedules/schedule_type_event_type_actions/`,
        eventActions[i]
      );
    } else {
      await axios.patch(
        `${
          process.env.REACT_APP_SERVER_URL || ''
        }/schedules/schedule_type_event_type_actions/${eventActions[i].id}/`,
        eventActions[i]
      );
    }
  }
  let eventIsDeleted = true;
  for (let i = 0; i < initialEvents.length; i++) {
    for (let j = 0; j < eventActions.length; j++) {
      if (eventActions[j].id === initialEvents[i].id) {
        eventIsDeleted = false;
        break;
      }
    }
    if (eventIsDeleted) {
      await axios.delete(
        `${
          process.env.REACT_APP_SERVER_URL || ''
        }/schedules/schedule_type_event_type_actions/${initialEvents[i].id}/`
      );
    }
    eventIsDeleted = true;
  }
};
