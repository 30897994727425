// @flow
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { defaultReducers } from './defaultReducers';
import { DefaultRequests } from './defaultRequests';

export interface EventType {
  id: number;
  event: string;
}

export interface EventTypeState {
  loading: boolean;
  error: string;
  list: EventType[];
  byId: {};
}

const initialState: EventTypeState = {
  loading: false,
  error: '',
  list: [],
  byId: {},
};

const event_types = createSlice({
  name: 'event_types',
  initialState,
  reducers: {
    getStart: defaultReducers.getStart,
    getSuccess: defaultReducers.getSuccess,
    getFailure: defaultReducers.getFailure,
    normalizeList: defaultReducers.normalizeList,
    add: defaultReducers.add,
    update: defaultReducers.update,
    delete: defaultReducers.delete,
  },
});

export const {
  getStart,
  getSuccess,
  getFailure,
  normalizeList,
} = event_types.actions;

export const eventTypeReducer = event_types.reducer;

export const eventTypeActions = new DefaultRequests(
  event_types.actions,
  `schedules/event_types`
);

export const fetchEventTypes = () => async (dispatch: any) => {
  try {
    dispatch(getStart());
    const event_types = await axios.get(
      `${process.env.REACT_APP_SERVER_URL || ''}/schedules/event_types/`
    );
    dispatch(getSuccess({ list: event_types.data }));
  } catch (err) {
    dispatch(getFailure(err));
  }
};
