import { PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';

let previousError: '';
export const defaultReducers = {
  getStart(state) {
    state.loading = true;
    state.error = '';
  },
  getSuccess(state, action: PayloadAction<any>) {
    state.loading = false;
    state.error = '';
    if (action.payload) {
      Object.assign(state, action.payload);
    }
  },
  getFailure(state, action: PayloadAction<any>) {
    state.loading = false;
    state.error = action.payload.message;
    const error = action.payload.message.substring(0, 300);
    if (error !== previousError) {
      message.error(error);
    }
    previousError = error;
    // Prevent duplicate errors rom the past few secons from showing up
    setTimeout(() => {
      previousError = '';
    }, 3000);
  },
  add(state, action: PayloadAction<any>) {
    state.loading = false;
    state.error = '';
    state.list.push(action.payload.id);
    state.byId[action.payload.id] = action.payload;
  },
  update(state, action: PayloadAction<any>) {
    state.loading = false;
    state.error = '';
    state.byId[action.payload.id] = action.payload;
  },
  delete(state, action: PayloadAction<any>) {
    state.loading = false;
    state.error = '';
    state.list.splice(
      state.list.findIndex((id) => {
        return id === action.payload.id;
      }),
      1
    );
    delete state.byId[action.payload.id];
  },
  normalizeList(state, action: PayloadAction<any>) {
    state.loading = false;
    const byId = {};
    (action.payload || []).forEach((item) => {
      byId[item.id] = item;
    });
    state.list = (action.payload || []).map((item) => {
      return item.id;
    });
    state.byId = byId;
  },
};
