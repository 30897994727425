// @flow
import React from 'react';
import './ParticipantCard.scss';
import '../../directory/Modal.scss';
import personIcon from '../../assets/head-icon.svg';
import chevronDown from '../../assets/chevron-down.svg';
import type { Participant } from '../../slices/participantSlice';
import { Menu, Dropdown, Button, Input, Tooltip, Select, Modal } from 'antd';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import FileTextOutlined from '@ant-design/icons/lib/icons/FileTextOutlined';
import CheckCircleOutlined from '@ant-design/icons/lib/icons/CheckCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons/lib/icons/CloseCircleOutlined';
import { useDispatch, useSelector } from 'react-redux';
import CloseSquareOutlined from '@ant-design/icons/lib/icons/CloseSquareOutlined';
import {
  deleteParticipant,
  patchParticipant,
  deleteParticipantForAllSchedules,
} from '../../slices/participantSlice';
import type { State } from '../../general/types';
import type { ScheduleCoach } from '../../slices/coachesSlice';
import WarningOutlined from '@ant-design/icons/lib/icons/WarningOutlined';
import StopOutlined from '@ant-design/icons/lib/icons/StopOutlined';
import { ParticipantStatusTypesEnum } from '../../utilities/participantStatusTypesEnum';
const { TextArea } = Input;
const { Option } = Select;

export default function ParticipantCard({
  participantId,
  scheduleCoach,
  includeMenu,
  isMaster,
}: {
  participantId: number,
  scheduleCoach?: ScheduleCoach,
  includeMenu?: boolean,
  isMaster?: boolean,
}) {
  const dispatch = useDispatch();
  const [showNote, setShowNote] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [note, setNote] = React.useState(null);
  const [currentStatus, setCurrentStatus] = React.useState(null);

  const participant: Participant = useSelector((state: State) => {
    return state.participants.byId[participantId];
  });

  const schedule = useSelector((state: State) => {
    return state.schedule;
  });

  const skills = useSelector((state: State) => {
    return state.skills.list;
  });

  const removeParticipant = () => {
    if (scheduleCoach) {
      dispatch(
        deleteParticipant(
          participantId,
          scheduleCoach.id,
          currentStatus,
          isMaster
        )
      );
    }
  };

  const removeParticipantFromAllSchedules = () => {
    if (scheduleCoach) {
      dispatch(
        deleteParticipantForAllSchedules(
          participantId,
          scheduleCoach.id,
          currentStatus,
          schedule.date,
          isMaster
        )
      );
    }
  };

  const saveNote = () => {
    dispatch(patchParticipant({ id: participant.id, note: note }));
  };

  const checkIn = () => {
    dispatch(
      patchParticipant({
        id: participant.id,
        requiresMoDropOff: !participant.requiresMoDropOff,
      })
    );
  };

  if (!participant) {
    return <div></div>;
  }

  if (typeof note !== 'string') {
    setNote(participant & participant.note || '');
  }

  const onChange = (value) => {
    dispatch(
      patchParticipant({
        id: participant.id,
        skill_id: value,
      })
    );
  };

  const menu = (
    <div className="user-menu">
      {showNote ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          className="note-menu"
        >
          <TextArea
            autoFocus
            rows={4}
            placeholder="Type your note here..."
            onChange={(e) => setNote(e.target.value)}
            value={note}
          />
          <Button
            type="link"
            className="close"
            onClick={() => setVisible(false)}
          >
            <CloseSquareOutlined />
          </Button>
          <Button
            className="submit"
            onClick={() => {
              setVisible(false);
              saveNote();
            }}
          >
            Submit
          </Button>
        </div>
      ) : (
        <Menu selectable={false}>
          <Menu.Item
            onClick={() => {
              setNote(participant.note);
              setShowNote(true);
            }}
          >
            <FileTextOutlined /> Add Note
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              setVisible(false);
              checkIn();
            }}
            className={participant.requiresMoDropOff && 'remove'}
          >
            <CheckCircleOutlined />{' '}
            {participant.requiresMoDropOff ? 'Remove' : 'Add'} Check-In
          </Menu.Item>

          <Menu.Item onClick={removeParticipant}>
            <CloseCircleOutlined /> Remove Participant
          </Menu.Item>

          {participant.status === ParticipantStatusTypesEnum.CALLED_OUT ? (
            <Menu.Item
              className="status-selected"
              onClick={() => {
                setVisible(false);
                setShowModal(true);
                setCurrentStatus(ParticipantStatusTypesEnum.SCHEDULED);
              }}
            >
              <WarningOutlined /> Called Out
            </Menu.Item>
          ) : (
            <Menu.Item
              onClick={() => {
                setVisible(false);
                setShowModal(true);
                setCurrentStatus(ParticipantStatusTypesEnum.CALLED_OUT);
              }}
            >
              <WarningOutlined /> Called Out
            </Menu.Item>
          )}

          {participant.status === ParticipantStatusTypesEnum.NO_SHOW ? (
            <Menu.Item
              className="status-selected"
              onClick={() => {
                setVisible(false);
                setShowModal(true);
                setCurrentStatus(ParticipantStatusTypesEnum.SCHEDULED);
              }}
            >
              <StopOutlined /> No Show
            </Menu.Item>
          ) : (
            <Menu.Item
              onClick={() => {
                setVisible(false);
                setShowModal(true);
                setCurrentStatus(ParticipantStatusTypesEnum.NO_SHOW);
              }}
            >
              <StopOutlined /> No Show
            </Menu.Item>
          )}
        </Menu>
      )}
    </div>
  );

  const renderSkills = () => {
    return skills.map((skill) => {
      return (
        <Option
          key={`${skill.id}_${participant.id}`}
          value={skill.id}
          label={skill.name}
        >
          {skill.name}
        </Option>
      );
    });
  };

  return (
    <div className="participant-card on-hover">
      <div>
        {<img src={personIcon} alt="head" width="25" height="25"></img>}{' '}
        <label className="name" htmlFor={participantId}>
          {participant.lastName}, {participant.firstName}
        </label>
        <Select
          id={participantId}
          disabled={!includeMenu}
          showSearch
          style={{
            width:
              participant.note || participant.requiresMoDropOff ? 170 : 194.8,
          }}
          placeholder="Select Skill"
          optionFilterProp="children"
          onChange={onChange}
          defaultValue={
            participant.skill?.id ? participant.skill.id : undefined
          }
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          suffixIcon={
            <img src={chevronDown} alt="chevron" width="30" height="30" />
          }
        >
          {renderSkills()}
        </Select>
      </div>
      {
        <div className="rightMenu">
          {participant.status === ParticipantStatusTypesEnum.OFF && (
            <WarningOutlined />
          )}
        </div>
      }
      {includeMenu && (
        <div className="right-menu">
          <Dropdown
            overlay={menu}
            trigger={['click']}
            visible={visible}
            onVisibleChange={setVisible}
            className={`ant-dropdown-link hover ${visible ? 'visible' : ''}`}
            onClick={(e) => {
              setShowNote(false);
              e.preventDefault();
            }}
          >
            <EllipsisOutlined />
          </Dropdown>
          <Modal
            width="900px"
            visible={showModal}
            footer={null}
            onCancel={() => setShowModal(false)}
            title={`Apply this only to ${schedule.type.type} or all schedule types?`}
          >
            <Button
              type="primary"
              name="submit button for single schedule"
              style={{ marginRight: '10px' }}
              onClick={() => {
                // setStatus(currentStatus);
                setShowModal(false);
                removeParticipant();
              }}
            >
              Just {schedule.type.type}
            </Button>
            <Button
              type="primary"
              name="submit button for multiple schedules"
              onClick={() => {
                // setStatusForAllSchedules(currentStatus);
                setShowModal(false);
                removeParticipantFromAllSchedules();
              }}
            >
              All Schedule Types
            </Button>
          </Modal>
          {(participant.note || participant.requiresMoDropOff) && (
            <div className="status-box">
              {participant.note && (
                <div>
                  <Tooltip title={participant.note}>
                    <span>
                      <FileTextOutlined style={{ color: 'white' }} />
                    </span>
                  </Tooltip>
                </div>
              )}
              {participant.requiresMoDropOff && (
                <div>
                  <CheckCircleOutlined style={{ color: 'white' }} />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
