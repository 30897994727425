// @flow
import React from 'react';

import { Form, Input } from 'antd';

export default function AddressForm({ required }: { required: boolean }) {
  return (
    <Form.List label="Address" name="addresses">
      {(fields) => (
        <div>
          <Form.Item hidden={true} name={[0, 'id']}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Address"
            name={[0, 'address']}
            rules={[
              { required: required, message: 'Please Input an Address!' },
            ]}
          >
            <Input placeholder="Address" />
          </Form.Item>
          <Form.Item name={[0, 'addressTwo']} label="Address Two">
            <Input placeholder="Address Two" />
          </Form.Item>
          <Form.Item
            label="City"
            name={[0, 'city']}
            rules={[{ required: required, message: 'Please Input a City!' }]}
          >
            <Input placeholder="City" />
          </Form.Item>
          <Form.Item
            label="State"
            name={[0, 'state']}
            rules={[{ required: required, message: 'Please Input a State!' }]}
          >
            <Input placeholder="State" />
          </Form.Item>
          <Form.Item
            label="Zip"
            name={[0, 'zip']}
            rules={[{ required: required, message: 'Please Input a Zip!' }]}
          >
            <Input placeholder="Zip" pattern="[0-9]*" />
          </Form.Item>
        </div>
      )}
    </Form.List>
  );
}
AddressForm.defaultProps = {
  required: true,
};
