// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserPresenter from '../presenters/UserPresenter';
import type { State } from '../general/types';
import { updateProgramTransfer } from '../slices/transferProgramSlice';
import { participantActions } from '../slices/participantUserSlice';
import { coachActions } from '../slices/coachUserSlice';
import styles from './Program.module.scss';
import { Transfer } from 'antd';
import { userActions } from '../slices/userSlice';

export default function Program(initialPrograms: any) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const programTransfer = useSelector((state: State) => {
    return state.programTransfer.transfer;
  });

  const users = useSelector((state: State) => {
    return state.users;
  });

  const participantUsers = useSelector((state: State) => {
    return state.participantUsers;
  });

  const coachUsers = useSelector((state: State) => {
    return state.coachUsers;
  });

  const programData = [];
  for (let i = 0; i < currentUserPresenter.user.agency.programs.length; i++) {
    programData.push({
      key: currentUserPresenter.user.agency.programs[i].id,
      name: currentUserPresenter.user.agency.programs[i].name,
    });
  }

  const filterOption = (inputValue, option) =>
    option.name.toLowerCase().includes(inputValue.toLowerCase());

  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    if (initialPrograms.initial.id === undefined) {
      dispatch(
        updateProgramTransfer({
          targetKeys: programTransfer.targetKeys,
          selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
          enableProgram: true,
        })
      );
    } else {
      dispatch(
        updateProgramTransfer({
          targetKeys: [],
          selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
          enableProgram: true,
        })
      );
    }
  };

  const handleChange = (nextTargetKeys, direction, moveKeys) => {
    if (initialPrograms.initial.id === undefined) {
      dispatch(
        updateProgramTransfer({
          targetKeys: nextTargetKeys,
          enableProgram: true,
        })
      );
    } else {
      let programUpdateData = [...nextTargetKeys];
      let ProgramBody = Object.assign({}, initialPrograms.initial);
      ProgramBody.program = programUpdateData;
      if (initialPrograms.initial.userType === 2) {
        dispatch(coachActions.upsert(ProgramBody));
      } else if (
        initialPrograms.initial.userType === 3 ||
        initialPrograms.initial.userType === 4
      ) {
        dispatch(userActions.upsert(ProgramBody));
      } else {
        dispatch(participantActions.upsert(ProgramBody));
      }
    }
  };

  const getTargetKeys = (initialPrograms) => {
    if (
      initialPrograms.initial.id === undefined ||
      initialPrograms.initial.userType === 4
    ) {
      return programTransfer.targetKeys;
    } else if (initialPrograms.initial.userType === 2) {
      return coachUsers.byId[initialPrograms.initial.id].program;
    } else if (initialPrograms.initial.userType === 3) {
      return users.byId[initialPrograms.initial.id].program;
    } else {
      return participantUsers.byId[initialPrograms.initial.id].program;
    }
  };

  return (
    <div className={styles.transport}>
      <Transfer
        dataSource={programData}
        titles={['Programs', 'Assigned']}
        showSearch
        targetKeys={getTargetKeys(initialPrograms)}
        filterOption={filterOption}
        onChange={handleChange}
        onSelectChange={handleSelectChange}
        render={(item) => item.name}
        disabled={false}
        style={{ marginBottom: 25 }}
      />
    </div>
  );
}
