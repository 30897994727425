// @flow
import React from 'react';
import axios from 'axios';
import styles from '../../directory/list-page/ListPage.module.scss';
import store from '../../general/store';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import type { State } from '../../general/types';
import { Layout, Menu, Spin, Transfer, notification, Result } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { groupActions } from '../../slices/groupSlice';
import AddGroup from '../groups/add-group/addGroup';
import { assignCoachesToGroup } from '../../slices/transferGroupSlice';
import { coachActions } from '../../slices/coachUserSlice';
const { Content, Sider } = Layout;

export default function Groups() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    store.dispatch(groupActions.fetch());
    store.dispatch(coachActions.fetch());
  }, []);
  const [groupSelected, setGroupSelected] = useState(false);
  const [coachesReady, setCoachesReady] = useState(false);
  const [firstSelect, setFirstSelect] = useState(false);
  const [coachTargetKeys, setCoachTargetKeys] = useState([]);
  const [coachSelectedKeys, setCoachSelectedKeys] = useState([]);
  const [selectedGroupID, setSelectedGroupID] = useState();

  const groups = useSelector((state: State) => {
    return state.groups;
  });

  const groupsList = groups.list.map((id) => {
    return groups.byId[id];
  });

  const coachUsers = useSelector((state: State) => {
    return state.coachUsers;
  });
  const coachList = coachUsers.list.map((id) => {
    return coachUsers.byId[id];
  });

  const getAssignedCoaches = (allCoaches, assignedCoaches) => {
    const initialAssignedCoaches = [];
    let assignedSet = new Set();

    for (let j = 0; j < assignedCoaches.length; j++) {
      assignedSet.add(assignedCoaches[j].user);
    }

    for (let i = 0; i < allCoaches.length; i++) {
      if (assignedSet.has(allCoaches[i].id)) {
        initialAssignedCoaches.push(allCoaches[i].id);
      }
    }

    setCoachTargetKeys(initialAssignedCoaches);
    setCoachesReady(true);
  };

  const renderGroupInfo = async (value) => {
    setFirstSelect(true);
    setGroupSelected(true);
    setCoachesReady(false);
    setSelectedGroupID(value.key);

    const apiCoachList = await axios.get(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/coach`
    );

    const apiAssignedCoachList = await axios.get(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/user_groups/${
        value.key
      }/assigned_coaches`
    );

    getAssignedCoaches(apiCoachList.data, apiAssignedCoachList.data);
  };

  const getTransferPatchedListData = (people) => {
    const peopleList = [];
    for (let i = 0; i < people.length; i++) {
      peopleList.push({
        key: people[i].id,
        name: people[i].firstName + ' ' + people[i].lastName,
      });
    }
    return peopleList;
  };

  const openNotification = (messageText) => {
    notification.success({
      message: messageText,
    });
  };

  const onChangeCoach = (nextTargetKeys) => {
    setCoachTargetKeys(nextTargetKeys);
    const request = {
      coaches: nextTargetKeys,
    };
    if (selectedGroupID === undefined) {
      dispatch(assignCoachesToGroup(request, groupsList[0].id));
    } else {
      dispatch(assignCoachesToGroup(request, parseInt(selectedGroupID)));
    }
    openNotification('Coaches Successfully Updated');
  };

  const onSelectChangeCoach = (sourceSelectedKeys, targetSelectedKeys) => {
    setCoachSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const filterOption = (inputValue, option) =>
    option.name.toLowerCase().includes(inputValue.toLowerCase());

  const getGroupStatus = (id) => {
    if (groups.byId[id]?.deleted) {
      return true;
    } else {
      return false;
    }
  };
  const resetGroupStatus = () => {
    setGroupSelected(false);
    setFirstSelect(false);
    setSelectedGroupID();
    setCoachesReady(false);
  };

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        {groupsList.length > 0 && coachList.length > 0 ? (
          <Layout style={{ minHeight: '100vh' }}>
            <Sider style={{ background: '#fff' }}>
              <Menu
                mode="inline"
                onSelect={renderGroupInfo}
                className={styles.siderCustom}
              >
                {groupsList.map((item) => {
                  return <Menu.Item key={item.id}>{item.group}</Menu.Item>;
                })}
              </Menu>
            </Sider>
            <Layout className={styles.siteLayout}>
              <Content>
                {groupSelected ? (
                  <div
                    className={styles.siteLayoutBackground}
                    style={{ padding: 24, minHeight: 360, paddingBottom: 150 }}
                  >
                    <h3 className="header-update">Update Group</h3>
                    <div className="content-underheader">
                      {selectedGroupID ? (
                        <AddGroup
                          group={groups.byId[selectedGroupID]}
                          groupStatus={getGroupStatus(selectedGroupID)}
                          groupResetStatus={() => resetGroupStatus()}
                        />
                      ) : (
                        <AddGroup
                          group={groups.byId[groupsList[0].id]}
                          groupStatus={getGroupStatus(selectedGroupID)}
                          groupResetStatus={() => resetGroupStatus()}
                        />
                      )}
                      {coachesReady ? (
                        <>
                          <h3 style={{ margin: '35px 0px 25px 0px' }}>
                            Coaches
                          </h3>
                          <div className="transfer-section">
                            <Transfer
                              dataSource={getTransferPatchedListData(coachList)}
                              titles={['All', 'Assigned']}
                              showSearch
                              listStyle={{ width: '100%' }}
                              targetKeys={coachTargetKeys}
                              selectedKeys={coachSelectedKeys}
                              onChange={onChangeCoach}
                              onSelectChange={onSelectChangeCoach}
                              filterOption={filterOption}
                              render={(item) => item.name}
                              operations={['assign', 'unassign']}
                              disabled={getGroupStatus(selectedGroupID)}
                            />
                          </div>
                        </>
                      ) : (
                        <div className={styles.spinner}>
                          <Spin />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    {firstSelect ? (
                      <div className={styles.spinner}>
                        <Spin />
                      </div>
                    ) : (
                      <Result
                        icon={<SearchOutlined />}
                        title="Select a Group to View or Update"
                      />
                    )}
                  </>
                )}
              </Content>
            </Layout>
          </Layout>
        ) : (
          <div className={styles.spinner}>
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
}
