// @flow
import React, { useEffect } from 'react';
import './Schedule.scss';
import Nav from '../nav/Nav';
import { useSelector } from 'react-redux';
import { UserTypesEnum } from '../utilities/userTypesEnum';
import type { State } from '../general/types';
import { Route, Switch, useHistory } from 'react-router';
import Directory from '../directory/Directory';
import Admin from '../admin/Admin';
import Search from '../search/Search';
import Scheduler from './scheduler/Scheduler';
import AutoLogout from '../utilities/userAutoLogout';
import CoachCalendar from './coach-calendar/CoachCalendar';

export default function Schedule() {
  const history = useHistory();

  useSelector((state: State) => {
    if (
      state.auth.currentUser &&
      state.auth.currentUser.userType !== UserTypesEnum.USER &&
      state.auth.currentUser.userType !== UserTypesEnum.ADMIN
    ) {
      history.push('/login');
    }
    return state.auth;
  });

  const timeStamp = new Date().getTime();

  const resetTimer = () => {
    if (localStorage.getItem('time_stamp')) {
      let oldTime = localStorage.getItem('time_stamp');
      let newTime = new Date().getTime();
      if (newTime - oldTime > 3600000) {
        history.push('/login');
      } else {
        localStorage.setItem('time_stamp', newTime);
      }
    }
  };

  useEffect(() => {
    localStorage.setItem('time_stamp', timeStamp);
    window.addEventListener('mouseover', () => resetTimer());
    window.addEventListener('touchstart', () => resetTimer());
  });

  return (
    <div className="schedule">
      <div className="header">
        <Nav />
      </div>
      <Switch>
        <Route path="/schedule/scheduler">
          <Scheduler />
        </Route>
        <Route path="/schedule/master">
          <Scheduler isMaster={true} />
        </Route>
        <Route path="/schedule/directory">
          <Directory />
        </Route>
        <Route path="/schedule/admin">
          <Admin />
        </Route>
        <Route path="/schedule/coach-calendar/:id/">
          <CoachCalendar />
        </Route>
        <Route path="/schedule/search">
          <Search />
        </Route>
        <Route>
          <Scheduler />
        </Route>
      </Switch>
      <AutoLogout />
    </div>
  );
}
