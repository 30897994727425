// @flow
import React from 'react';
import '../../../directory/Modal.scss';
import styles from '../../../directory/Directory.module.scss';
import { Button, Form, Input, Modal, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import type { State } from '../../../general/types';
import UserPresenter from '../../../presenters/UserPresenter';
import editIcon from '../../../assets/edit-icon.svg';
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import { encounterTypeActions } from '../../../slices/encounterTypeSlice';
import type { EncounterType } from '../../../slices/encounterTypeSlice';
const { Option } = Select;

export default function AddEncounterType({
  encounter,
}: {
  encounter?: EncounterType,
}) {
  const [submitting, setSubmitting] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  let initialValues = {};
  if (encounter) {
    initialValues = Object.assign({}, encounter);
  }

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleOk = (e) => {
    hideModal();
  };

  const handleCancel = (e) => {
    hideModal();
  };

  let formSubmit = async (values) => {
    setSubmitting(true);

    const result = await dispatch(
      encounterTypeActions.upsert(
        Object.assign({}, values, {
          agency: currentUserPresenter.user.agency.id,
        })
      )
    );
    if (result) {
      form.resetFields();
      hideModal();
    }
    setSubmitting(false);
  };

  const changeReimbursement = (values) => {};

  return (
    <div>
      {encounter ? (
        <Button type="link" onClick={showModal}>
          <img
            src={editIcon}
            alt="Edit encounter type button"
            width="25"
            height="25"
          />
        </Button>
      ) : (
        <button className={styles.teammate} onClick={showModal}>
          <div>
            <PlusCircleOutlined className="plus-icon-dashboard" />
            <p className="add-text-align">ADD ENCOUNTER TYPE</p>
          </div>
        </button>
      )}
      <Modal
        width="900px"
        title={encounter ? 'Update Encounter Type' : 'Add Encounter Type'}
        visible={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          onFinish={formSubmit}
          className="forgot-password"
          form={form}
          initialValues={initialValues}
        >
          <div className="split">
            <div>
              <Form.Item name="id" hidden={true}>
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: 'Please Input an Encounter Name!',
                  },
                ]}
              >
                <Input type="text" placeholder="Name" />
              </Form.Item>
              <Form.Item
                name="reimbursement"
                label="Reimbursement Type"
                rules={[{ required: true, message: 'Please Select a Zone!' }]}
              >
                <Select
                  style={{ width: 175 }}
                  placeholder="Reimbursement Type"
                  onChange={changeReimbursement}
                >
                  <Option value={1}>FMS</Option>
                  <Option value={2}>Standard</Option>
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className="buttons">
            <Button type="primary" htmlType="submit" disabled={submitting}>
              {encounter ? 'Update Encounter' : 'Create Encounter Type'}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
