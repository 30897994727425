// @flow
import React from 'react';
import '../../../directory/Modal.scss';
import styles from '../../../directory/Directory.module.scss';
import './addPrograms.scss';
import { Button, Form, Input, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import type { State } from '../../../general/types';
import UserPresenter from '../../../presenters/UserPresenter';
import {
  Program,
  upsertProgram,
  deleteProgram,
} from '../../../slices/programSlice';
import ScheduleTypes from '../../scheduleTypes/ScheduleTypes';
import AddScheduleType from '../../scheduleTypes/add-scheduleTypes/AddScheduleTypes';
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
const { confirm } = Modal;

export default function AddProgram({
  program,
  programStatus,
}: {
  program?: Program,
  programStatus?: boolean,
}) {
  const [submitting, setSubmitting] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  let initialValues = {};
  if (program) {
    initialValues = Object.assign({}, program);
  }

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleOk = (e) => {
    hideModal();
  };

  const handleCancel = (e) => {
    hideModal();
  };

  let formSubmit = async (values) => {
    setSubmitting(true);
    if (initialValues.id) {
      openUpdateModal(values);
    } else {
      const result = await dispatch(
        upsertProgram({
          name: values.name,
          agency: currentUserPresenter.user.agency.id,
        })
      );
      if (result) {
        form.resetFields();
        hideModal();
      }
      setSubmitting(false);
    }
  };

  const openUpdateModal = async (values: any) => {
    confirm({
      title: program?.deleted
        ? `Reactivate ${program?.name ?? 'this program'}?`
        : `Update ${program?.name ?? 'this program'} with these changes?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      async onOk() {
        const programNewName = values.name ?? initialValues.name;
        const result = await dispatch(
          upsertProgram({
            id: initialValues.id,
            name: programNewName,
            agency: currentUserPresenter.user.agency.id,
          })
        );
        if (result) {
          form.resetFields();
          hideModal();
        }
        setSubmitting(false);
      },
      onCancel() {
        form.resetFields();
      },
    });
  };

  const openDeactivateModal = async (values: any) => {
    confirm({
      title: `Are you sure you want to deactivate this program?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okButtonProps: { type: 'danger' },
      async onOk() {
        dispatch(deleteProgram(initialValues));
      },
      onCancel() {
        form.resetFields();
      },
    });
  };

  return (
    <div>
      {program ? (
        <>
          <Form
            onFinish={formSubmit}
            className="forgot-password"
            form={form}
            colon={false}
          >
            <div className="slpit">
              <>
                <Form.Item name="id" hidden={true}>
                  <Input type="text" />
                </Form.Item>
                <Form.Item name="name" label={<p className="title">Title</p>}>
                  <Input type="text" placeholder={initialValues.name} />
                </Form.Item>
              </>
            </div>

            <div className="buttons">
              <button
                className={styles.programButton}
                type="primary"
                htmltype="submit"
                disabled={submitting}
              >
                {program.deleted ? 'Activate' : 'Update'}
              </button>
            </div>
          </Form>
          <h3 style={{ margin: '50px 0px 25px 0px' }}>Schedule Type(s)</h3>
          <div className="schedule-type-section">
            <AddScheduleType
              programID={program.id}
              programStatus={programStatus}
            />
            <ScheduleTypes
              programID={program.id}
              programStatus={programStatus}
            />
          </div>
          {program.deleted ? (
            ''
          ) : (
            <button
              className={styles.programDelete}
              type="danger"
              onClick={openDeactivateModal}
            >
              Deactivate
            </button>
          )}
        </>
      ) : (
        <button className={styles.teammate} onClick={showModal}>
          <div>
            <PlusCircleOutlined className="plus-icon-dashboard" />
            <p className="add-text-align">ADD PROGRAM</p>
          </div>
        </button>
      )}
      <Modal
        width="900px"
        title={program ? 'Update program' : 'Add program'}
        visible={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          onFinish={formSubmit}
          className="forgot-password"
          form={form}
          initialValues={initialValues}
        >
          <div className="split">
            <div>
              <Form.Item name="id" hidden={true}>
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="name"
                label="Program Name"
                rules={[
                  { required: true, message: 'Please Input a Program Name!' },
                ]}
              >
                <Input type="text" placeholder="Program Name" />
              </Form.Item>
            </div>
          </div>

          <div className="buttons">
            <Button type="primary" htmlType="submit" disabled={submitting}>
              {program ? 'Update program' : 'Create New program'}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
