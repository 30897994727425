import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultReducers } from './defaultReducers';
import axios from 'axios';
import { addParticipantCoach, removeParticipantCoach } from './coachesSlice';
import store from '../general/store';
import { fetchBasicSchedule, scheduleSliceActions } from './scheduleSlice';
import type { Skill } from './skillSlice';

export interface Address {
  address: string;
  addressTwo: string;
  city: string;
  state: string;
  zip: string;
  skill: Skill;
}

export interface Participant {
  id: string;
  user: Participant;
  firstName: string;
  lastName: string;
  scheduledCoach: number;
  note: string;
  requiresMoDropOff: boolean;
  transportationType: any;
  currentEventType: any;
  status: string;
  addresses: Address[];
}

export interface ParticipantState {
  loading: boolean;
  error: Error;
  list: string[];
  byId: { string: Participant };
}

const initialState: ParticipantState = {
  loading: false,
  error: null,
  list: [],
  byId: {},
};

const participants = createSlice({
  name: 'participants',
  initialState,
  reducers: {
    getStart: defaultReducers.getStart,
    getSuccess: defaultReducers.getSuccess,
    getFailure: defaultReducers.getFailure,
    normalizeList: defaultReducers.normalizeList,
    updateParticipant(state: ParticipantState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = '';
      // The server is returning a compounded ID in some places. Let's check for both.
      if (state.byId[action.payload.id]) {
        Object.assign(state.byId[action.payload.id], action.payload);
      } else {
        let combined_id = action.payload.user + '_' + action.payload.schedule;
        Object.assign(state.byId[combined_id], action.payload);
      }
    },
    updateParticipantForAllSchedules(
      state: ParticipantState,
      action: PayloadAction<any>
    ) {
      state.loading = false;
      state.error = '';

      // The server is returning a compounded ID in some places. Let's check for both.
      if (state.byId[action.payload.id]) {
        Object.assign(state.byId[action.payload.id], action.payload);
      } else {
        const payloadArray = action.payload;
        for (let i = 0; i < payloadArray.length; i++) {
          let combined_id =
            payloadArray[i].user + '_' + payloadArray[i].schedule;
          state.byId[combined_id] &&
            Object.assign(state.byId[combined_id], payloadArray[i]);
        }
      }
    },
  },
});

export const {
  getStart,
  getSuccess,
  normalizeList,
  getFailure,
  updateParticipant,
  updateParticipantForAllSchedules,
} = participants.actions;

export const participantSliceActions = participants.actions;
export const participantsReducer = participants.reducer;

const sendParticipant = (participantId, participant) => async (
  dispatch: any
) => {
  dispatch(fetchBasicSchedule());
  const updatedParticipant = await axios.patch(
    `${
      process.env.REACT_APP_SERVER_URL || ''
    }/schedules/scheduled_participants/${participant.id}/?expand=~all`,
    participant
  );
  dispatch(
    scheduleSliceActions.getSuccess({
      modifiedDate: updatedParticipant.data.scheduleModifiedDate,
    })
  );
  return updatedParticipant.data;
};

export const deleteParticipant = (
  participantId: string,
  coachId: number,
  status?: string,
  isMaster?: boolean
) => async (dispatch: any) => {
  let originalStatus;

  try {
    const state = store.getState();
    originalStatus = state.participants.byId[participantId].status;
    dispatch(getStart());
    let data = { id: participantId, scheduledCoach: null, isMaster: isMaster };

    if (status) {
      data.status = status;
      dispatch(updateParticipant({ id: participantId, status: status }));
    }

    dispatch(updateParticipant({ scheduledCoach: null, id: participantId }));
    dispatch(removeParticipantCoach({ coachId, participantId }));
    await dispatch(sendParticipant(participantId, data));
  } catch (err) {
    dispatch(
      updateParticipant({
        id: participantId,
        status: originalStatus,
        scheduledCoach: coachId,
      })
    );

    dispatch(addParticipantCoach({ coachId: coachId, participantId }));
    dispatch(getFailure(err));
  }
};

export const deleteParticipantForAllSchedules = (
  participantId: string,
  coachId: number,
  status?: string,
  date?: Date,
  isMaster?: boolean
) => async (dispatch: any) => {
  let originalStatus;
  try {
    const state = store.getState();
    originalStatus = state.participants.byId[participantId].status;
    dispatch(getStart());
    let data = {
      id: participantId,
      scheduledCoach: null,
      date: date,
      isMaster: isMaster,
    };
    if (status) {
      data.status = status;
      dispatch(
        updateParticipantForAllSchedules({
          scheduledCoach: null,
          id: participantId,
          status: status,
        })
      );
      dispatch(removeParticipantCoach({ coachId, participantId }));
      await dispatch(sendParticipantForAllSchedules(participantId, data));
    }
  } catch (err) {
    dispatch(
      updateParticipant({
        id: participantId,
        status: originalStatus,
        scheduledCoach: coachId,
      })
    );
    dispatch(addParticipantCoach({ coachId: coachId, participantId }));
    dispatch(getFailure(err));
  }
};

export const patchParticipant = (participant: Participant) => async (
  dispatch: any
) => {
  try {
    dispatch(getStart());
    const updatedParticipant = await dispatch(
      sendParticipant(participant.id, participant)
    );
    dispatch(updateParticipant(updatedParticipant));
  } catch (err) {
    dispatch(getFailure(err));
  }
};

const sendParticipantForAllSchedules = (
  participantId,
  participant: Participant
) => async (dispatch: any) => {
  dispatch(fetchBasicSchedule());
  const updatedParticipant = await axios.patch(
    `${
      process.env.REACT_APP_SERVER_URL || ''
    }/schedules/scheduled_participants/${participant.id}/bulk_update_status/`,
    participant
  );
  dispatch(
    scheduleSliceActions.getSuccess({
      modifiedDate: updatedParticipant.data.scheduleModifiedDate,
    })
  );
  return updatedParticipant.data;
};

export const patchParticipantForAllSchedule = (
  participant: Participant
) => async (dispatch: any) => {
  try {
    dispatch(getStart());

    const updatedParticipant = await dispatch(
      sendParticipantForAllSchedules(participant.id, participant)
    );

    dispatch(updateParticipantForAllSchedules(updatedParticipant));
  } catch (err) {
    dispatch(getFailure(err));
  }
};

// Must update state before request for drag and drop to work smoothly
export const prePatchParticipant = (participant: Participant) => async (
  dispatch: any
) => {
  try {
    dispatch(getStart());
    dispatch(updateParticipant(participant));
    await dispatch(sendParticipant(participant.id, participant));
  } catch (err) {
    dispatch(getFailure(err));
  }
};

export const moveParticipant = (
  participantId: string,
  oldCoachId: number,
  newCoachId: number,
  isMaster: Boolean
) => async (dispatch: any) => {
  try {
    dispatch(getStart());
    dispatch(removeParticipantCoach({ coachId: oldCoachId, participantId }));
    dispatch(
      updateParticipant({ scheduledCoach: newCoachId, id: participantId })
    );
    dispatch(addParticipantCoach({ coachId: newCoachId, participantId }));
    await dispatch(
      sendParticipant(participantId, {
        id: participantId,
        scheduledCoach: newCoachId,
        isMaster: isMaster,
      })
    );
  } catch (err) {
    dispatch(removeParticipantCoach({ coachId: newCoachId, participantId }));
    dispatch(
      updateParticipant({ scheduledCoach: oldCoachId, id: participantId })
    );
    dispatch(addParticipantCoach({ coachId: oldCoachId, participantId }));
    dispatch(getFailure(err));
  }
};

export const postParticipant = (
  participantId: string,
  coachId: number,
  status?: string,
  isMaster?: Boolean
) => async (dispatch: any) => {
  let originalStatus;
  try {
    const state = store.getState();
    originalStatus = state.participants.byId[participantId].status;
    dispatch(getStart());
    let data = {
      id: participantId,
      scheduledCoach: coachId,
      isMaster: isMaster,
    };
    if (status) {
      data.status = status;
      dispatch(updateParticipant({ id: participantId, status: status }));
    }
    dispatch(updateParticipant({ scheduledCoach: coachId, id: participantId }));
    dispatch(addParticipantCoach({ coachId, participantId }));
    await dispatch(sendParticipant(participantId, data));
  } catch (err) {
    dispatch(
      updateParticipant({
        id: participantId,
        status: originalStatus,
        scheduledCoach: null,
      })
    );
    dispatch(removeParticipantCoach({ coachId, participantId }));
    dispatch(getFailure(err));
  }
};

export const nextState = (participantId: string) => async (dispatch: any) => {
  try {
    dispatch(getStart());

    const updatedParticipant = await axios.post(
      `${process.env.REACT_APP_SERVER_URL || ''}/schedules/next_state/`,
      { id: participantId }
    );
    dispatch(updateParticipant(updatedParticipant.data));
  } catch (err) {
    dispatch(getFailure(err));
  }
};

export const getParticipantReport = async (
  date_from: String,
  date_to: String
) => {
  const report = await axios.get(
    `${
      process.env.REACT_APP_SERVER_URL || ''
    }/aerostar/reports/participant_by_day?date_from=${date_from}&date_to=${date_to}`
  );
  return report.data;
};
