// @flow
import React from 'react';
import './CoachCard.scss';
import { useSelector } from 'react-redux';
import { fetchEventTypes } from '../../slices/eventTypeSlice';
import ParticipantCard from '../participant-card/ParticipantCard';
import store from '../../general/store';
import UserPresenter from '../../presenters/UserPresenter';
import type { State } from '../../general/types';
import Transport from '../../transport/Transport';
import axios from 'axios';

export default function CoachCard() {
  const [partnerData, setPartnerData] = React.useState([]);

  React.useEffect(() => {
    store.dispatch(fetchEventTypes());
  }, []);

  const currentUserPresenter = useSelector((state: State) => {
    return new UserPresenter(state.auth.currentUser);
  });

  const coach = useSelector((state: State) => {
    return state.coaches.byId[state.coaches.list[0]];
  });

  const coachID = currentUserPresenter?.user?.id;

  React.useEffect(() => {
    const getPartners = async () => {
      const res = await axios.get(
        `${
          process.env.REACT_APP_SERVER_URL || ''
        }/aerostar/user_groups/${coachID}/get_partners`
      );

      let resData = res['data'];
      let partners = [];

      for (let i = 0; i < resData.length; i++) {
        const item = [
          resData[i]['user_FirstName'] + ' ' + resData[i]['user_LastName'],
          resData[i]['user_PhoneNumber'],
          resData[i]['user_Email'],
          resData[i]['user_Id'],
        ];

        if (item[3] !== coachID) {
          partners.push(item);
        }
      }

      return partners;
    };

    let isMounted = true;

    getPartners().then((data) => {
      if (isMounted) setPartnerData(data);
    });

    return () => {
      isMounted = false;
    };
  }, [coachID]);

  const showLocation = (coach, location) => {
    return (
      <div>
        {coach && coach[location] && coach[location].googleMapUrl ? (
          <a href={coach[location].googleMapUrl}>{coach[location].name}</a>
        ) : (
          coach && coach[location] && coach[location].name
        )}
      </div>
    );
  };

  const showLocationStart = (coach) => {
    return showLocation(coach, 'locationStart');
  };

  const showLocationEnd = (coach) => {
    return showLocation(coach, 'locationEnd');
  };

  const showNotes = (coach) => {
    return <div>{coach !== undefined ? coach.note : ''}</div>;
  };

  let generateList = () => {
    return partnerData.map((item) => {
      return (
        <div key={item[0]}>
          <div className="partner-name">{item[0]}</div>
          <div className="partner-info">{item[1]}</div>
          <div className="partner-info">{item[2]}</div>
          <br></br>
        </div>
      );
    });
  };

  return (
    <div className="coach-start">
      <div className="coach-start-title">COACH</div>
      <div className="header">{currentUserPresenter.firstLastNames()}</div>
      <div className="actions">
        <div className="left">
          <div className="action-header">People Center</div>
        </div>
        <div className="right">
          <div className="action-header">Actions</div>
        </div>
      </div>
      <div className="list">
        {coach &&
        coach.scheduledParticipants &&
        coach.scheduledParticipants.length ? (
          coach.scheduledParticipants.map((participantId) => {
            return (
              <ParticipantCard
                key={`participant-${participantId}`}
                participantId={participantId}
              />
            );
          })
        ) : (
          <div>LOADING Participants</div>
        )}
      </div>

      <div>
        <div className="location-skill">
          <div>Pickup Location</div>
        </div>
        <div className="location-skill-item">{showLocationStart(coach)}</div>

        <div className="location-skill">
          <div>Destination</div>
        </div>
        <div className="location-skill-item">{showLocationEnd(coach)}</div>
      </div>

      <div className="transport-note-container">
        <div className="transportation-div">
          <div className="transportation-type">Encounter Type</div>
          <div>
            <Transport
              participantId={
                coach &&
                coach.scheduledParticipants &&
                coach.scheduledParticipants[0]
              }
            />
          </div>
        </div>

        <div>
          <div className="note">Note</div>
          <div className="show-note">{showNotes(coach)}</div>
        </div>
      </div>

      <div>
        <div className="location-skill">Partners</div>
        <div className="partners-content">
          <div className="partners-list">{generateList()}</div>
        </div>
      </div>
    </div>
  );
}
