// @flow
import React from 'react';
import './ParticipantCard.scss';
import personIcon from '../../assets/head-icon-border_1px.svg';
import { Tooltip } from 'antd';
import type { Participant } from '../../slices/participantSlice';
import ParticipantCardAction from '../participant-card-action/ParticipantCardAction';
import FileTextOutlined from '@ant-design/icons/lib/icons/FileTextOutlined';
import CheckCircleOutlined from '@ant-design/icons/lib/icons/CheckCircleOutlined';
import { useSelector } from 'react-redux';
import type { State } from '../../general/types';
import { formatAddress } from '../../utilities/formatAddress';

export default function ParticipantCard({
  participantId,
}: {
  participantId: number,
}) {
  const participant: Participant = useSelector((state: State) => {
    return state.participants.byId[participantId];
  });

  if (!participant) {
    return <div></div>;
  }

  //TODO: Presenter as soon as two or more usages
  function presentAddress() {
    if (participant.addresses && participant.addresses.length) {
      //System currently allows for multiple addresses per user, but for now, the assumption is a user will have at most one Address
      //IF THIS EVER CHANGES, WE WILL NEED AN IDENTIFIER or the API will need to identify the correct address.
      let address = participant.addresses[0];
      let address_google_map_url = address.googleMapUrl;
      if (address.address && address.addressTwo) {
        return (
          <a href={`${address_google_map_url}`}> {formatAddress(address)}</a>
        );
      }
      return (
        <a href={`${address_google_map_url}`}>
          {' '}
          {address.address} {address.city} {address.state} {address.zip}
        </a>
      );
    }

    return 'No Address Provided';
  }

  return (
    <div className="coach-dashboard participant-card">
      <div className="left-container">
        {<img src={personIcon} alt="head" width="100" height="100"></img>}{' '}
        <div className="name">
          {participant.firstName} {participant.lastName}
          <div className="name-header">Location</div>
          <div className="header-detail">{presentAddress()}</div>
          {participant.skill ? (
            <>
              <div className="name-header">Skill</div>
              <div className="header-detail">{participant.skill.name}</div>
            </>
          ) : (
            ''
          )}
        </div>
        <div className="icons">
          <div>
            {participant.note && (
              <Tooltip title={participant.note}>
                <span>
                  <FileTextOutlined />
                </span>
              </Tooltip>
            )}
          </div>
          <div className="check-mark">
            {participant.requiresMoDropOff && <CheckCircleOutlined />}
          </div>
        </div>
      </div>
      <ParticipantCardAction participant={participant} />
    </div>
  );
}
