// @flow
import React from 'react';
import styles from './Directory.module.scss';
import { Route, Switch, useHistory } from 'react-router';
import PeopleCenter from './people-center/PeopleCenter';
import Coaches from './coaches/Coaches';
import Locations from './locations/Locations';
import { useSelector } from 'react-redux';
import AddParticipant from './people-center/add-participant/AddParticipant';
import AddCoach from './coaches/add-coach/AddCoach';
import AddLocation from './locations/addLocation/AddLocation';
import type { State } from '../general/types';
import UserPresenter from '../presenters/UserPresenter';
import { Checkbox, Switch as AntSwitch } from 'antd';

export default function Directory() {
  const history = useHistory();
  const tab = useSelector((state) => {
    return state.general.url;
  });

  const [showActive, setShowActive] = React.useState(true);
  const [showAll, setShowAll] = React.useState(false);

  const navigate = (target) => {
    return () => {
      history.push(target);
    };
  };

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const toggleActive = () => {
    setShowActive(!showActive);
  };

  const toggleAllUsers = (checkedValue) => {
    if (checkedValue.target.checked) {
      setShowAll(true);
    } else {
      setShowAll(false);
    }
  };

  return (
    <div>
      <div className={styles.main}>
        <div className={styles.center}>
          <div
            className={tab.includes('/people_center') ? styles.selected : ''}
          >
            <button
              className={styles.button}
              onClick={navigate('/schedule/directory/people_center')}
            >
              PEOPLE CENTER
            </button>
          </div>
          <div className={tab.includes('/coaches') ? styles.selected : ''}>
            <button
              className={styles.button}
              onClick={navigate('/schedule/directory/coaches')}
            >
              COACHES
            </button>
          </div>
          <div className={tab.includes('/locations') ? styles.selected : ''}>
            <button
              className={styles.button}
              onClick={navigate('/schedule/directory/locations')}
            >
              LOCATIONS
            </button>
          </div>
        </div>
        <div className={styles.left}>
          {currentUserPresenter.isAdminUserType() && (
            <React.Fragment>
              {tab.includes('/people_center') && <AddParticipant />}
              {tab.includes('/coaches') && <AddCoach />}
              {tab.includes('/locations') && <AddLocation />}
            </React.Fragment>
          )}
        </div>
        <div className={styles.right1}>
          <div className={styles.activeToggle}>
            <p className={styles.activeText}>ACTIVE</p>
            <AntSwitch defaultChecked={true} onChange={toggleActive} />
          </div>
        </div>
        <div className={styles.right2}>
          <div className={styles.showAll}>
            <p className={styles.activeText}>SHOW ALL</p>
            <Checkbox onChange={toggleAllUsers} />
          </div>
        </div>
      </div>
      <Switch>
        <Route path="/schedule/directory/people_center">
          <PeopleCenter activeRender={showActive} allUserRender={showAll} />
        </Route>
        <Route path="/schedule/directory/coaches">
          <Coaches activeRender={showActive} allUserRender={showAll} />
        </Route>
        <Route path="/schedule/directory/locations">
          <Locations />
        </Route>
        <Route>
          <PeopleCenter activeRender={showActive} allUserRender={showAll} />
        </Route>
      </Switch>
    </div>
  );
}
