// @flow
import React from 'react';
import styles from '../list-page/ListPage.module.scss';
import store from '../../general/store';
// Took out useDispatch import from react-redux to bypass delete implementation warnings
import { useSelector } from 'react-redux';
import type { State } from '../../general/types';
// Took out Modal, Button import from antd on delete Modal
import { Table, Spin } from 'antd';
import LocationPresenter from '../../presenters/LocationPresenter';
import SearchComponent from '../search-component/SearchComponent';
// Took out Location from slices/locationSlice to bypass delete implementation warnings
import { locationRequests } from '../../slices/locationSlice';
// Comment out delete implementation imports to bypass warnings for Circleci
// import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import AddLocation from './addLocation/AddLocation';
// import CloseCircleOutlined from '@ant-design/icons/lib/icons/CloseCircleOutlined';
import UserPresenter from '../../presenters/UserPresenter';
import { getSortProps } from '../../utilities/getSortProps';
// const { confirm } = Modal;

export default function Locations() {
  // const dispatch = useDispatch();
  React.useEffect(() => {
    store.dispatch(locationRequests.fetch());
  }, []);

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const locationState = useSelector((state: State) => {
    return state.locations;
  });

  const searchTerm = useSelector((state: State) => {
    return (
      state.search.byId['locations'] &&
      state.search.byId['locations'].searchTerm
    );
  });

  const locations = locationState.list.map((id) => {
    return locationState.byId[id];
  });

  let filteredList = locations.slice();
  if (searchTerm) {
    filteredList = filteredList.filter((item) => {
      return item.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }
  filteredList = filteredList.map(
    (location) => new LocationPresenter(location)
  );

  // Comment out delete Modal to bypass warnings since we will not be implementing deleting yet without further testing
  // const openModal = function (location: Location) {
  //   confirm({
  //     title: `Are you sure you want to delete ${location.name}?`,
  //     icon: <ExclamationCircleOutlined />,
  //     content: '',
  //     onOk() {
  //       dispatch(locationRequests.delete(location));
  //     },
  //     onCancel() {},
  //   });
  // };

  const columns = [
    {
      title: 'BUSINESS NAME',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      ...getSortProps('name'),
    },
    {
      title: 'ADDRESS',
      dataIndex: 'address',
      key: 'address',
      ...getSortProps('address'),
    },
    {
      title: 'PHONE NUMBER',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      ...getSortProps('phoneNumber'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (id) => {
        return (
          <div className={styles.options}>
            <AddLocation location={locationState.byId[id]} />

            {/* Comment out delete button in the meantime for further testing */}
            {/* <Button
              type="link"
              onClick={() => openModal(locationState.byId[id])}
            >
              <CloseCircleOutlined />
            </Button> */}
          </div>
        );
      },
    },
  ];

  if (!currentUserPresenter.isAdminUserType()) {
    // Don't show User the edit/delete options
    columns.pop();
  }

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <SearchComponent
          id="locations"
          partial={filteredList.length}
          total={locations.length}
        ></SearchComponent>
        {currentUser && filteredList.length > 0 ? (
          <Table dataSource={filteredList} columns={columns} />
        ) : (
          <div className={styles.spinner}>
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
}
