// @flow
import React from 'react';
import '../../../directory/Modal.scss';
import styles from '../../../directory/Directory.module.scss';
import { Button, Form, Input, Modal, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import type { User } from '../../../slices/userSlice';
import type { State } from '../../../general/types';
import UserPresenter from '../../../presenters/UserPresenter';
import editIcon from '../../../assets/edit-icon.svg';
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import { userActions } from '../../../slices/userSlice';
import Program from '../../../program/Program';
import { updateProgramTransfer } from '../../../slices/transferProgramSlice';
const { Option } = Select;

export default function AddUser({ user }: { user?: User }) {
  const [submitting, setSubmitting] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  let initialValues = {};
  if (user) {
    initialValues = Object.assign({}, user);
  }

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const programTransfer = useSelector((state: State) => {
    return state.programTransfer.transfer;
  });

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleOk = (e) => {
    hideModal();
  };

  const handleCancel = (e) => {
    hideModal();
  };

  let formSubmit = async (values) => {
    setSubmitting(true);
    if (!Array.isArray(values.user)) {
      values.user = [values.user];
    }
    if (values.userType === 4) {
      const result = await dispatch(
        userActions.upsert(
          Object.assign({}, values, {
            program: [],
            agency: currentUserPresenter.user.agency.id,
          })
        )
      );
      if (result) {
        form.resetFields();
        hideModal();
      }
    } else {
      if (Object.keys(initialValues).length === 0) {
        const result = await dispatch(
          userActions.upsert(
            Object.assign({}, values, {
              program: programTransfer.targetKeys,
              agency: currentUserPresenter.user.agency.id,
            })
          )
        );
        if (result) {
          form.resetFields();
          hideModal();
        }
      } else {
        const result = await dispatch(
          userActions.upsert(
            Object.assign({}, values, {
              agency: currentUserPresenter.user.agency.id,
            })
          )
        );
        if (result) {
          form.resetFields();
          hideModal();
        }
      }
    }
    dispatch(
      updateProgramTransfer({
        targetKeys: [],
        selectedKeys: [],
        enableProgram: programTransfer.enableProgram,
      })
    );
    setSubmitting(false);
  };

  const toggleProgram = async (value) => {
    if (value === 3) {
      dispatch(
        updateProgramTransfer({
          targetKeys: programTransfer.targetKeys,
          selectedKeys: programTransfer.selectedKeys,
          enableProgram: true,
        })
      );
    } else {
      dispatch(
        updateProgramTransfer({
          targetKeys: programTransfer.targetKeys,
          selectedKeys: programTransfer.selectedKeys,
          enableProgram: false,
        })
      );
    }
  };

  return (
    <div>
      {user ? (
        <Button type="link" onClick={showModal}>
          <img src={editIcon} alt="pencil" width="25" height="25" />
        </Button>
      ) : (
        <button className={styles.teammate} onClick={showModal}>
          <div>
            <PlusCircleOutlined className="plus-icon-dashboard" />
            <p className="add-text-align">ADD USER</p>
          </div>
        </button>
      )}
      <Modal
        width="900px"
        title={user ? 'Update User' : 'Add User'}
        visible={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          onFinish={formSubmit}
          className="forgot-password"
          form={form}
          initialValues={initialValues}
        >
          <div className="split">
            <div>
              <Form.Item name="id" hidden={true}>
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  { required: true, message: 'Please Input a First Name!' },
                ]}
              >
                <Input type="text" placeholder="First Name" />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  { required: true, message: 'Please Input a Last Name!' },
                ]}
              >
                <Input type="text" placeholder="Last Name" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'Please Input an Email Address!',
                  },
                ]}
              >
                <Input placeholder="Email Address" />
              </Form.Item>
              <Form.Item
                name="userType"
                label="Type"
                rules={[{ required: true, message: 'Please Select a Zone!' }]}
              >
                {Object.keys(initialValues).length === 0 ? (
                  <Select
                    style={{ width: 175 }}
                    placeholder="Type"
                    onChange={toggleProgram}
                    disabled={false}
                  >
                    <Option value={4}>Admin</Option>
                    <Option value={3}>User</Option>
                  </Select>
                ) : (
                  <Select
                    style={{ width: 175 }}
                    placeholder="Type"
                    onChange={toggleProgram}
                    disabled={true}
                  >
                    <Option value={4}>Admin</Option>
                    <Option value={3}>User</Option>
                  </Select>
                )}
              </Form.Item>
              {(programTransfer.enableProgram ||
                initialValues.userType === 3) &&
              initialValues.userType !== 4 ? (
                <Form.Item
                  label="Program"
                  rules={[
                    { required: true, message: 'Please Select a Program!' },
                  ]}
                >
                  <Program initial={initialValues} />
                </Form.Item>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="buttons">
            <Button type="primary" htmlType="submit" disabled={submitting}>
              {user ? 'Update User' : 'Create New User'}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
