import { createSlice } from '@reduxjs/toolkit';
import { defaultReducers } from './defaultReducers';

export interface GeneralState {
  isFetching: boolean;
  error: Error;
  url: string;
}

const initialState: GeneralState = {
  isFetching: false,
  error: null,
  url: '',
};

const general = createSlice({
  name: 'general',
  initialState,
  reducers: defaultReducers,
});

export const { getStart, getSuccess, getFailure } = general.actions;

export const generalReducer = general.reducer;

export const setUrl = (url) => (dispatch: any) => {
  dispatch(getSuccess({ url: url }));
};
