// @flow
import React from 'react';
import styles from '../list-page/ListPage.module.scss';
import './tables/Report.scss';
import {
  Table,
  Modal,
  Button,
  ConfigProvider,
  Select,
  DatePicker,
  Space,
} from 'antd';
import store from '../../general/store';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import type { State } from '../../general/types';
import UserPresenter from '../../presenters/UserPresenter';
import { filterList, filterUsers } from '../../slices/searchSlice';
import SearchComponent from '../search-component/SearchComponent';
import AddParticipant from './add-participant/AddParticipant';
import { participantActions } from '../../slices/participantUserSlice';
import type { ParticipantUser } from '../../slices/participantUserSlice';
import { getParticipantReport } from '../../slices/participantSlice';
import { getSortProps } from '../../utilities/getSortProps';
import MonthlyTable from './tables/MonthlyTable';
import deleteIcon from '../../assets/remove.svg';
import {
  SmileOutlined,
  RedoOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import chevronDown from '../../assets/chevron-down.svg';
import downloadIcon from '../../assets/download-icon.svg';
import calendarIcon from '../../assets/calendar-icon.svg';

const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center' }}>
    <SmileOutlined style={{ fontSize: 20 }} />
    <p>Data Not Found</p>
  </div>
);

const { confirm } = Modal;
const { Option } = Select;

export default function PeopleCenter({
  activeRender,
  allUserRender,
}: {
  activeRender?: boolean,
  allUserRender?: boolean,
}) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    store.dispatch(participantActions.fetch());
  }, []);

  const [showDirectory, setShowDirectory] = React.useState(true);
  const [reports, setReports] = React.useState({});
  //const [scheduledCounts, setScheduledCounts] = React.useState(0);
  const [monthDownload, setMonthDownload] = React.useState();
  const [reportMonthSelect, setReportMonthSelect] = React.useState('');

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const participantUsers = useSelector((state: State) => {
    return state.participantUsers;
  });
  const search = useSelector((state: State) => {
    return state.search;
  });

  const participants = participantUsers.list.map((id) => {
    return participantUsers.byId[id];
  });

  let filteredReport = {
    columns: reports?.columns,
    rows: reports?.rows?.filter((row) => {
      let searchTerm = search?.byId['participantUserAttendance']?.searchTerm;
      if (searchTerm) {
        return row['Participant']
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      }
      return row;
    }),
  };

  let filteredReportRowsSet = filteredReport?.rows
    ? new Set(filteredReport.rows.map((x) => x['Participant']))
    : new Set();
  let filteredReportLength = filteredReportRowsSet.size;

  let reportsRowsSet = reports?.rows
    ? new Set(reports.rows.map((x) => x['Participant']))
    : new Set();
  let reportsLength = reportsRowsSet.size;

  let filteredList = filterList(search.byId['participantUser'], participants);
  let activeUsersList = [];
  let InactiveUsersList = [];
  if (currentUser) {
    activeUsersList = filterUsers(filteredList, 'Active', currentUser.agency);
    InactiveUsersList = filterUsers(
      filteredList,
      'Deleted',
      currentUser.agency
    );
  }

  // Comment out delete Modal to bypass warnings since we will not be implementing deleting yet without further testing
  const openModal = function (user: ParticipantUser, action: string) {
    confirm({
      title: `Are you sure you want to ${action} ${user.firstName} ${user.lastName}?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        if (action === 'Deactivate') {
          dispatch(participantActions.delete(user));
        } else {
          dispatch(
            participantActions.upsert(
              Object.assign({}, user, {
                deleted: null,
              })
            )
          );
        }
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'TEAMMATE',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      ...getSortProps('name'),
      width: '30%',
    },
    {
      title: 'ADDRESS',
      dataIndex: 'address',
      key: 'address',
      ...getSortProps('address'),
    },
    {
      title: 'UCI',
      dataIndex: 'uciNumber',
      key: 'uciNumber',
      ...getSortProps('uciNumber'),
    },
    {
      title: 'ZONE',
      dataIndex: 'zone',
      key: 'zone',
      ...getSortProps('zone'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (id) => {
        let userDeleted = participantUsers.byId[id].deleted;
        if (!userDeleted) {
          return (
            <div className={styles.options}>
              <AddParticipant
                setDisabled={participantUsers.byId[id].deleted}
                user={participantUsers.byId[id]}
              />
              {/* Comment out delete button in the meantime for further testing */}
              <Button
                danger
                disabled={participantUsers.byId[id].deleted}
                type="link"
                onClick={() =>
                  openModal(participantUsers.byId[id], 'Deactivate')
                }
              >
                <img src={deleteIcon} alt="pencil" width="25" height="25" />
              </Button>
            </div>
          );
        } else {
          return (
            <div className={styles.options}>
              <Button
                // type="primary"
                type="link"
                onClick={() =>
                  openModal(participantUsers.byId[id], 'Reactivate')
                }
              >
                <RedoOutlined />
              </Button>
            </div>
          );
        }
      },
    },
  ];

  if (!currentUserPresenter.isAdminUserType()) {
    // Don't show User the edit/delete options
    columns.pop();
  }

  const handleChange = (value) => {
    setShowDirectory(!showDirectory);
  };

  const apiURL = `${process.env.REACT_APP_SERVER_URL || ''}`;
  const getDownloadURL = (month) => {
    const startDate = month.startOf('month').format('YYYY-MM-DD');
    const endDate = month.endOf('month').format('YYYY-MM-DD');
    return (
      apiURL +
      `/aerostar/reports/participant_by_month/?date_from=${startDate}&date_to=${endDate}`
    );
  };

  const renderUsers = () => {
    if (allUserRender) {
      return activeUsersList.concat(InactiveUsersList);
    } else if (activeRender) {
      return activeUsersList;
    } else {
      return InactiveUsersList;
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <div className={styles.reportHeader}>
          <div className={styles.left}>
            <SearchComponent
              id={
                showDirectory ? 'participantUser' : 'participantUserAttendance'
              }
              partial={
                showDirectory ? filteredList.length : filteredReportLength
              }
              total={showDirectory ? participants.length : reportsLength}
            ></SearchComponent>
          </div>
          <div className={styles.center}>
            <span className="dropdown-label">View</span>
            <Select
              defaultValue="directory"
              className="dropdown-select"
              onChange={handleChange}
              suffixIcon={
                <img src={chevronDown} alt="chevron" width="30" height="30" />
              }
            >
              <Option value="directory">
                <label htmlFor="rc_select_0">Directory</label>
              </Option>
              <Option value="attendance">Attendance</Option>
            </Select>
            {monthDownload ? (
              <a
                name="download-csv"
                style={{ paddingTop: '4px', paddingLeft: '15px' }}
                href={getDownloadURL(monthDownload)}
              >
                <img
                  src={downloadIcon}
                  alt="download-csv"
                  width="20"
                  height="20"
                />
              </a>
            ) : (
              ''
            )}
          </div>
          <div className={styles.right}>
            <Space direction="vertical" style={{ marginBottom: '15px' }}>
              <div className="report-select">
                <p className="report-date-text">{reportMonthSelect}</p>
                <DatePicker
                  onChange={async (date, dateString) => {
                    const start = moment(dateString, 'MMMM YYYY')
                      .startOf('month')
                      .format('YYYY-MM-DD');
                    const end = moment(dateString, 'MMMM YYYY')
                      .endOf('month')
                      .format('YYYY-MM-DD');
                    if (!dateString) {
                      setReports({ rows: [] });
                    } else {
                      const data = await getParticipantReport(start, end);
                      setReports(data);
                      //setScheduledCounts(data['rows'].length);
                    }
                    setMonthDownload(date);
                    setReportMonthSelect(dateString);
                  }}
                  bordered={false}
                  format={'MMMM, YYYY'}
                  picker="month"
                  suffixIcon={
                    <img
                      src={calendarIcon}
                      title="select-month"
                      alt="calendar"
                      width="20"
                      height="20"
                    />
                  }
                />
              </div>
            </Space>
          </div>
        </div>
        {showDirectory ? (
          <>
            <ConfigProvider
              renderEmpty={activeUsersList.length > 0 && customizeRenderEmpty}
            >
              <Table dataSource={renderUsers()} columns={columns} />
            </ConfigProvider>
          </>
        ) : (
          <MonthlyTable
            reports={filteredReport}
            scheduled={filteredReportLength}
          />
        )}
      </div>
    </div>
  );
}
