// @flow
import React from 'react';
import styles from '../list-page/ListPage.module.scss';
import store from '../../general/store';
import { useSelector, useDispatch } from 'react-redux';
import type { State } from '../../general/types';
import UserPresenter from '../../presenters/UserPresenter';
import { filterList, filterUsers } from '../../slices/searchSlice';
import SearchComponent from '../search-component/SearchComponent';
import AddCoach from './add-coach/AddCoach';
import { coachActions } from '../../slices/coachUserSlice';
import type { CoachUser } from '../../slices/coachUserSlice';
import { getSortProps } from '../../utilities/getSortProps';
import deleteIcon from '../../assets/remove.svg';
import '../people-center/tables/Report.scss';
import moment from 'moment';
import { getCoachesReport } from '../../slices/coachesSlice';
import MonthlyTable from './MonthlyTable';
import chevronDown from '../../assets/chevron-down.svg';
import calendarIcon from '../../assets/calendar-icon.svg';
import {
  SmileOutlined,
  RedoOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  Table,
  Modal,
  Button,
  ConfigProvider,
  Select,
  DatePicker,
  Space,
} from 'antd';

const { confirm } = Modal;

const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center' }}>
    <SmileOutlined style={{ fontSize: 20 }} />
    <p>Data Not Found</p>
  </div>
);

export default function Coaches({
  activeRender,
  allUserRender,
}: {
  activeRender?: boolean,
  allUserRender?: boolean,
}) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    store.dispatch(coachActions.fetch());
  }, []);

  const { Option } = Select;

  const [showDirectory, setShowDirectory] = React.useState(true);
  const [reports, setReports] = React.useState({});
  //const [scheduledCounts, setScheduledCounts] = React.useState(0);
  const [reportMonthSelect, setReportMonthSelect] = React.useState('');

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const coachUsers = useSelector((state: State) => {
    return state.coachUsers;
  });

  const coaches = coachUsers.list.map((id) => {
    return coachUsers.byId[id];
  });

  const search = useSelector((state: State) => {
    return state.search;
  });

  let filteredReport = {
    columns: reports?.columns,
    rows: reports?.rows?.filter((row) => {
      let searchTerm = search?.byId['coachUserAttendance']?.searchTerm;
      if (searchTerm) {
        return row['Coach'].toLowerCase().includes(searchTerm.toLowerCase());
      }
      return row;
    }),
  };

  let filteredReportRowsSet = filteredReport?.rows
    ? new Set(filteredReport.rows.map((x) => x['Coach']))
    : new Set();
  let filteredReportLength = filteredReportRowsSet.size;

  let reportsRowsSet = reports?.rows
    ? new Set(reports.rows.map((x) => x['Coach']))
    : new Set();
  let reportsLength = reportsRowsSet.size;

  let filteredList = filterList(search.byId['coachUser'], coaches);
  let activeUsersList = [];
  let InactiveUsersList = [];
  if (currentUser) {
    activeUsersList = filterUsers(filteredList, 'Active', currentUser.agency);
    InactiveUsersList = filterUsers(
      filteredList,
      'Deleted',
      currentUser.agency
    );
  }

  const openModal = function (user: CoachUser, action: string) {
    confirm({
      title: `Are you sure you want to ${action} ${user.firstName} ${user.lastName}?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: `Yes, ${action} Coach`,
      onOk() {
        if (action === 'Deactivate') {
          dispatch(coachActions.delete(user));
        } else {
          dispatch(
            coachActions.upsert(
              Object.assign({}, user, {
                deleted: null,
              })
            )
          );
        }
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'COACH',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      width: '30%',
      ...getSortProps('name'),
    },
    {
      title: 'ADDRESS',
      dataIndex: 'address',
      key: 'address',
      ...getSortProps('address'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (id) => {
        let userDeleted = coachUsers.byId[id].deleted;
        if (!userDeleted) {
          return (
            <div className={styles.options}>
              <AddCoach user={coachUsers.byId[id]} />
              <Button
                danger
                type="link"
                onClick={() => openModal(coachUsers.byId[id], 'Deactivate')}
              >
                <img src={deleteIcon} alt="pencil" width="25" height="25" />
              </Button>
            </div>
          );
        } else {
          return (
            <div className={styles.options}>
              <Button
                // type="primary"
                type="link"
                onClick={() => openModal(coachUsers.byId[id], 'Reactivate')}
              >
                <RedoOutlined />
              </Button>
            </div>
          );
        }
      },
    },
  ];

  if (!currentUserPresenter.isAdminUserType()) {
    // Don't show User the edit/delete options
    columns.pop();
  }

  const renderUsers = () => {
    if (allUserRender) {
      return activeUsersList.concat(InactiveUsersList);
    } else if (activeRender) {
      return activeUsersList;
    } else {
      return InactiveUsersList;
    }
  };

  const handleChange = (value) => {
    setShowDirectory(!showDirectory);
  };

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <div className={styles.reportHeader}>
          <div className={styles.left}>
            <SearchComponent
              id={showDirectory ? 'coachUser' : 'coachUserAttendance'}
              partial={
                showDirectory ? filteredList.length : filteredReportLength
              }
              total={showDirectory ? coaches.length : reportsLength}
            ></SearchComponent>
          </div>
          <div className={styles.center}>
            <span className="dropdown-label">View</span>
            <Select
              aria-label="view selection"
              defaultValue="directory"
              className="dropdown-select"
              onChange={handleChange}
              suffixIcon={
                <img src={chevronDown} alt="chevron" width="30" height="30" />
              }
            >
              <Option value="directory">Directory</Option>
              <Option value="attendance">Attendance</Option>
            </Select>
          </div>
          <div className={styles.right}>
            <Space direction="vertical" style={{ marginBottom: '15px' }}>
              <div className="report-select">
                <p className="report-date-text">{reportMonthSelect}</p>
                <DatePicker
                  aria-label="date picker"
                  onChange={async (date, dateString) => {
                    const start = moment(dateString, 'MMMM YYYY')
                      .startOf('month')
                      .format('YYYY-MM-DD');
                    const end = moment(dateString, 'MMMM YYYY')
                      .endOf('month')
                      .format('YYYY-MM-DD');
                    if (!dateString) {
                      setReports({ rows: [] });
                    } else {
                      const data = await getCoachesReport(start, end);
                      setReports(data);
                      //setScheduledCounts(data['rows'].length);
                    }
                    setReportMonthSelect(dateString);
                  }}
                  bordered={false}
                  format={'MMMM, YYYY'}
                  picker="month"
                  suffixIcon={
                    <img
                      src={calendarIcon}
                      alt="calendar"
                      width="20"
                      height="20"
                    />
                  }
                />
              </div>
            </Space>
          </div>
        </div>
        {showDirectory ? (
          <>
            <ConfigProvider
              renderEmpty={activeUsersList.length > 0 && customizeRenderEmpty}
            >
              <Table dataSource={renderUsers()} columns={columns} />
            </ConfigProvider>
          </>
        ) : (
          <MonthlyTable
            reports={filteredReport}
            scheduled={filteredReportLength}
          />
        )}
      </div>
    </div>
  );
}
