// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import type { State } from '../../general/types';
import ScheduleHeader from '../../schedule/schedule-header/ScheduleHeader';
import CoachCard from '../coach-card/CoachCard';
import { UserTypesEnum } from '../../utilities/userTypesEnum';
import { Redirect } from 'react-router';
import './CoachSchedule.scss';
import { getScheduleTypeClass } from '../../utilities/getScheduleTypeStyles';

export default function CoachSchedule() {
  const schedule = useSelector((state: State) => {
    return state.schedule;
  });

  const redirect = useSelector((state: State) => {
    if (
      state.auth.currentUser &&
      state.auth.currentUser.userType !== UserTypesEnum.COACH &&
      state.auth.currentUser.userType !== UserTypesEnum.TRANSPORTER
    ) {
      return '/login';
    }

    return '';
  });

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div>
      <div className="header">
        <ScheduleHeader />
      </div>
      <div
        style={getScheduleTypeClass(schedule)}
        className={'coach-card-start'}
      >
        <div className="center">
          <CoachCard />
        </div>
      </div>
    </div>
  );
}
