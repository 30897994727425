// @flow
import React from 'react';
import './Scheduler.scss';
import ParticipantList from '../participant-list/ParticipantList';
import CoachList from '../coach-list/CoachList';
import { DragDropContext } from 'react-beautiful-dnd';
import store from '../../general/store';
import { fetchBasicSchedule, fetchSchedule } from '../../slices/scheduleSlice';
import { fetchSkills } from '../../slices/skillSlice';
import {
  deleteParticipant,
  moveParticipant,
  postParticipant,
  prePatchParticipant,
} from '../../slices/participantSlice';
import ScheduleHeader from '../schedule-header/ScheduleHeader';
import { MONDAY } from '../../utilities/masterDatesEnum';
import { locationRequests } from '../../slices/locationSlice';
import { ParticipantStatusTypesEnum } from '../../utilities/participantStatusTypesEnum';
import { getToday } from '../../utilities/getToday';

export default function Scheduler({ isMaster }: { isMaster?: boolean }) {
  React.useEffect(() => {
    store.dispatch(fetchSkills());
    store.dispatch(locationRequests.fetch());

    if (isMaster) {
      store.dispatch(
        fetchSchedule({
          scheduleType:
            parseInt(localStorage.getItem('scheduleType')) || undefined,
          program: parseInt(localStorage.getItem('program') || 0),
          date: localStorage.getItem('master_date') || MONDAY,
        })
      );
    } else {
      store.dispatch(
        fetchSchedule({
          scheduleType:
            parseInt(localStorage.getItem('scheduleType')) || undefined,
          program: parseInt(localStorage.getItem('program') || 0),
          date: localStorage.getItem('schedule_date') || getToday(),
        })
      );
    }

    const ping = setInterval(function () {
      store.dispatch(fetchBasicSchedule());
    }, 30000);

    return function cleanup() {
      clearInterval(ping);
    };
  }, [isMaster]);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    let participantId = result.draggableId.replace('participant-', '');
    const sourceId = parseInt(result.source.droppableId.split('_')[1]);
    const destinationId = parseInt(
      result.destination.droppableId.split('_')[1]
    );
    if (result.source.droppableId === result.destination.droppableId) {
      return;
    }

    let status = null;
    // Add and remove off status when moving to and from the Participants Off list
    if (result.destination.droppableId.startsWith('participantDroppable')) {
      switch (result.destination.droppableId) {
        case 'participantDroppable_0':
          status = ParticipantStatusTypesEnum.SCHEDULED;
          break;
        case 'participantDroppableOff_0':
          status = ParticipantStatusTypesEnum.OFF;
          break;
        case 'participantDroppableOff_1':
          status = ParticipantStatusTypesEnum.CALLED_OUT;
          break;
        case 'participantDroppableOff_2':
          status = ParticipantStatusTypesEnum.NO_SHOW;
          break;
        default:
          status = ParticipantStatusTypesEnum.SCHEDULED;
      }
    } else if (
      result.source.droppableId.startsWith('participantDroppable') &&
      result.destination.droppableId.startsWith('coachDroppable')
    ) {
      status = ParticipantStatusTypesEnum.SCHEDULED;
    }

    if (
      result.source.droppableId.startsWith('participantDroppable') &&
      result.destination.droppableId.startsWith('coachDroppable')
    ) {
      await store.dispatch(
        postParticipant(participantId, destinationId, status, isMaster)
      );
    } else if (
      result.source.droppableId.startsWith('coachDroppable') &&
      result.destination.droppableId.startsWith('coachDroppable')
    ) {
      await store.dispatch(
        moveParticipant(participantId, sourceId, destinationId, isMaster)
      );
    } else if (
      result.source.droppableId.startsWith('coachDroppable') &&
      result.destination.droppableId.startsWith('participantDroppable')
    ) {
      await store.dispatch(
        deleteParticipant(participantId, sourceId, status, isMaster)
      );
    } else if (
      result.source.droppableId.startsWith('participantDroppable') &&
      result.destination.droppableId.startsWith('participantDroppable')
    ) {
      await store.dispatch(
        prePatchParticipant({
          id: participantId,
          status: status,
          isMaster: isMaster,
        })
      );
    }
  };

  return (
    <div className="scheduler">
      <div className="sub-header">
        <ScheduleHeader isMaster={isMaster} />
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="left-side">
          <ParticipantList isMaster={isMaster} />
        </div>
        <div className="right-side">
          <CoachList isMaster={isMaster} />
        </div>
      </DragDropContext>
    </div>
  );
}
