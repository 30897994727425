// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.scss';
import { Drawer } from 'antd';
import { useSelector } from 'react-redux';
import type { State } from '../../general/types';
import type { AuthState } from '../../slices/authSlice';

export default function Menu({
  menuVisibility,
  setVisible,
}: {
  menuVisibility: Boolean,
  setVisible: (boolean) => void,
}) {
  const onClose = () => {
    setVisible(false);
  };

  const auth: AuthState = useSelector((state: State) => state.auth);

  return (
    <Drawer
      title=""
      placement="right"
      closable={false}
      onClose={onClose}
      id="flyoutMenu"
      width="304px"
      visible={menuVisibility}
    >
      <div className="agency">{auth.currentUser.agency.name}</div>
      <h2>
        <Link to="/login">Logout</Link>
      </h2>
    </Drawer>
  );
}
