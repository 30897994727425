// @flow
import React from 'react';
import styles from '../directory/list-page/ListPage.module.scss';
import dashboardStyles from '../directory/Directory.module.scss';
import './Search.scss';
import moment from 'moment';
import SearchInput from './SearchInput';
import { getAllSchedulesByDate } from '../slices/scheduleSlice';
import { getSortProps } from '../utilities/getSortProps';
import calendarIcon from '../assets/calendar-icon.svg';
import { Table, DatePicker, Radio } from 'antd';

export default function Search() {
  const [radioValue, setRadioValue] = React.useState('Coaches');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [usersData, setUsersData] = React.useState();
  const [scheduleDate, setScheduleDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    fetchData(moment().format('YYYY-MM-DD'), 'Coaches');
  }, []);

  const fetchData = async (date, type) => {
    const res = await getAllSchedulesByDate(date, type);
    setUsersData(res.data.rows);
  };

  const toggleOpen = (open) => {
    setIsOpen(open);
  };

  const onScheduleDateChange = (value) => {
    const formattedValue = moment(value).format('YYYY-MM-DD');
    setScheduleDate(formattedValue);
    fetchData(formattedValue, radioValue);
  };

  const filterData = (list: any[]): any[] => {
    if (searchTerm === '') {
      return list;
    }
    const searchValue = searchTerm.toLowerCase();
    let filteredList = list.slice();
    if (searchValue) {
      filteredList = usersData.filter((item) => {
        return item.name.toLowerCase().includes(searchValue);
      });
    }
    return filteredList;
  };

  let filteredData = filterData(usersData);

  const coachesColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      ...getSortProps('name'),
      width: '30%',
    },
    {
      title: 'Program',
      dataIndex: 'program',
      key: 'program',
      ...getSortProps('program'),
    },
    {
      title: 'Schedule Type',
      dataIndex: 'scheduleType',
      key: 'scheduleType',
      ...getSortProps('scheduleType'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ...getSortProps('status'),
    },
  ];

  const participantsColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      ...getSortProps('name'),
      width: '30%',
    },
    {
      title: 'Coach',
      dataIndex: 'coach',
      key: 'coach',
      ...getSortProps('coach'),
    },
    {
      title: 'Program',
      dataIndex: 'program',
      key: 'program',
      ...getSortProps('program'),
    },
    {
      title: 'Schedule Type',
      dataIndex: 'scheduleType',
      key: 'scheduleType',
      ...getSortProps('scheduleType'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ...getSortProps('status'),
    },
  ];

  const radioValueOptions = [
    { label: 'Coaches', value: 'Coaches' },
    { label: 'Participants', value: 'Participants' },
  ];

  const onRadioValueChange = ({ target: { value } }: RadioChangeEvent) => {
    setRadioValue(value);
    fetchData(scheduleDate, value);
  };

  const searchTermChange = (value) => {
    setSearchTerm(value);
  };

  const renderUsers = () => {
    return filteredData;
  };

  return (
    <div>
      <div className={dashboardStyles.main}></div>
      <div className="main">
        <div className="content">
          <div className="reportHeader">
            <div className="left-section">
              <div className="calendar">
                <button
                  onClick={() => toggleOpen(true)}
                  className="date-button"
                >
                  {moment(scheduleDate).format('MMM Do')}
                </button>
                <DatePicker
                  allowClear={false}
                  onOpenChange={toggleOpen}
                  onChange={onScheduleDateChange}
                  bordered={false}
                  value={moment(scheduleDate, 'YYYY/MM/DD')}
                  format={'MMM Do'}
                  open={isOpen}
                  suffixIcon={
                    <img
                      src={calendarIcon}
                      alt="calendar"
                      width="20"
                      height="20"
                    />
                  }
                />
              </div>
              <Radio.Group
                options={radioValueOptions}
                onChange={onRadioValueChange}
                value={radioValue}
                optionType="button"
                buttonStyle="solid"
              />
              <SearchInput onSearchTermChange={searchTermChange}></SearchInput>
            </div>
            <div className={styles.center}></div>
          </div>
          <Table
            dataSource={renderUsers()}
            columns={
              radioValue === 'Coaches' ? coachesColumns : participantsColumns
            }
          />
        </div>
      </div>
    </div>
  );
}
