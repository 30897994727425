// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Input } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import './LoginForm.scss';
import { login, fetchUser } from '../../slices/authSlice';
import store from '../../general/store';
import { UserTypesEnum } from '../../utilities/userTypesEnum';

export default function LoginForm() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  let history = useHistory();

  let handleLogin = (values) => {
    dispatch(login(values)).then(() => {
      dispatch(fetchUser()).then(() => {
        const state = store.getState();
        if (
          state.auth.currentUser &&
          (state.auth.currentUser.userType === UserTypesEnum.COACH ||
            state.auth.currentUser.userType === UserTypesEnum.TRANSPORTER)
        ) {
          history.push('/coach-dashboard/schedule');
        } else {
          history.push('/schedule/scheduler');
        }
      });
    });
  };

  return (
    <Form onFinish={handleLogin} className="login-form" form={form}>
      <label htmlFor="email">Email</label>
      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input type="text" autoComplete="email" placeholder="Email" />
      </Form.Item>
      <label htmlFor="password">Password</label>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
          id="password"
          type="password"
          autoComplete="current-password"
          placeholder="Password"
        />
      </Form.Item>
      <div className="buttons">
        <Link to="/forgot_password">Forgot Password?</Link>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Login
        </Button>
      </div>
    </Form>
  );
}
