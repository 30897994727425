// @flow
import React from 'react';
import styles from '../../directory/list-page/ListPage.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import type { State } from '../../general/types';
import UserPresenter from '../../presenters/UserPresenter';
import { Table, Button, Modal, ConfigProvider } from 'antd';
import { filterList, filterEvents } from '../../slices/searchSlice';
import SearchComponent from '../../directory/search-component/SearchComponent';
import { getSortProps } from '../../utilities/getSortProps';
import AddEventType from './add-eventTypes/AddEventTypes';
import { eventTypeActions } from '../../slices/eventTypeSlice';
import store from '../../general/store';
import deleteIcon from '../../assets/remove.svg';
import { SmileOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center' }}>
    <SmileOutlined style={{ fontSize: 20 }} />
    <p>Data Not Found</p>
  </div>
);

const { confirm } = Modal;

export default function EventType() {
  React.useEffect(() => {
    store.dispatch(eventTypeActions.fetch());
  }, []);
  const dispatch = useDispatch();

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });

  const currentUserPresenter = new UserPresenter(currentUser);
  const search = useSelector((state: State) => {
    return state.search;
  });

  const eventList = useSelector((state: State) => {
    return state.event_types;
  });

  const events = eventList.list.map((id) => {
    return eventList.byId[id];
  });

  let filteredList = filterList(
    search.byId['eventList'],
    events,
    false,
    false,
    false,
    true
  );
  filteredList = filteredList.map((event) => new UserPresenter(event));
  let customEventList = [];
  let systemEventList = [];
  if (currentUser) {
    customEventList = filterEvents(filteredList, 'Custom', currentUser.agency);
    systemEventList = filterEvents(filteredList, 'Default', currentUser.agency);
  }

  const openModal = function (event: any) {
    confirm({
      title: `Are you sure you want to delete ${event.event}?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        dispatch(eventTypeActions.delete(event));
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'EVENT TYPES',
      dataIndex: 'event',
      key: 'event',
      ...getSortProps('event'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (id) => {
        let eventSystemDefault = eventList.byId[id].agency;
        if (eventSystemDefault) {
          return (
            <div className={styles.options}>
              <AddEventType event={eventList.byId[id]} />
              <Button type="link" onClick={() => openModal(eventList.byId[id])}>
                <img src={deleteIcon} alt="pencil" width="25" height="25" />
              </Button>
            </div>
          );
        } else {
          return '';
        }
      },
    },
  ];

  if (!currentUserPresenter.isAdminUserType()) {
    // Don't show User the edit/delete options
    columns.pop();
  }

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <SearchComponent
          id="eventList"
          partial={filteredList.length}
          total={events.length}
        ></SearchComponent>
        <ConfigProvider
          renderEmpty={customEventList.length > 0 && customizeRenderEmpty}
        >
          <Table
            dataSource={customEventList}
            columns={columns}
            title={() => 'Custom Event Types'}
          />
        </ConfigProvider>
        <ConfigProvider
          renderEmpty={systemEventList.length > 0 && customizeRenderEmpty}
        >
          <Table
            dataSource={systemEventList}
            columns={columns}
            title={() => 'System Default Event Types'}
          />
        </ConfigProvider>
      </div>
    </div>
  );
}
