import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultReducers } from './defaultReducers';
import type { Location } from './locationSlice';
import type { Skill } from './skillSlice';
import axios from 'axios';
import { fetchBasicSchedule, scheduleSliceActions } from './scheduleSlice';

export interface Coach {
  id: number;
  firstName: string;
  lastName: string;
}

export interface ScheduleCoach {
  id: number;
  user: Coach;
  status: string;
  note: string;
  locationStart: Location;
  locationEnd: Location;
  skill: Skill;
  scheduledParticipants: number[];
  transportationType: number;
}

export interface CoachState {
  loading: boolean;
  error: Error;
  list: number[];
  byId: { number: ScheduleCoach };
  searchStatus: string;
}

const initialState: CoachState = {
  loading: false,
  error: null,
  list: [],
  byId: {},
  searchStatus: '',
};

const coaches = createSlice({
  name: 'coaches',
  initialState,
  reducers: {
    getStart: defaultReducers.getStart,
    getSuccess: defaultReducers.getSuccess,
    getFailure: defaultReducers.getFailure,
    normalizeList: defaultReducers.normalizeList,
    updateCoach(state: CoachState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = '';

      const scheduledCoach = state.byId[action.payload.id];
      Object.assign(scheduledCoach, action.payload);
    },
    addParticipantCoach(state: CoachState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = '';

      const scheduledCoach = state.byId[action.payload.coachId];

      if (scheduledCoach) {
        scheduledCoach.scheduledParticipants.splice(
          0,
          0,
          action.payload.participantId
        );
      }
    },
    removeParticipantCoach(state: CoachState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = '';

      const scheduledCoach = state.byId[action.payload.coachId];
      if (scheduledCoach) {
        scheduledCoach.scheduledParticipants.splice(
          scheduledCoach.scheduledParticipants.findIndex(
            (participantId) => participantId === action.payload.participantId
          ),
          1
        );
      }
    },
  },
});

export const {
  getStart,
  getSuccess,
  getFailure,
  normalizeList,
  updateCoach,
  addParticipantCoach,
  removeParticipantCoach,
} = coaches.actions;

export const coachSliceActions = coaches.actions;
export const coachesReducer = coaches.reducer;

export const setCoachStatus = (searchStatus: string) => (dispatch: any) => {
  dispatch(getSuccess({ searchStatus }));
};

export const patchCoach = (coach: ScheduleCoach, isMaster: boolean) => async (
  dispatch: any
) => {
  try {
    dispatch(getStart());
    dispatch(fetchBasicSchedule());
    coach.isMaster = isMaster;
    const updatedCoach = await axios.patch(
      `${process.env.REACT_APP_SERVER_URL || ''}/schedules/scheduled_coach/${
        coach.id
      }/`,
      coach
    );
    dispatch(
      scheduleSliceActions.getSuccess({
        modifiedDate: updatedCoach.data.scheduleModifiedDate,
      })
    );
    dispatch(updateCoach(updatedCoach.data));
  } catch (err) {
    dispatch(getFailure(err));
  }
};

export const getCoachesReport = async (date_from: String, date_to: String) => {
  const report = await axios.get(
    `${
      process.env.REACT_APP_SERVER_URL || ''
    }/aerostar/reports/coaches_by_day?date_from=${date_from}&date_to=${date_to}`
  );
  return report.data;
};

export const getCoachCalendar = async (coach, date_from, date_to) => {
  try {
    const coachCalendar = await axios.get(
      `${
        process.env.REACT_APP_SERVER_URL || ''
      }/schedules/coach_calendar/${coach}/${date_from}/${date_to}/`
    );
    return coachCalendar.data;
  } catch (error) {
    console.log(error);
  }
};
