import React from 'react';
import { getCoachCalendar } from '../../slices/coachesSlice';
import FullCalendar from '@fullcalendar/react';
import type { State } from '../../general/types';
import { useSelector } from 'react-redux';
import timeGridPlugin from '@fullcalendar/timegrid';
import '../../schedule/coach-calendar/CoachCalendar.scss';
import EventPopover from './EventPopover';
import moment from 'moment';

const CoachCalendar = () => {
  const [fullSchedule, setFullSchedule] = React.useState([]);

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });

  React.useEffect(() => {
    const today = moment().format('YYYY-MM-DD');

    const getDailySchedule = async () => {
      const coachSchedule = await getCoachCalendar(
        currentUser.id,
        today,
        today
      );
      setFullSchedule(coachSchedule);
    };

    getDailySchedule();
  }, [currentUser.id]);

  function renderEventContent(eventInfo) {
    return <EventPopover eventInfo={eventInfo} />;
  }

  return (
    <div>
      <FullCalendar
        plugins={[timeGridPlugin]}
        events={fullSchedule}
        initialView="timeGridDay"
        headerToolbar={{ left: '', center: 'title', right: '' }}
        slotEventOverlap={false}
        nowIndicator={true}
        eventContent={renderEventContent}
        height={'100vh'}
        slotMinTime={'04:00:00'}
        allDaySlot={false}
        eventTimeFormat={{
          hour: 'numeric',
          minute: '2-digit',
          meridiem: true,
        }}
      />
    </div>
  );
};

export default CoachCalendar;
