// @flow
import React from 'react';
import './UserSearch.scss';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import searchIconBlue from '../assets/streamline-icon.svg';
import searchIconWhite from '../assets/search-icon-white.svg';
import { showSearch } from '../slices/searchSlice';
import type { State } from '../general/types';

export default function UserSearch({
  id,
  partial,
  total,
  className,
}: {
  id: string,
  partial: number,
  total: number,
  className?: string,
}) {
  const dispatch = useDispatch();

  const search = useSelector((state: State) => {
    if (!state.search.byId[id]) {
      return false;
    }
    return state.search.byId[id];
  });

  let toggle = () => {
    dispatch(showSearch(id, !search.showSearch));
  };

  return (
    <div className={`user-search ${className || ''}`}>
      <div className={className ? '' : 'participant-count'}>
        {partial} / {total}
      </div>
      <div>
        <Button
          type="link"
          onClick={toggle}
          icon={
            <img
              src={className ? searchIconBlue : searchIconWhite}
              alt="magnifying_glass"
              width="20"
              height="20"
            />
          }
        />
      </div>
    </div>
  );
}
