import React from 'react';
import './ParticipantCardAction.scss';
import type { Participant } from '../../slices/participantSlice';
import { nextState } from '../../slices/participantSlice';
import { useDispatch } from 'react-redux';
import CheckCircleOutlined from '@ant-design/icons/lib/icons/CheckCircleOutlined';
import FormOutlined from '@ant-design/icons/lib/icons/FormOutlined';
import { EventTypesEnum } from '../../utilities/eventTypesEnum';
import { CarOutlined, SendOutlined } from '@ant-design/icons';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';

export default function ParticipantCardAction({
  participant,
}: {
  participant: Participant,
}) {
  const dispatch = useDispatch();

  //Controls the next scheduled participant state
  const applyNextParticipantState = () => {
    dispatch(nextState(participant.id));
  };

  let iconMap = {};
  iconMap[EventTypesEnum.PICKUP] = <CarOutlined />;
  iconMap[EventTypesEnum.ARRIVE] = <SendOutlined />;
  iconMap[EventTypesEnum.CHECK_IN] = <CheckOutlined />;

  function presetActions() {
    //TODO: CSS to make this look good/icons when they are available.
    if (participant.scheduleEventActionType.complete) {
      return (
        <div className="button complete">
          <CheckCircleOutlined />
          {participant.scheduleEventActionType.text}
        </div>
      );
    } else {
      return (
        <button className="button" onClick={applyNextParticipantState}>
          {participant.currentEventType.id === 1 ||
          participant.currentEventType.id === 2 ||
          participant.currentEventType.id === 3 ? (
            iconMap[participant.currentEventType.id]
          ) : (
            <FormOutlined />
          )}
          {participant.scheduleEventActionType.text}
        </button>
      );
    }
  }

  return (
    <div className="participant-action">
      {participant ? presetActions() : <div>Loading Participant</div>}
    </div>
  );
}
