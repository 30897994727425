// @flow
import { createSlice } from '@reduxjs/toolkit';
import { defaultReducers } from './defaultReducers';
import { DefaultRequests } from './defaultRequests';

export interface Document {
  id: number;
  sThreeLocation: string;
}

export interface Vehicle {
  id: number;
  make: string;
  model: string;
  year: string;
  color: string;
  licenseNumber: string;
  docExpiration: string;
  licensePhoto: Document[];
  vehiclePhoto: Document[];
}

export interface CoachUser {
  id: number;
  firstName: string;
  lastName: string;
  note: string;
  work_days: string[];
  workHoursStart: string;
  workHoursEnd: string;
  vehicles: Vehicle[];
  backgroundCheck: Document[];
  immunizationRecords: Document[];
  sendNotificationEmail: Boolean;
}

export interface CoachUserState {
  loading: boolean;
  error: string;
  list: CoachUser[];
  byId: {};
}

const initialState: CoachUserState = {
  loading: false,
  error: '',
  list: [],
  byId: {},
};

const coachUsers = createSlice({
  name: 'coachUsers',
  initialState,
  reducers: defaultReducers,
});

export const coachUserReducer = coachUsers.reducer;
export const coachActions = new DefaultRequests(
  coachUsers.actions,
  `aerostar/coach`
);
