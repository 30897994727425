import type { Location } from '../slices/locationSlice';
import { formatAddress } from '../utilities/formatAddress';

export default class LocationPresenter {
  location: Location;

  constructor(location) {
    this.location = location;
  }

  get id() {
    return this.location.id;
  }

  get name() {
    return this.location.name;
  }

  get phoneNumber() {
    return this.location.phoneNumber;
  }

  get address() {
    if (!this.location.address) {
      return '';
    }
    return formatAddress(this.location);
  }

  get key() {
    return this.location.id;
  }
}
