// @flow
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserPresenter from '../presenters/UserPresenter';
import type { Agency } from './authSlice';

interface Search {
  searchTerm: string;
  showSearch: boolean;
}

export interface SearchState {
  byId: any;
}

const initialState: SearchState = {
  byId: {},
};

const participantUsers = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateId: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.byId[action.payload.id] = state.byId[action.payload.id] || {};
        Object.assign(state.byId[action.payload.id], action.payload);
      }
    },
  },
});

export const searchActions = participantUsers.actions;
export const searchReducer = participantUsers.reducer;

export const setSearchTerm = (id: string, searchTerm: string) => (
  dispatch: any
) => {
  dispatch(searchActions.updateId({ id: id, searchTerm }));
};

export const showSearch = (id: string, showSearch: string) => (
  dispatch: any
) => {
  dispatch(searchActions.updateId({ id: id, showSearch }));
};

export const filterList = (
  search: Search,
  list: any[],
  skill?: boolean,
  program?: boolean,
  scheduleType?: boolean,
  eventType?: boolean
): any[] => {
  if (!search || !search.searchTerm) {
    return list;
  }
  const searchTerm = search.searchTerm.toLowerCase();
  let filteredList = list.slice();
  if (searchTerm) {
    filteredList = filteredList.filter((item) => {
      if (program) {
        return item.name.toLowerCase().includes(searchTerm);
      } else if (scheduleType) {
        return item.type.toLowerCase().includes(searchTerm);
      } else if (skill) {
        return item.skill.toLowerCase().includes(searchTerm);
      } else if (eventType) {
        return item.event.toLowerCase().includes(searchTerm);
      } else {
        return (
          (item.firstName + ' ' + item.lastName)
            .toLowerCase()
            .includes(searchTerm) ||
          (item.lastName + ' ' + item.firstName)
            .toLowerCase()
            .includes(searchTerm)
        );
      }
    });
  }
  return filteredList;
};

export const filterUsers = (
  list: any[],
  filterAction: string,
  agency: Agency
) => {
  if (list.length > 0) {
    if (filterAction === 'Deleted') {
      list = list.filter((s) => s.deleted);
    } else if (filterAction === 'Active') {
      list = list.filter((s) => s.deleted == null);
    }
    if (agency === undefined && agency === null)
      list = list.map((user) => new UserPresenter(user));
    else list = list.map((user) => new UserPresenter(user, agency));
  }
  return list;
};

export const filterEvents = (
  list: any[],
  filterAction: string,
  agency: Agency
) => {
  if (list.length > 0) {
    if (filterAction === 'Custom') {
      list = list.filter((s) => s.user.agency);
    } else if (filterAction === 'Default') {
      list = list.filter((s) => s.user.agency == null);
    }
    if (agency === undefined && agency === null)
      list = list.map((user) => new UserPresenter(user));
    else list = list.map((user) => new UserPresenter(user, agency));
  }
  return list;
};
