import { UserTypesEnum } from '../utilities/userTypesEnum';
import type { Agency, CurrentUser } from '../slices/authSlice';
import { formatAddress } from '../utilities/formatAddress';
import moment from 'moment';

export default class UserPresenter {
  user: CurrentUser;
  agency: Agency;

  constructor(user, agency) {
    this.user = user;
    this.agency = agency;
  }

  get skill() {
    return this.user.name;
  }

  get id() {
    return this.user.id;
  }

  get type() {
    if (this.user.deleted !== null) {
      return `${this.user.type} (Deactivated)`;
    } else {
      return this.user.type;
    }
  }

  get startTime() {
    return moment(this.user.startTime, 'HH:mm:ss').format('h:mm a');
  }

  get endTime() {
    return moment(this.user.endTime, 'HH:mm:ss').format('h:mm a');
  }

  get email() {
    return this.user.email;
  }

  get userType() {
    let userType = '';
    switch (this.user.userType) {
      case 1:
        userType = 'Participant';
        break;
      case 2:
        userType = 'Coach';
        break;
      case 3:
        userType = 'User';
        break;
      case 4:
        userType = 'Admin';
        break;
      case 5:
        userType = 'Transporter';
        break;
      default:
        userType = undefined;
    }
    return userType;
  }

  get icon() {
    return this.user.icon;
  }

  get name() {
    if (this.user.deleted) {
      return `${this.user.lastName}, ${this.user.firstName} (Deactivated)`;
    }
    return `${this.user.lastName}, ${this.user.firstName}`;
  }

  get event() {
    return this.user.event;
  }

  get address() {
    if (this.user.addresses) {
      return formatAddress(this.user.addresses[0]);
    }
    return '';
  }

  get program() {
    return this.user.name;
  }

  get encounter() {
    return this.user.name;
  }

  get key() {
    return this.user.id;
  }

  get uciNumber() {
    return this.user.uciNumber;
  }
  isDeleted() {
    return this.user.deleted ? true : false;
  }
  get zone() {
    let zone = this.agency.zones.find((zone) => {
      return zone.id === this.user.zone;
    });
    if (zone) {
      return zone.zone;
    }
    return '';
  }

  firstLastNames() {
    if (this.user.firstName && this.user.lastName) {
      return this.user.firstName + ' ' + this.user.lastName;
    }

    return '';
  }

  initials() {
    let initials = '';
    if (this.user.firstName && this.user.lastName) {
      initials = this.user.firstName[0] + this.user.lastName[0];
    }
    return initials.toUpperCase();
  }

  isCoachUserType() {
    return (
      this.user &&
      (this.user.userType === UserTypesEnum.COACH ||
        this.user.userType === UserTypesEnum.TRANSPORTER)
    );
  }

  isUserUserType() {
    return this.user && this.user.userType === UserTypesEnum.USER;
  }

  isAdminUserType() {
    return this.user && this.user.userType === UserTypesEnum.ADMIN;
  }
}
