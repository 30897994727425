// @flow
import { createSlice } from '@reduxjs/toolkit';
import { defaultReducers } from './defaultReducers';
import axios from 'axios';

export interface GroupTransfer {
  targetKeys: number[];
  selectedKeys: number[];
  enableProgram: boolean;
}

export interface GroupTransferState {
  loading: boolean;
  error: string;
  transfer: ProgramTransfer;
}

const initialState: GroupTransferState = {
  loading: false,
  error: '',
  transfer: { targetKeys: [], selectedKeys: [], enableGroup: true },
};

const groupTransfer = createSlice({
  name: 'groupTransfer',
  initialState,
  reducers: {
    getStart: defaultReducers.getStart,
    getSuccess: defaultReducers.getSuccess,
    getFailure: defaultReducers.getFailure,
  },
});

export const { getStart, getSuccess, getFailure } = groupTransfer.actions;

export const groupTransferReducer = groupTransfer.reducer;

export const updateGroupTransfer = (transferData: any) => async (
  dispatch: any
) => {
  try {
    dispatch(getStart());
    dispatch(getSuccess({ transfer: transferData }));
  } catch (err) {
    dispatch(getFailure(err));
  }
};

export const assignCoachesToGroup = (coachList: any, groupID: any) => async (
  dispatch: any
) => {
  try {
    await axios.post(
      `${
        process.env.REACT_APP_SERVER_URL || ''
      }/aerostar/user_groups/${groupID}/assign_coaches/`,
      coachList
    );
  } catch (err) {
    dispatch(getFailure(err));
  }
};
