// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import type { State } from '../general/types';
import styles from './Transport.module.scss';

export default function Transport({
  participantId,
}: {
  participantId: number,
}) {
  const participant = useSelector((state: State) => {
    return state.participants.byId[participantId];
  });

  return (
    <div className={styles.transport}>
      {participant && participant.transportationType
        ? participant.transportationType.name
        : 'Standard Transport'}
    </div>
  );
}
