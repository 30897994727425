export const MONDAY = '1900-01-01';

export const MASTER_DATES = {
  '1900-01-01': 'Monday',
  '1900-01-02': 'Tuesday',
  '1900-01-03': 'Wednesday',
  '1900-01-04': 'Thursday',
  '1900-01-05': 'Friday',
  '1900-01-06': 'Saturday',
  '1900-01-07': 'Sunday',
};
