// @flow
import React from 'react';
import '../../Modal.scss';
import styles from '../../Directory.module.scss';
import { Button, Form, Input, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import AddressForm from '../../adddress-form/AddressForm';
import { locationRequests, Location } from '../../../slices/locationSlice';
import editIcon from '../../../assets/edit-icon.svg';
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined';
const { TextArea } = Input;

export default function AddLocation({ location }: { location?: Location }) {
  const [submitting, setSubmitting] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const initialValues = Object.assign({}, location);
  if (location) {
    initialValues.addresses = [
      {
        address: location.address,
        addressTwo: location.addressTwo,
        city: location.city,
        state: location.state,
        zip: location.zip,
      },
    ];
  }

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleOk = (e) => {
    hideModal();
  };

  const handleCancel = (e) => {
    hideModal();
  };

  let formSubmit = async (values) => {
    setSubmitting(true);
    const result = await dispatch(
      locationRequests.upsert(
        Object.assign({}, values, values.addresses[0], { addresses: undefined })
      )
    );
    if (result) {
      hideModal();
      form.resetFields();
    }
    setSubmitting(false);
  };

  return (
    <div>
      {location ? (
        <Button type="link" onClick={showModal}>
          <img src={editIcon} alt="pencil" width="25" height="25" />
        </Button>
      ) : (
        <button className={styles.teammate} onClick={showModal}>
          <div>
            <PlusCircleOutlined className="plus-icon-dashboard" />
            <p className="add-text-align">ADD LOCATION</p>
          </div>
        </button>
      )}
      <Modal
        width="900px"
        title={location ? 'Edit Location' : 'Add Location'}
        visible={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          onFinish={formSubmit}
          form={form}
          initialValues={initialValues}
        >
          <div className="split">
            <div>
              <Form.Item name="id" hidden={true}>
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="name"
                label="Business Name"
                rules={[
                  { required: true, message: 'Please Input a Business Name!' },
                ]}
              >
                <Input type="text" placeholder="Business Name" />
              </Form.Item>

              <AddressForm />

              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                rules={[
                  { required: true, message: 'Please Input a Phone Number!' },
                ]}
              >
                <Input
                  type="tel"
                  placeholder="Phone Number"
                  pattern="^[0-9-+\s()]*$"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item name="note" label="Notes">
                <TextArea rows={4} />
              </Form.Item>
            </div>
          </div>

          <div className="buttons">
            <Button type="primary" htmlType="submit" disabled={submitting}>
              {location ? 'Update Location' : 'Create New Location'}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
