// @flow
import React from 'react';
import '../../../directory/Modal.scss';
import styles from '../../../directory/Directory.module.scss';
import { Button, Form, Input, Modal, Select, Collapse } from 'antd';
import { useDispatch } from 'react-redux';
import type { Survey } from '../../../slices/surveySlice';
import editIcon from '../../../assets/edit-icon.svg';
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import { surveyActions } from '../../../slices/surveySlice';
import { Switch } from 'antd';
const { Option } = Select;
const { Panel } = Collapse;

export default function AddSurvey({ survey }: { survey?: Survey }) {
  const [submitting, setSubmitting] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  let initialValues = {};
  if (survey) {
    initialValues = Object.assign({}, survey);
  }

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleOk = (e) => {
    hideModal();
  };

  const handleCancel = (e) => {
    hideModal();
  };

  let formSubmit = async (values) => {
    setSubmitting(true);
    if (values.userType === 4) {
      const result = await dispatch(
        surveyActions.upsert(Object.assign({}, values))
      );
      if (result) {
        form.resetFields();
        hideModal();
      }
    } else {
      if (Object.keys(initialValues).length === 0) {
        const result = await dispatch(
          surveyActions.upsert(Object.assign({}, values))
        );
        if (result) {
          form.resetFields();
          hideModal();
        }
      } else {
        const result = await dispatch(
          surveyActions.upsert(Object.assign({}, values))
        );
        if (result) {
          form.resetFields();
          hideModal();
        }
      }
    }
    setSubmitting(false);
  };

  return (
    <div>
      {survey ? (
        <Button type="link" onClick={showModal}>
          <img src={editIcon} alt="pencil" width="25" height="25" />
        </Button>
      ) : (
        <button className={styles.teammate} onClick={showModal}>
          <div>
            <PlusCircleOutlined className="plus-icon-dashboard" />
            <p className="add-text-align">ADD SURVEY</p>
          </div>
        </button>
      )}
      <Modal
        width="900px"
        title={survey ? 'Update Survey' : 'Add Survey'}
        visible={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          onFinish={formSubmit}
          className="forgot-password"
          form={form}
          initialValues={initialValues}
        >
          <div className="split">
            <Collapse>
              <Panel header="Instructions">
                <ol>
                  <li>
                    Click on the ‘Build New Survey’ link, that will take you to
                    the google forms management page in a new tab. Select a form
                    template to start from.
                  </li>
                  <li>
                    Build the form with the first question being coach name
                    (short answer question), and the second question being
                    participant name (if form is meant for participants). Finish
                    the rest of the form with whatever questions desired.
                  </li>
                  <li>In the top right, click More (3 vertical dots).</li>
                  <li>Choose Get pre-filled link (will open new tab)</li>
                  <li>
                    Fill in the coach name question with the answer “coach” and
                    the participant name question with the answer “participant”
                  </li>
                  <li>Click Get Link at bottom of form</li>
                  <li>
                    Copy the link using the COPY LINK button that will appear.
                    Then paste the link here in the URL field. Complete the rest
                    of this form below.
                  </li>
                </ol>
              </Panel>
            </Collapse>
          </div>
          <div className="buttons">
            <Button
              type="link"
              htmlType="button"
              href="https://docs.google.com/forms/u/0/?tgif=d"
              target="_blank"
            >
              Build New Survey
            </Button>
          </div>
          <div className="split">
            <div>
              <Form.Item name="id" hidden={true}>
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: 'Please Input a title!' }]}
              >
                <Input type="text" placeholder="Title" />
              </Form.Item>
              <Form.Item
                name="url"
                label="URL"
                rules={[
                  {
                    type: 'url',
                    required: true,
                    message: 'Please Input a google form url!',
                  },
                ]}
              >
                <Input type="text" placeholder="Paste Google Form URL here" />
              </Form.Item>
              <Form.Item
                name="surveyType"
                label="Type"
                rules={[
                  { required: true, message: 'Please Select a Survey Type!' },
                ]}
              >
                {Object.keys(initialValues).length === 0 ? (
                  <Select
                    style={{ width: 175 }}
                    placeholder="Type"
                    disabled={false}
                  >
                    <Option value={'COACHES'}>Coaches</Option>
                    <Option value={'PARTICIPANTS'}>Participants</Option>
                    <Option value={'BOTH'}>Both</Option>
                  </Select>
                ) : (
                  <Select
                    style={{ width: 175 }}
                    placeholder="Type"
                    disabled={false}
                  >
                    <Option value={'COACHES'}>Coaches</Option>
                    <Option value={'PARTICIPANTS'}>Participants</Option>
                    <Option value={'BOTH'}>Both</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item name="switch" label="Active" valuePropName="checked">
                {Object.keys(initialValues).length === 0 ? (
                  <Switch title="active" defaultChecked={true} />
                ) : (
                  <Switch
                    title={initialValues.isActive}
                    defaultChecked={initialValues.isActive}
                  />
                )}
              </Form.Item>
            </div>
          </div>
          <div className="buttons">
            <Button type="primary" htmlType="submit" disabled={submitting}>
              {survey ? 'Update Survey' : 'Create New Survey'}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
