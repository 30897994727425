import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import IdleTimer from 'react-idle-timer';
import LoginApp from '../login/LoginApp';

class AutoLogout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeout: 1000 * 5 * 1,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
    };
    this.mounted = false;
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  _onAction() {
    if (this.mounted) {
      this.setState({ isTimedOut: false });
    }
  }

  _onActive() {
    this.setState({ isTimedOut: false });
  }

  _onIdle() {
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      this.props.history.push('/login');
    } else {
      this.setState({ timeout: 1000 * 1800 * 1 }, () => {
        this.idleTimer.reset();
        this.setState({ isTimedOut: true });
      });
    }
  }
  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { match } = this.props;
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />

        <div className="">
          <Switch>
            <Route
              exact
              path={`${match.path}/login`}
              render={(props) => <LoginApp {...props} />}
            />
          </Switch>
        </div>
      </>
    );
  }
}

export default withRouter(AutoLogout);
