// @flow
import React from 'react';
import styles from '../../directory/list-page/ListPage.module.scss';
import { useSelector } from 'react-redux';
import type { State } from '../../general/types';
import UserPresenter from '../../presenters/UserPresenter';
import { Table, Spin } from 'antd';
import { filterList } from '../../slices/searchSlice';
import SearchComponent from '../../directory/search-component/SearchComponent';
// import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
// import CloseCircleOutlined from '@ant-design/icons/lib/icons/CloseCircleOutlined';
import { getSortProps } from '../../utilities/getSortProps';
import AddSkills from '../skills/add-skills/AddSkills';
// import type { Skill } from '../../slices/skillSlice';
// import { skillActions } from '../../slices/skillSlice';
// const { confirm } = Modal;

export default function Skills() {
  // const dispatch = useDispatch();

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });

  const currentUserPresenter = new UserPresenter(currentUser);
  const search = useSelector((state: State) => {
    return state.search;
  });

  const skillLists = useSelector((state: State) => {
    return state.skills;
  });
  const skills = skillLists.list.map((id) => {
    return skillLists.byId[id];
  });

  let filteredList = filterList(
    search.byId['skillLists'],
    skills,
    true,
    false,
    false
  );
  filteredList = filteredList.map((skill) => new UserPresenter(skill));

  // Task to be added later to change the delete portion

  // const openModal = function (skill: Skill) {
  //   confirm({
  //     title: `Are you sure you want to delete ${skill.name}?`,
  //     icon: <ExclamationCircleOutlined />,
  //     content: '',
  //     onOk() {
  //       dispatch(skillActions.delete(skill));
  //     },
  //     onCancel() {},
  //   });
  // };

  const columns = [
    {
      title: 'SKILLS',
      dataIndex: 'skill',
      key: 'skill',
      ...getSortProps('skill'),
    },
    {
      title: 'ICON',
      dataIndex: 'icon',
      key: 'icon',
      ...getSortProps('icon'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (id) => {
        return (
          <div className={styles.options}>
            <AddSkills skill={skillLists.byId[id]} />
            {/*<Button*/}
            {/*  type="link"*/}
            {/*  onClick={() => openModal(skillLists.byId[id])}*/}
            {/*  hidden={true}*/}
            {/*>*/}
            {/*  <CloseCircleOutlined />*/}
            {/*</Button>*/}
          </div>
        );
      },
    },
  ];

  if (!currentUserPresenter.isAdminUserType()) {
    // Don't show User the edit/delete options
    columns.pop();
  }

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <SearchComponent
          id="skillLists"
          partial={filteredList.length}
          total={skills.length}
        ></SearchComponent>
        {currentUser && filteredList.length > 0 ? (
          <Table dataSource={filteredList} columns={columns} />
        ) : (
          <div className={styles.spinner}>
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
}
