// @flow
import React from 'react';
import styles from '../../list-page/ListPage.module.scss';
import './Report.scss';
import moment from 'moment';
import 'moment-duration-format';
import { Table } from 'antd';
import availableIcon from '../../../assets/available-icon.svg';
import checkIcon from '../../../assets/scheduled-check-icon.svg';
import calloutIcon from '../../../assets/callout-icon.svg';
import cancelIcon from '../../../assets/cancel-icon.svg';
import redCrossCircle from '../../../assets/red-circle-cross.svg';

export default function MonthlyTable({
  reports = {
    rows: [],
  },
  scheduled,
  callouts = 0,
  offs = 0,
  unavailable = 0,
}: {
  reports?: any,
  scheduled?: number,
  callouts?: number,
  offs?: number,
  unavailable?: number,
}) {
  // report default global default values for initial computation
  let total = '00:00';
  const scheduledCount = scheduled;
  const calledOutCount = callouts;
  const offCount = offs;
  const unavailableCount = unavailable;

  const patchReportData = (rows) => {
    // Determine date for days needed to add
    let numberOfDays = moment(
      rows[0].ServiceDate.slice(0, 7),
      'YYYY-MM'
    ).daysInMonth();

    // Parse participants as an object by keys --> {participant1: [length = daysInMonth], participant2: [length = daysInMonth], ...}
    let participants = {};
    for (let [index, row] of rows.entries()) {
      if (!participants[row.Participant]) {
        participants[row.Participant] = new Array(numberOfDays);
      }
      let serviceDay = parseInt(row.ServiceDate.slice(8, 10)) - 1;

      if (serviceDay > numberOfDays) {
        continue;
      }

      // use serviceDay as an index for the month array
      if (!participants[row.Participant][serviceDay]) {
        participants[row.Participant][serviceDay] = row;
      } else {
        // if participant has multiple time chunks of same date in report, sum them for that day before renderLogic
        let prevRow = rows[index - 1];
        let prevStoredRowDate =
          participants[row.Participant][serviceDay]['ServiceDate'];
        if (
          prevRow['Participant'] === row['Participant'] &&
          prevStoredRowDate === row['ServiceDate']
        ) {
          let timeInServiceDay = prevRow['HoursServiced'];
          const dayTotal = moment
            .duration(timeInServiceDay)
            .add(row.HoursServiced)
            .format('HH:mm', { trim: false });

          let day = index.toString();
          if (day.length === 1) {
            day = '0' + day;
          }
          participants[row.Participant][serviceDay] = {
            Participant: row.Participant,
            ServiceDate: row.ServiceDate,
            Program: '',
            HoursServiced: dayTotal,
          };
        }
      }
    }

    // Add unserviced days to participants --> {participant1: [{}, {}, {}, ...], participant2: [{}, {}, {}, ...], ...}
    for (let i = 0; i < numberOfDays; i++) {
      for (let participant in participants) {
        if (!participants[participant][i]) {
          let dayCheck = (i + 1).toString();
          if (dayCheck.length === 1) {
            dayCheck = '0' + dayCheck;
          }
          participants[participant][i] = {
            Participant: participant,
            ServiceDate: rows[0].ServiceDate.slice(0, 7) + '/' + dayCheck,
            Program: '',
            HoursServiced: '-----',
          };
        }
      }
    }

    // Ready data for table UI by merging all participant data and return value
    let patchedRows = [];
    for (let participant in participants) {
      patchedRows = patchedRows.concat(participants[participant]);
    }
    return [patchedRows, numberOfDays];
  };

  const addTimeServiced = (row) => {
    total = moment
      .duration(total)
      .add(row['HoursServiced'])
      .format('H:m', { trim: false });
  };

  const formatRenderUITime = (time, totalRender) => {
    if (time === '-----') {
      return <img src={availableIcon} alt="checkmark" width="18" height="18" />;
    }
    let formatTime = moment.duration('00:00').add(time).format('H:m');
    formatTime = formatTime.replace(':', 'h ') + 'm';
    if (formatTime.includes(' 0m') && formatTime.length > 2) {
      formatTime = formatTime.substring(0, formatTime.length - 2);
    }
    if (totalRender) {
      return formatTime;
    } else {
      return <img src={checkIcon} alt="checkmark" width="18" height="18" />;
    }
  };

  const renderReportLogic = (reports) => {
    let rows = reports.rows;
    if (!rows || rows?.length === 0) {
      return '';
    }

    // Patch rows data to include days that were not serviced by the participant
    const rowsLogicInfo = patchReportData(rows);
    rows = rowsLogicInfo[0];
    const numberOfDaysColumn = rowsLogicInfo[1];
    let participantColumnCounter = 1;

    let table_data = {};
    let table_columns = {
      name: {
        title: 'Participant',
        dataIndex: 'Participant',
        key: 'Participant',
        fixed: 'left',
        width: 180,
      },
    };
    for (let i = 0; i < rows.length; i++) {
      let row = rows[i];
      // ServiceDate is in the `YYYY/MM/dd` format and we only want the day
      let dayOffset = 7;
      let date = row['ServiceDate'].slice(dayOffset);
      date = date.replace('/', ' ');

      let name = row['Participant'];

      if (table_data[name]) {
        let renderServiceDayTime = (
          <img src={availableIcon} alt="dashes" width="18" height="18" />
        );
        if (row['HoursServiced'] !== '-----') {
          renderServiceDayTime = formatRenderUITime(row['HoursServiced']);
        }
        table_data[name][date] = renderServiceDayTime;
        addTimeServiced(row);
        table_data[name]['total'] = formatRenderUITime(total, true);
      } else {
        if (row['HoursServiced'] !== '00:00') {
          addTimeServiced(row);
        }
        table_data[name] = {
          key: i,
          total: formatRenderUITime(total, true),
          [date]: formatRenderUITime(row['HoursServiced']),
          Participant: name,
        };
      }

      table_columns[date] = {
        title: date,
        dataIndex: date,
        width: 60,
        key: date,
      };

      // Reset total serviced time sum for new participant
      participantColumnCounter++;
      if (participantColumnCounter - 1 === numberOfDaysColumn) {
        total = '00:00';
        participantColumnCounter = 1;
      }
    }
    // $FlowFixMe[incompatible-type]
    table_columns['total'] = {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      fixed: 'right',
      width: 100,
    };

    return (
      <Table
        dataSource={Object.values(table_data)}
        columns={Object.values(table_columns)}
        scroll={{ x: '100vw' }}
        label="attendance-table"
      />
    );
  };

  return (
    <div className={styles.main}>
      <div className={styles.reportHeaderLegend}>
        <div className={styles.centerLegend}>
          <div>
            <img src={availableIcon} alt="dashes" width="18" height="18" />
            <p>Available</p>
          </div>
          <div>
            <img src={checkIcon} alt="checkmark" width="18" height="18" />
            <p>Scheduled</p>
          </div>
          <div>
            <img src={calloutIcon} alt="exclaimation" width="17" height="17" />
            <p>Called Out</p>
          </div>
          <div>
            <img src={cancelIcon} alt="cancel" width="18" height="18" />
            <p>Scheduled Off</p>
          </div>
        </div>
      </div>
      <div className={styles.reportHeaderLegend}>
        <div className={styles.centerLegendCount}>
          <div>
            <img src={checkIcon} alt="checkmark" width="18" height="18" />
            <p>{scheduledCount}</p>
          </div>
          <div>
            <img src={calloutIcon} alt="exclaimation" width="17" height="17" />
            <p>{calledOutCount}</p>
          </div>
          <div>
            <img src={cancelIcon} alt="cancel" width="18" height="18" />
            <p>{offCount}</p>
          </div>
          <div>
            <img
              src={redCrossCircle}
              alt="redCircleCross"
              width="18"
              height="18"
            />
            <p>{unavailableCount}</p>
          </div>
        </div>
      </div>
      <div name="attendance-report" className="report-container">
        {reports.length === 0 ? '' : renderReportLogic(reports)}
      </div>
    </div>
  );
}
