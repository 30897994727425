// @flow
import React from 'react';
import '../../../directory/Modal.scss';
import styles from '../../../directory/Directory.module.scss';
import { Button, Form, Input, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import type { State } from '../../../general/types';
import UserPresenter from '../../../presenters/UserPresenter';
import editIcon from '../../../assets/edit-icon.svg';
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import { eventTypeActions } from '../../../slices/eventTypeSlice';
import type { EventType } from '../../../slices/eventTypeSlice';

export default function AddEventType({ event }: { event?: EventType }) {
  const [submitting, setSubmitting] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  let initialValues = {};
  if (event) {
    initialValues = Object.assign({}, event);
  }

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleOk = (e) => {
    hideModal();
  };

  const handleCancel = (e) => {
    hideModal();
  };

  let formSubmit = async (values) => {
    setSubmitting(true);

    const result = await dispatch(
      eventTypeActions.upsert(
        Object.assign({}, values, {
          agency: currentUserPresenter.user.agency.id,
        })
      )
    );
    if (result) {
      form.resetFields();
      hideModal();
    }
    setSubmitting(false);
  };

  return (
    <div>
      {event ? (
        <Button type="link" onClick={showModal}>
          <img src={editIcon} alt="pencil" width="25" height="25" />
        </Button>
      ) : (
        <button className={styles.teammate} onClick={showModal}>
          <div>
            <PlusCircleOutlined className="plus-icon-dashboard" />
            <p className="add-text-align">ADD EVENT TYPE</p>
          </div>
        </button>
      )}
      <Modal
        width="900px"
        title={event ? 'Update Event Type' : 'Add Event Type'}
        visible={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          onFinish={formSubmit}
          className="forgot-password"
          form={form}
          initialValues={initialValues}
        >
          <div className="split">
            <div>
              <Form.Item name="id" hidden={true}>
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="event"
                label="Event"
                rules={[
                  {
                    required: true,
                    message: 'Please Input an Event Name!',
                  },
                ]}
              >
                <Input type="text" placeholder="Event" />
              </Form.Item>
            </div>
          </div>

          <div className="buttons">
            <Button type="primary" htmlType="submit" disabled={submitting}>
              {event ? 'Update Event' : 'Create Event Type'}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
