// @flow
import { combineReducers } from 'redux';
import { authReducer } from '../slices/authSlice';
import { coachesReducer } from '../slices/coachesSlice';
import { participantsReducer } from '../slices/participantSlice';
import { scheduleReducer } from '../slices/scheduleSlice';
import { locationReducer } from '../slices/locationSlice';
import { skillReducer } from '../slices/skillSlice';
import { eventTypeReducer } from '../slices/eventTypeSlice';
import { participantUserReducer } from '../slices/participantUserSlice';
import { generalReducer } from '../slices/generalSlice';
import { coachUserReducer } from '../slices/coachUserSlice';
import { searchReducer } from '../slices/searchSlice';
import { userReducer } from '../slices/userSlice';
import { programTransferReducer } from '../slices/transferProgramSlice';
import { programReducer } from '../slices/programSlice';
import { scheduleTypeReducer } from '../slices/scheduleTypeSlice';
import { participantHistoryReducer } from '../slices/participantHistorySlice';
import { encounterTypeReducer } from '../slices/encounterTypeSlice';
import { groupReducer } from '../slices/groupSlice';
import { surveyReducer } from '../slices/surveySlice';

const appReducer = combineReducers({
  schedule: scheduleReducer,
  auth: authReducer,
  users: userReducer,
  participants: participantsReducer,
  locations: locationReducer,
  skills: skillReducer,
  programs: programReducer,
  programTransfer: programTransferReducer,
  coaches: coachesReducer,
  event_types: eventTypeReducer,
  schedule_types: scheduleTypeReducer,
  participantUsers: participantUserReducer,
  participantHistory: participantHistoryReducer,
  coachUsers: coachUserReducer,
  general: generalReducer,
  search: searchReducer,
  encounter_type: encounterTypeReducer,
  groups: groupReducer,
  surveys: surveyReducer,
});

const rootReducer = (state: any, action: any) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'auth/userLogOut') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
