// @flow
import React from 'react';
import styles from '../../directory/list-page/ListPage.module.scss';
import store from '../../general/store';
import { useSelector, useDispatch } from 'react-redux';
import type { State } from '../../general/types';
import UserPresenter from '../../presenters/UserPresenter';
import { Table, Modal, Button, Spin } from 'antd';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import deleteIcon from '../../assets/remove.svg';
import { userActions } from '../../slices/userSlice';
import { surveyActions, Survey } from '../../slices/surveySlice';
import { getSortProps } from '../../utilities/getSortProps';
import AddSurveys from './add-surveys/AddSurvey';
const { confirm } = Modal;

export default function Surveys() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    store.dispatch(userActions.fetch());
    store.dispatch(surveyActions.fetch());
  }, []);

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const surveys = useSelector((state: State) => {
    return state.surveys;
  });

  const surveyList = surveys.list.map((id) => {
    return surveys.byId[id];
  });

  const openModal = function (survey: Survey) {
    confirm({
      title: `Are you sure you want to delete ${survey.title} survey?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        dispatch(surveyActions.delete(survey));
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      ...getSortProps('title'),
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      ...getSortProps('url'),
    },
    {
      title: 'TYPE',
      dataIndex: 'surveyType',
      key: 'surveyType',
      defaultSortOrder: 'ascend',
      ...getSortProps('surveyType'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (id) => {
        return (
          <div className={styles.options}>
            <AddSurveys survey={surveys.byId[id]} />
            <Button
              type="link"
              onClick={() => {
                openModal(surveys.byId[id]);
              }}
            >
              <img src={deleteIcon} alt="delete" width="25" height="25" />
            </Button>
          </div>
        );
      },
    },
  ];

  if (!currentUserPresenter.isAdminUserType()) {
    // Don't show User the edit/delete options
    columns.pop();
  }

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        {currentUser && surveyList.length > 0 ? (
          <Table
            dataSource={surveyList}
            columns={columns}
            rowKey={(survey) => survey.id}
          />
        ) : (
          <div className={styles.spinner}>
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
}
