// @flow
import React from 'react';
import '../../Modal.scss';
import styles from './AddParticipant.module.scss';
import {
  Button,
  Form,
  Input,
  Modal,
  Checkbox,
  Select,
  Tabs,
  Timeline,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { UserTypeValues } from '../../../utilities/userTypeValues';
import type { State } from '../../../general/types';
import UserPresenter from '../../../presenters/UserPresenter';
import AddressForm from '../../adddress-form/AddressForm';
import editIcon from '../../../assets/edit-icon.svg';
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import type { ParticipantUser } from '../../../slices/participantUserSlice';
import {
  participantActions,
  recordParticipantAddressHistory,
} from '../../../slices/participantUserSlice';
import {
  fetchParticipantHistory,
  refreshParticipantHistory,
} from '../../../slices/participantHistorySlice';
import Program from '../../../program/Program';
import { updateProgramTransfer } from '../../../slices/transferProgramSlice';
const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

export default function AddParticipant({
  user,
  setDisabled,
}: {
  user?: ParticipantUser,
  setDisabled?: Boolean,
}) {
  const [submitting, setSubmitting] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const initialValues = Object.assign({}, user);

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const programTransfer = useSelector((state: State) => {
    return state.programTransfer.transfer;
  });

  const participantHistory = useSelector((state: State) => {
    return state.participantHistory.history;
  });

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    dispatch(refreshParticipantHistory());
    setVisible(false);
  };

  const handleOk = (e) => {
    hideModal();
  };

  const handleCancel = (e) => {
    hideModal();
  };

  let formSubmit = async (values) => {
    setSubmitting(true);
    if (user) {
      // Pass no Transfer State
    } else {
      values.program = programTransfer.targetKeys;
    }
    const result = await dispatch(
      participantActions.upsert(
        Object.assign({}, values, {
          userType: UserTypeValues.PARTICIPANT,
        })
      )
    );
    // Historical addresses will only record direct post/patch to root endpoint
    if (values.addresses !== undefined && values.addresses.length !== 0) {
      recordParticipantAddressHistory(Object.assign({}, values.addresses[0]));
    }
    if (result) {
      form.resetFields();
      hideModal();
    }
    dispatch(
      updateProgramTransfer({
        targetKeys: [],
        selectedKeys: [],
      })
    );
    setSubmitting(false);
  };

  const options = [
    { label: 'M', value: 'M' },
    { label: 'Tu', value: 'Tu' },
    { label: 'W', value: 'W' },
    { label: 'Th', value: 'Th' },
    { label: 'F', value: 'F' },
  ];

  const renderHistory = () => {
    return (
      <Timeline>
        {participantHistory.map((history) => (
          <Timeline.Item key={history.id}>
            <p>{history.date}</p>
            <p>{history.createdBy}</p>
            <p>{history.changed.join(', ')}</p>
          </Timeline.Item>
        ))}
      </Timeline>
    );
  };

  const handleTabChange = (value) => {
    if (value === '2') {
      dispatch(fetchParticipantHistory(initialValues.id));
    }
  };

  return (
    <div>
      {user ? (
        <Button type="link" disabled={setDisabled} onClick={showModal}>
          <img src={editIcon} alt="pencil" width="25" height="25" />
        </Button>
      ) : (
        <button className={styles.teammate} onClick={showModal}>
          <div>
            <PlusCircleOutlined className="plus-icon-dashboard" />
            <p className="add-text-align">ADD PARTICIPANT</p>
          </div>
        </button>
      )}
      <Modal
        width="900px"
        title={user ? 'Edit Participant' : 'Add Participant'}
        visible={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <div className="card-container">
          <Tabs type="card" defaultActiveKey="1" onChange={handleTabChange}>
            <TabPane tab="FORM" key="1">
              <Form
                layout="vertical"
                onFinish={formSubmit}
                form={form}
                initialValues={initialValues}
              >
                <div className="split">
                  <div>
                    <Form.Item name="id" hidden={true}>
                      <Input type="text" />
                    </Form.Item>
                    <Form.Item
                      name="firstName"
                      label="First Name"
                      rules={[
                        {
                          required: true,
                          message: 'Please Input a First Name!',
                        },
                      ]}
                    >
                      <Input type="text" placeholder="First Name" />
                    </Form.Item>
                    <Form.Item
                      name="lastName"
                      label="Last Name"
                      rules={[
                        {
                          required: true,
                          message: 'Please Input a Last Name!',
                        },
                      ]}
                    >
                      <Input type="text" placeholder="Last Name" />
                    </Form.Item>
                    <Form.Item
                      name="nickname"
                      label="Nickname"
                      rules={[
                        {
                          required: false,
                          message: 'Please Input a Nickname!',
                        },
                      ]}
                    >
                      <Input type="text" placeholder="Nickname" />
                    </Form.Item>

                    <AddressForm required={false} />
                  </div>
                  <div>
                    <Form.Item
                      name="uciNumber"
                      label="UCI Number"
                      rules={[
                        {
                          required: false,
                          message: 'Please Input the UCI Nubmer!',
                        },
                      ]}
                    >
                      <Input type="text" placeholder="UCI Number" />
                    </Form.Item>
                    <Form.Item
                      name="zone"
                      label="Zone"
                      rules={[
                        { required: false, message: 'Please Select a Zone!' },
                      ]}
                    >
                      <Select style={{ width: 175 }} placeholder="Zone">
                        {currentUserPresenter.user &&
                          currentUserPresenter.user.agency.zones.map((zone) => {
                            return (
                              <Option key={zone.id} value={zone.id}>
                                {zone.zone}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="transportationType"
                      label="Encounter Type"
                      rules={[
                        {
                          required: false,
                          message: 'Please Select an Encounter Type!',
                        },
                      ]}
                    >
                      <Select
                        style={{ width: 175 }}
                        placeholder="Encounter Type"
                      >
                        {currentUserPresenter.user &&
                          currentUserPresenter.user.agency.transportationTypes.map(
                            (transportationType) => {
                              return (
                                <Option
                                  key={transportationType.id}
                                  value={transportationType.id}
                                >
                                  {transportationType.name}
                                </Option>
                              );
                            }
                          )}
                      </Select>
                    </Form.Item>

                    <Form.Item name="note" label="Notes">
                      <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item name="workDays" label="Work Days">
                      <Checkbox.Group options={options} />
                    </Form.Item>
                    <Form.Item
                      label="Program"
                      rules={[
                        { required: true, message: 'Please Select a Program!' },
                      ]}
                    >
                      <Program initial={initialValues} />
                    </Form.Item>
                  </div>
                </div>

                <div className="buttons">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={submitting}
                  >
                    {user ? 'Update Participant' : ' Create New Participant'}
                  </Button>
                </div>
              </Form>
            </TabPane>
            {Object.keys(initialValues).length === 0 ? (
              ''
            ) : (
              <TabPane tab="HISTORY" key="2">
                {renderHistory()}
              </TabPane>
            )}
          </Tabs>
        </div>
      </Modal>
    </div>
  );
}
