// @flow
import React from 'react';
import './UserSearchInput.scss';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import type { State } from '../general/types';
import { setSearchTerm } from '../slices/searchSlice';

export default function UserSearchInput({ id }: { id: string }) {
  const dispatch = useDispatch();
  let setSearchValue = (searchTerm) => {
    dispatch(setSearchTerm(id, searchTerm));
  };

  const searching = useSelector((state: State) => {
    if (!state.search.byId[id]) {
      return false;
    }
    return state.search.byId[id].showSearch;
  });

  return (
    <div className="user-search-input">
      <div>
        {searching ? (
          <Input
            autoFocus
            placeholder="Search"
            onChange={(e) => setSearchValue(e.target.value)}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
