// @flow
import React from 'react';
import '../../../directory/Modal.scss';
import styles from '../../../directory/Directory.module.scss';
import { Button, Form, Input, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import type { State } from '../../../general/types';
import UserPresenter from '../../../presenters/UserPresenter';
import editIcon from '../../../assets/edit-icon.svg';
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import type { Skill } from '../../../slices/skillSlice';
import { skillActions } from '../../../slices/skillSlice';

export default function AddSkills({ skill }: { skill?: Skill }) {
  const [submitting, setSubmitting] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  let initialValues = {};
  if (skill) {
    initialValues = Object.assign({}, skill, {
      skillName: skill.skill,
    });
  }

  const currentUser = useSelector((state: State) => {
    return state.auth.currentUser;
  });
  const currentUserPresenter = new UserPresenter(currentUser);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleOk = (e) => {
    hideModal();
  };

  const handleCancel = (e) => {
    hideModal();
  };

  let formSubmit = async (values) => {
    setSubmitting(true);
    const result = await dispatch(
      skillActions.upsert(
        Object.assign({}, values, {
          name: values.skillName,
          skill: values.skillName,
          icon: values.icon,
          agency: currentUserPresenter.user.agency.id,
        })
      )
    );
    if (result) {
      form.resetFields();
      hideModal();
    }
    setSubmitting(false);
  };

  return (
    <div>
      {skill ? (
        <Button type="link" onClick={showModal}>
          <img src={editIcon} alt="pencil" width="25" height="25" />
        </Button>
      ) : (
        <button className={styles.teammate} onClick={showModal}>
          <div>
            <PlusCircleOutlined className="plus-icon-dashboard" />
            <p className="add-text-align">ADD SKILL</p>
          </div>
        </button>
      )}
      <Modal
        width="900px"
        title={skill ? 'Update Skill' : 'Add Skill'}
        visible={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          onFinish={formSubmit}
          className="forgot-password"
          form={form}
          initialValues={initialValues}
        >
          <div className="split">
            <div>
              <Form.Item name="id" hidden={true}>
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="skillName"
                label="Skill Name"
                rules={[
                  { required: true, message: 'Please Input a Skill Name!' },
                ]}
              >
                <Input type="text" placeholder="Skill Name" />
              </Form.Item>
              <Form.Item
                name="icon"
                label="Icon"
                rules={[
                  { required: true, message: 'Please Input a Icon Label!' },
                ]}
              >
                <Input type="text" placeholder="Icon Label" />
              </Form.Item>
            </div>
          </div>

          <div className="buttons">
            <Button type="primary" htmlType="submit" disabled={submitting}>
              {skill ? 'Update Skill' : 'Create New Skill'}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
