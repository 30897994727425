// @flow
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { defaultReducers } from './defaultReducers';
import store from '../general/store';
import { participantSliceActions } from './participantSlice';
import { coachSliceActions } from './coachesSlice';
import { getToday } from '../utilities/getToday';

export interface ScheduleType {
  id: ?number;
  type: string;
}

export interface ScheduleState {
  id: ?number;
  loading: boolean;
  error: string;
  isOutdated: boolean;
  type: ScheduleType;
  types: ScheduleType[];
  date: string;
  program: number;
}

const initialState: ScheduleState = {
  id: undefined,
  loading: false,
  error: '',
  isOutdated: false,
  type: {
    id: undefined,
    type: '',
  },
  types: [],
  date: getToday(),
  program: 0,
};

const schedule = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    checkOutdated: (state, action: PayloadAction<any>) => {
      if (action.payload.modifiedDate !== state.modifiedDate) {
        state.isOutdated = true;
      }
      state.modifiedDate = action.payload.modifiedDate;
    },
    getStart: defaultReducers.getStart,
    getSuccess: defaultReducers.getSuccess,
    getFailure: defaultReducers.getFailure,
  },
});

export const scheduleSliceActions = schedule.actions;
export const scheduleReducer = schedule.reducer;

export const fetchBasicSchedule = () => async (dispatch: any) => {
  try {
    const scheduleState = store.getState().schedule;
    const scheduleId = scheduleState.id;

    if (!scheduleId) {
      return null;
    }

    const schedule = await axios.get(
      `${
        process.env.REACT_APP_SERVER_URL || ''
      }/schedules/schedules/${scheduleId}/`
    );

    dispatch(scheduleSliceActions.checkOutdated(schedule.data));
  } catch (err) {
    dispatch(scheduleSliceActions.getFailure(err));
  }
};

export const fetchSchedule = (params?: {
  date?: string,
  program?: number,
  scheduleType?: number,
}) => async (dispatch: any) => {
  try {
    params = params || {};
    let date = params.date;
    let scheduleType = params.scheduleType;
    let program = params.program;
    const scheduleState = store.getState().schedule;
    if (!scheduleType) {
      scheduleType = scheduleState.type.id;
    } else {
      dispatch(
        scheduleSliceActions.getSuccess({
          type: { id: scheduleType },
        })
      );
    }
    if (!program) {
      program = scheduleState.program;
    }
    if (!date) {
      date = scheduleState.date;
    }
    dispatch(scheduleSliceActions.getStart());
    dispatch(participantSliceActions.normalizeList([]));
    dispatch(coachSliceActions.normalizeList([]));

    const schedule = await axios.post(
      `${process.env.REACT_APP_SERVER_URL || ''}/schedules/schedule_by_date/`,
      { schedule_type_id: scheduleType, date: date, program: program }
    );

    if (!schedule.data) {
      return;
    }

    dispatch(
      scheduleSliceActions.getSuccess({
        id: schedule.data.id,
        isOutdated: false,
        date: date,
        modifiedDate: schedule.data.modifiedDate,
        program: schedule.data.program,
        type: schedule.data.scheduleType,
        types: schedule.data.scheduleTypes,
      })
    );

    dispatch(
      participantSliceActions.normalizeList(schedule.data.scheduledParticipants)
    );

    dispatch(coachSliceActions.normalizeList(schedule.data.scheduledCoaches));
  } catch (err) {
    dispatch(scheduleSliceActions.getFailure(err));
  }
};

export const setScheduleType = (scheduleType: number) => async (
  dispatch: any
) => {
  localStorage.setItem('scheduleType', scheduleType.toString());
  dispatch(fetchSchedule({ scheduleType: scheduleType }));
};

export const getScheduleByDate = async (
  date: String,
  program: number,
  scheduleType: number
) => {
  const schedule = await axios.post(
    `${process.env.REACT_APP_SERVER_URL || ''}/schedules/schedule_by_date/`,
    { schedule_type_id: scheduleType, date: date, program: program }
  );
  return schedule;
};

export const getAllSchedulesByDate = async (date: String, userType: String) => {
  const schedules = await axios.get(
    `${
      process.env.REACT_APP_SERVER_URL || ''
    }/aerostar/reports/all_schedules_by_date?date=${date}&userType=${userType}`,
    { date: date, userType: userType }
  );
  return schedules;
};
