// @flow
import React from 'react';
import axios from 'axios';
import styles from '../../directory/list-page/ListPage.module.scss';
import store from '../../general/store';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import type { State } from '../../general/types';
import { Layout, Menu, Spin, Transfer, notification, Result } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { programActions } from '../../slices/programSlice';
import AddProgram from '../program/add-program/addPrograms';
import { assignProgramToPeople } from '../../slices/transferProgramSlice';
import { participantActions } from '../../slices/participantUserSlice';
import { coachActions } from '../../slices/coachUserSlice';
const { Content, Sider } = Layout;

export default function Programs() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    store.dispatch(programActions.fetch());
    store.dispatch(coachActions.fetch());
    store.dispatch(participantActions.fetch());
  }, []);
  const [programSelected, setProgramSelected] = useState(false);
  const [peopleReady, setPeopleReady] = useState(false);
  const [firstSelect, setFirstSelect] = useState(false);
  const [participantTargetKeys, setParticipantTargetKeys] = useState([]);
  const [participantSelectedKeys, setParticipantSelectedKeys] = useState([]);
  const [coachTargetKeys, setCoachTargetKeys] = useState([]);
  const [coachSelectedKeys, setCoachSelectedKeys] = useState([]);
  const [selectedProgramID, setSelectedProgramID] = useState();

  const programs = useSelector((state: State) => {
    return state.programs;
  });

  const programsList = programs.list.map((id) => {
    return programs.byId[id];
  });

  const participantUsers = useSelector((state: State) => {
    return state.participantUsers;
  });
  const participantList = participantUsers.list.map((id) => {
    return participantUsers.byId[id];
  });

  const coachUsers = useSelector((state: State) => {
    return state.coachUsers;
  });
  const coachList = coachUsers.list.map((id) => {
    return coachUsers.byId[id];
  });

  const getAssignPeople = (people, programID, isCoach) => {
    const initialAssignedPeople = [];
    for (let i = 0; i < people.length; i++) {
      if (people[i].program.includes(parseInt(programID))) {
        initialAssignedPeople.push(people[i].id);
      }
    }
    if (isCoach) {
      setCoachTargetKeys(initialAssignedPeople);
    } else {
      setParticipantTargetKeys(initialAssignedPeople);
    }
    setPeopleReady(true);
  };

  const renderProgramInfo = async (value) => {
    setFirstSelect(true);
    setProgramSelected(true);
    setPeopleReady(false);
    setSelectedProgramID(value.key);
    const apiParticipantList = await axios.get(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/participant`
    );
    const apiCoachList = await axios.get(
      `${process.env.REACT_APP_SERVER_URL || ''}/aerostar/coach`
    );
    getAssignPeople(apiParticipantList.data, value.key, false);
    getAssignPeople(apiCoachList.data, value.key, true);
  };

  const getTransferPatchedListData = (people) => {
    const peopleList = [];
    for (let i = 0; i < people.length; i++) {
      peopleList.push({
        key: people[i].id,
        name: people[i].firstName + ' ' + people[i].lastName,
      });
    }
    return peopleList;
  };

  const openNotification = (messageText) => {
    notification.success({
      message: messageText,
    });
  };

  const onChangeParticipant = (nextTargetKeys) => {
    setParticipantTargetKeys(nextTargetKeys);
    const request = {
      users: nextTargetKeys.concat(coachTargetKeys),
    };
    if (selectedProgramID === undefined) {
      dispatch(assignProgramToPeople(request, programsList[0].id));
    } else {
      dispatch(assignProgramToPeople(request, parseInt(selectedProgramID)));
    }
    openNotification('Participants Successfully Updated');
  };

  const onSelectChangeParticipant = (
    sourceSelectedKeys,
    targetSelectedKeys
  ) => {
    setParticipantSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onChangeCoach = (nextTargetKeys) => {
    setCoachTargetKeys(nextTargetKeys);
    const request = {
      users: nextTargetKeys.concat(participantTargetKeys),
    };
    if (selectedProgramID === undefined) {
      dispatch(assignProgramToPeople(request, programsList[0].id));
    } else {
      dispatch(assignProgramToPeople(request, parseInt(selectedProgramID)));
    }
    openNotification('Coaches Successfully Updated');
  };

  const onSelectChangeCoach = (sourceSelectedKeys, targetSelectedKeys) => {
    setCoachSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const filterOption = (inputValue, option) =>
    option.name.toLowerCase().includes(inputValue.toLowerCase());

  const getProgramStatus = (id) => {
    if (programs.byId[id]?.deleted) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        {programsList.length > 0 &&
        participantList.length > 0 &&
        coachList.length > 0 ? (
          <Layout style={{ minHeight: '100vh' }}>
            <Sider style={{ background: '#fff' }}>
              <Menu
                mode="inline"
                onSelect={renderProgramInfo}
                className={styles.siderCustom}
              >
                {programsList.map((program) => {
                  return (
                    <Menu.Item key={program.id}>
                      {program.deleted
                        ? `${program.name} (Deactivated)`
                        : program.name}
                    </Menu.Item>
                  );
                })}
              </Menu>
            </Sider>
            <Layout className={styles.siteLayout}>
              <Content>
                {programSelected ? (
                  <div
                    className={styles.siteLayoutBackground}
                    style={{ padding: 24, minHeight: 360, paddingBottom: 150 }}
                  >
                    <h3 className="header-update">
                      {getProgramStatus(selectedProgramID) ? (
                        <p style={{ color: 'red' }}>Program Deactivated</p>
                      ) : (
                        'Update Program'
                      )}
                    </h3>
                    <div className="content-underheader">
                      {selectedProgramID ? (
                        <AddProgram
                          program={programs.byId[selectedProgramID]}
                          programStatus={getProgramStatus(selectedProgramID)}
                        />
                      ) : (
                        <AddProgram
                          program={programs.byId[programsList[0].id]}
                          programStatus={getProgramStatus(selectedProgramID)}
                        />
                      )}
                      {peopleReady ? (
                        <>
                          <h3 style={{ margin: '35px 0px 25px 0px' }}>
                            Coaches
                          </h3>
                          <div className="transfer-section">
                            <Transfer
                              dataSource={getTransferPatchedListData(coachList)}
                              titles={['All', 'Assigned']}
                              showSearch
                              listStyle={{ width: '100%' }}
                              targetKeys={coachTargetKeys}
                              selectedKeys={coachSelectedKeys}
                              onChange={onChangeCoach}
                              onSelectChange={onSelectChangeCoach}
                              filterOption={filterOption}
                              render={(item) => item.name}
                              operations={['assign', 'unassign']}
                              disabled={getProgramStatus(selectedProgramID)}
                            />
                          </div>
                          <h3 style={{ margin: '35px 0px 25px 0px' }}>
                            Participants
                          </h3>
                          <div className={styles.transport}>
                            <Transfer
                              dataSource={getTransferPatchedListData(
                                participantList
                              )}
                              titles={['All', 'Assigned']}
                              showSearch
                              listStyle={{ width: '100%' }}
                              targetKeys={participantTargetKeys}
                              selectedKeys={participantSelectedKeys}
                              onChange={onChangeParticipant}
                              onSelectChange={onSelectChangeParticipant}
                              filterOption={filterOption}
                              render={(item) => item.name}
                              operations={['assign', 'unassign']}
                              disabled={getProgramStatus(selectedProgramID)}
                            />
                          </div>
                        </>
                      ) : (
                        <div className={styles.spinner}>
                          <Spin />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    {firstSelect ? (
                      <div className={styles.spinner}>
                        <Spin />
                      </div>
                    ) : (
                      <Result
                        icon={<SearchOutlined />}
                        title="Select a Program to View and Update"
                      />
                    )}
                  </>
                )}
              </Content>
            </Layout>
          </Layout>
        ) : (
          <div className={styles.spinner}>
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
}
