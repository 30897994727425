// @flow
import { createSlice } from '@reduxjs/toolkit';
import { defaultReducers } from './defaultReducers';
import { DefaultRequests } from './defaultRequests';

export interface Survey {
  id: number;
  title: string;
  url: string;
  survey_type: string;
  is_active: boolean;
}

export interface SurveyState {
  loading: boolean;
  error: string;
  list: Survey[];
  byId: {};
}

const initialState: SurveyState = {
  loading: false,
  error: '',
  list: [],
  byId: {},
};

const surveys = createSlice({
  name: 'surveys',
  initialState,
  reducers: {
    getStart: defaultReducers.getStart,
    getSuccess: defaultReducers.getSuccess,
    getFailure: defaultReducers.getFailure,
    normalizeList: defaultReducers.normalizeList,
    add: defaultReducers.add,
    update: defaultReducers.update,
    delete: defaultReducers.delete,
  },
});

export const {
  getStart,
  getSuccess,
  getFailure,
  normalizeList,
  add,
  update,
  deleteSurveyReducer,
} = surveys.actions;

export const surveyReducer = surveys.reducer;
export const surveyActions = new DefaultRequests(surveys.actions, `forms`);
